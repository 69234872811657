import React, {useState} from "react";
import {TimelineSettings} from "../graphs/TimelineSettings";
import {ZoomStatus} from "../graphs/ZoomStatus";
import ButtonGroupComp from "./ButtonGroupComp";
import {TimelineGraphController} from "./GraphController";

export interface Props {
  settings: TimelineSettings,
  updateSettings: (settings: TimelineSettings) => void,
  style: any,
}

export const TimelineDataTypesComp = (props: Props) => {
  const settings = props.settings;

  const toggleSetting = (name: string) => {
    let newSettings = structuredClone(props.settings);
    newSettings[name] = !newSettings[name];
    props.updateSettings(newSettings);
  };

   return (
     <div style={props.style}>
       <div><b>Data types</b></div>
       <div>
         <input type="checkbox" checked={settings.showCGM} onChange={() => toggleSetting("showCGM")} />CGM
       </div>
       <div>
         <input type="checkbox" checked={settings.showGlucose} onChange={() => toggleSetting("showGlucose")} />Glucose
       </div>
        <div>
           <input type="checkbox" checked={settings.showBasal} onChange={() => toggleSetting("showBasal")} />Basal
        </div>
        <div>
            <input type="checkbox" checked={settings.showInsulin} onChange={() => toggleSetting("showInsulin")} />Insulin
        </div>
        <div>
            <input type="checkbox" checked={settings.showBolus} onChange={() => toggleSetting("showBolus")} />Bolus
        </div>
        <div>
            <input type="checkbox" checked={settings.showEvents} onChange={() => toggleSetting("showEvents")} />Events
        </div>
        <div>
            <input type="checkbox" checked={settings.showCarbs} onChange={() => toggleSetting("showCarbs")} />Carbs
        </div>
       <div>
         <input type="checkbox" checked={settings.showExercise} onChange={() => toggleSetting("showExercise")} />Exercise
       </div>
       <div>
         <input type="checkbox" checked={settings.showPumpModes} onChange={() => toggleSetting("showPumpModes")} />Pump modes
       </div>
     </div>
   )
}