import {PumpModeStatisticsItem} from "./TimeRangeStatistics";

const MAIN = "rgba(22, 38, 46, 0.87)";

export default {
   TEXT_SIZE: 13,

   CURSOR: "#0690ED",
   AXIS_HOUR_FG: MAIN,
   FOG_OF_WAR: "rgba(0,0,0,0.2)",
   AXIS_HORIZONTAL: "#555555",
   HOUR_DIVIDER: "rgba(0,0,0,0.08)",
   DAY_DIVIDER: "rgba(0,0,0,0.20)",
   AXIS_WEEKEND_FG: "#b2000d",
   AXIS_WEEKDAY_FG: "#333",
   AXIS_NAME: MAIN,
   AXIS_UNIT: MAIN,
   AXIS_VALUE: MAIN,
   AXIS_VALUE_SIZE: 11,

   POPUP_TIME_SIZE: 11,

   GLUCOSE_TARGET_DASH: [5, 5],
   GLOCOSE_TARGET_LINE: MAIN,
   GLUCOSE_SEVERE_HIGH: "#ff9520",
   GLUCOSE_HIGH: "#fec31e",
   GLUCOSE_IN_RANGE: "#25bd59",
   GLUCOSE_LOW: "#d93a00",
   GLUCOSE_SEVERE_LOW: "#922700",
   GLUCOSE_MISSING: "#808080",
   GLUCOSE_SIZE: 8,
   CGM_SIZE: 2,

   BOLUS_FILL: "rgba(124,193,255,0.33)",
   BOLUS_STROKE: "#7cc1ff",
   BOLUS_AUTOMATIC_FILL: "rgba(255,149,32,0.33)",
   BOLUS_AUTOMATIC_STROKE: "rgba(255,149,32,1)",
   BOLUS_BUBBLE_FILL: "#7cc1ff",
   BOLUS_BUBBLE_STROKE: "#7cc1ff",

   CARBS_FILL: "#23cac2",

   BASAL_FILL: "rgba(0,0,0,0.05)",
   BASAL_STROKE: "#0957c3",
   BASAL_TEMP_MODIFIED: "#0957c3",
   BASAL_PREDICTIVE_LOW_GLUCOSE_SUSPEND: "#d93a00",
   BASAL_LOW_GLUCOSE_SUSPEND: "#c500ff",
   BASAL_TRANSPARENT_PREDICTIVE_LOW_GLUCOSE_SUSPEND: "rgba(217,58,0,0)",
   BASAL_TRANSPARENT_LOW_GLUCOSE_SUSPEND: "rgba(197,0,255,0)",

   EVENT_FILL: "rgba(9,87,195,0.33)",
   EVENT_STROKE: "#0957c3",

   EXERCISE_FILL: "rgba(27,182,225,0.33)",
   EXERCISE_STROKE: "#1bb6e1",

   HIDE_ALPHA: 0.15
}
