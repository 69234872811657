import React from "react";

export interface Props<Type> {
  values: [Type, string][];
  value: Type;
  onValueChange: (value: Type) => void;
  style?: any;
  vertical?: boolean;
}

export default function ButtonGroupComp<Type>(props: Props<Type>) {
  const values = props.values;
  return(
    <div className={`buttonGroup ${props.vertical ? "vertical" : ""}`} style={props.style}>
      {values.map(keyAndValue =>
        <div key={String(keyAndValue[0])}
             className={`button ${props.value == keyAndValue[0] ? "selected" : ""}`}
             onClick={() => props.onValueChange(keyAndValue[0])}>{keyAndValue[1]}</div>)
      }
    </div>)
}