import React, {useState} from "react";
import GlookoGraphRepository from "../graphs/GlookoGraphRepository";
import {TimelineSettings} from "../graphs/TimelineSettings";
import {StatisticsComp} from "./StatisticsComp";
import {TimeRangeStatistics} from "../graphs/TimeRangeStatistics";
import {TimelineControlsComp} from "./TimelineControlsComp";
import {ZoomStatus} from "../graphs/ZoomStatus";
import {TimelineGraphComp} from "./TimelineGraphComp";
import {TimelineGraphController} from "./GraphController";
import {DEFAULT_MAX_GLUCOSE} from "../graphs/GraphSettings";

export interface Props {
  repo: GlookoGraphRepository;
}

export const TimelineView = (props: Props) => {

  const [statistics, setStatistics] = useState<TimeRangeStatistics | undefined>(undefined)
  const [zoomStatus, setZoomStatus] = useState<ZoomStatus | undefined>(undefined)
  const [graphController, _setGraphController] = useState<TimelineGraphController>(new TimelineGraphController())
  const [settings, setSettings] = useState<TimelineSettings>({
    showBasal: true,
    showBolus: true,
    showCarbs: true,
    showEvents: true,
    showExercise: true,
    showGlucose: true,
    showCGM: true,
    showInsulin: true,
    showPumpModes: true,
    advancedCursor: false,
    allowPanning: true,
    useFullDayStatistics: true,
    maxGlucose: DEFAULT_MAX_GLUCOSE,
  })

  const updateSettings = (settings: TimelineSettings) => {
    graphController.setSettings(settings);
    setSettings(settings);
  }

  return (
    <div className="tab">
      <div className="box" style={{flexGrow:1, flexShrink:1, flexBasis:0, display:"flex", flexDirection:"column"}}>
        <div style={{margin:15}}>
          {zoomStatus && <TimelineControlsComp settings={settings}
                                               zoomStatus={zoomStatus}
                                               graphController={graphController}
                                               updateSettings={updateSettings}/> }
        </div>
        <div style={{flexGrow:1, flexShrink:1, flexBasis:0, position:"relative", height:600,marginBottom:15}}>
          <TimelineGraphComp graphController={graphController}
                             settings={settings}
                             setStatistics={setStatistics}
                             setZoomStatus={setZoomStatus}
                             repo={props.repo}
                             style={{height:600, cursor:"crosshair", width:"100%"}} />
        </div>
      </div>
      <StatisticsComp statistics={statistics}
                      graphController={graphController}/>
    </div>)
};
