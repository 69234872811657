import React from "react";

export interface Props {
  errorMessage: string;
  clearErrorMessage: () => void;
}

export const ErrorMessageComp = (props: Props) =>
  <div className="errorMessage">
    <div className="message">{props.errorMessage}</div>
    <div className="buttonGroup">
      <div className="button" onClick={props.clearErrorMessage}>Retry</div>
    </div>
  </div>
