export interface PumpModeDescription {
  fillColor: string,
  strokeColor: string,
  rowNum: number,
  rowHeight: number,
}

const PUMP_MODE_DESCRIPTIONS: { [type: string]: PumpModeDescription } = {
  "default": {fillColor: "rgba(255, 0, 0, 0.15)", strokeColor: "rgba(255, 0, 0, 1.0)", rowNum: 0, rowHeight: 1},
  "Exercise": {fillColor: "rgba(0, 150, 0, 0.15)", strokeColor: "rgb(0, 150, 0, 1.0)", rowNum: 0, rowHeight: 1},
  "Activity": {fillColor: "rgba(0, 150, 0, 0.15)", strokeColor: "rgb(0, 150, 0, 1.0)", rowNum: 0, rowHeight: 1},
  "Sleep": {fillColor: "rgba(140, 0, 255, 0.15)", strokeColor: "rgb(140, 0, 255, 1.0)", rowNum: 0, rowHeight: 1},
  "Limited": {fillColor: "rgba(140, 0, 255, 0.15)", strokeColor: "rgba(140, 0, 255, 1.0)", rowNum: 0, rowHeight: 1},
  "No CGM": {fillColor: "rgba(255, 0, 0, 0.15)", strokeColor: "rgba(255, 0, 0, 1.0)", rowNum: 0, rowHeight: 1},

  "Automatic": {fillColor: "rgba(0, 0, 255, 0.15)", strokeColor: "rgba(0, 0, 255, 1.0)", rowNum: 1, rowHeight: 1},
  "Control-IQ": {fillColor: "rgba(0, 0, 255, 0.15)", strokeColor: "rgba(0, 0, 255, 1.0)", rowNum: 1, rowHeight: 1},
  "Basal-IQ": {fillColor: "rgba(0, 0, 255, 0.15)", strokeColor: "rgba(0, 0, 255, 1.0)", rowNum: 1, rowHeight: 1},
  "Manual": {fillColor: "rgba(255, 153, 0, 0.15)", strokeColor: "rgba(255, 153, 0, 1.0)", rowNum: 0, rowHeight: 2}
}

export const getPumpModeDescription = (type: string): PumpModeDescription => {
  const description = PUMP_MODE_DESCRIPTIONS[type];
  return description ? description : PUMP_MODE_DESCRIPTIONS["default"]
};
