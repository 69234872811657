import {GraphResources} from "./GraphResources";
import GraphArea from "./GraphArea";
import GraphRepository from "./GraphRepository";
import {GraphData} from "./GraphData";
import {PatientData} from "./PatientData";
import {GraphSettings} from "./GraphSettings";
import {getGlucoseString, getGlucoseValue} from "./UnitTools";
import GraphAxis from "./GraphAxis";
import {getGlucoseColor} from "./DrawTools";

export default abstract class AbstractGraph<T extends GraphSettings> {

   protected readonly canvas: HTMLCanvasElement;
   public readonly ctx: CanvasRenderingContext2D;
   private pendingRepaint:boolean = false;

   protected settings:T;
   protected readonly resources:GraphResources;
   protected readonly graphRepo:GraphRepository;
   protected data?: GraphData;
   protected readonly patientData: PatientData;

   public dpr:number = 1;
   protected area: GraphArea = new GraphArea();
   protected cursorX?: number;
   protected cursorY?: number;
   protected scaleW:number = 0;

   protected glucoseAxis: GraphAxis;

   protected highlightedEventId?: number;
   protected hiddenEventIds = new Set<number>();

   protected constructor(canvas: HTMLCanvasElement, graphRepo: GraphRepository, settings: T) {
      this.canvas = canvas;
      this.graphRepo = graphRepo;
      this.resources = graphRepo.resources;
      this.patientData = graphRepo.getPatientData();
      this.settings = settings;
      this.ctx = this.canvas.getContext("2d")!;
      this.ctx.translate(0.5, 0.5);

      this.canvas.onmousemove = this.onMouseMove;
      this.canvas.onmouseleave = this.onMouseLeave;

      this.glucoseAxis = new GraphAxis(this.getGlucoseValue(settings.maxGlucose), 2);

      this.data = this.graphRepo.getGraphData();
      this.graphRepo.addOnUpdate(this.onRepoUpdate);
   }

   protected onRepoUpdate = () => {
      this.data = this.graphRepo.getGraphData();
      if(this.data) {
         this.onDataUpdate();
         this.scheduleRepaint();
      }
   }

   setSettings = (settings: T) => {
      this.settings = settings;
      this.scheduleRepaint();
   }

   destroyGraph() {
      this.graphRepo.deleteOnUpdate(this.onRepoUpdate)
   }


   protected onDataUpdate() {
   }

   protected abstract repaint(): void;

   abstract showLastDays(nDays: number): void;

   protected doRepaint = () => {
      this.pendingRepaint = false;
      this.dpr = window.devicePixelRatio;
      let domRect = this.canvas.getBoundingClientRect();

      this.canvas.width = domRect.width * this.dpr;
      this.canvas.height = domRect.height * this.dpr;
      if(this.canvas.width && this.canvas.height) {
         this.repaint();
      }
   };

   scheduleRepaint = () => {
      if(!this.pendingRepaint) {
         this.pendingRepaint = true;
         setTimeout(this.doRepaint);
      }
   };

   onMouseMove = (me: MouseEvent) => {
      this.cursorX = me.offsetX * this.dpr;
      this.cursorY = me.offsetY * this.dpr;
      this.scheduleRepaint();
   };

   onMouseLeave = () => {
      this.cursorX = undefined;
      this.cursorY = undefined;
      this.scheduleRepaint();
   };

   setHighlightedEventId = (eventId?:number) => {
      this.highlightedEventId = eventId;
      this.scheduleRepaint();
   };

   toggleEventVisible = (eventId: number) => {
      if(this.hiddenEventIds.has(eventId)) {
         this.hiddenEventIds.delete(eventId);
      } else {
         this.hiddenEventIds.add(eventId)
      }
      this.scheduleRepaint();
   };

   setFont = (size:number, style?: string) => {
      this.ctx.font = (style ? (style + " ") : "") + (size * this.dpr) +"px Lato";
   };

   getGlucoseValue = (glucoseInMmol: number) => {
      return getGlucoseValue(this.patientData.unit,  glucoseInMmol);
   };

   getGlucoseString = (glucoseInMmol: number) => {
      return getGlucoseString(this.patientData.unit, glucoseInMmol);
   };

   protected getGlucoseY = (valueInMmol: number) => {
      return this.glucoseAxis.getY(this.getGlucoseValue(valueInMmol));
   };

   protected drawGlucosePoint(glucose: any, x: number, size: number) {
      let ctx = this.ctx;
      let y = this.getGlucoseY(glucose[1]);
      ctx.fillStyle = getGlucoseColor(this.patientData, glucose[1]);
      if (y <= this.glucoseAxis.top) {
         y = this.glucoseAxis.top;
         ctx.beginPath();
         ctx.moveTo(x - 5, y + 4);
         ctx.lineTo(x, y - 1);
         ctx.lineTo(x + 5, y + 4);
         ctx.closePath();
         ctx.fill()
      } else {
         if (size > 4) {
            ctx.beginPath();
            ctx.arc(x, y, size / 2, 0, 2 * Math.PI, false);
            ctx.fill();
         } else {
            ctx.fillRect(x - size / 2, y - size / 2, size, size);
         }
      }
   }

}