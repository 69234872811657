import GraphRepository from "./GraphRepository";
import GraphAxis from "./GraphAxis";
import {findValue, getRange, SearchType, toMoment} from "./DataTools";
import {
   drawBasalLine,
   drawBolus,
   drawDownArrow,
   drawIcon,
   drawLine,
   drawMultiline,
   drawScale,
   getBasalColor
} from "./DrawTools";
import moment, {Moment} from 'moment'
import {CursorInfo} from "./CursorInfo";
import {BasalType, BolusData} from "./GraphData";
import {TimeRangeStatistics} from "./TimeRangeStatistics";
import Style from "./Style";
import {StatisticsGenerator} from "./StatisticsGenerator";
import {GlucoseStatisticsGenerator} from "./GlucoseStatisticsGenerator";
import AbstractGraph from "./AbstractGraph";
import {OverlayInterval, OverlaySettings, OverlayType} from "./OverlaySettings";
import {DEFAULT_MAX_GLUCOSE, GlucoseUnit} from "./GraphSettings";
import {getShortMonthAndDay} from "./TimeTools";
import GraphComponent from "./GraphComponent";


export default class OverlayGraph extends AbstractGraph<OverlaySettings> {

   private nowString: string;

   private statisticsGenerator: StatisticsGenerator;

   private suspendComp = new GraphComponent(15);
   private basalAxis = new GraphAxis(4, 1);
   private bolusAxis = new GraphAxis(1, 2);
   private alarmComp = new GraphComponent(25);
   private components = [
      this.glucoseAxis,
      this.alarmComp,
      this.basalAxis,
      this.suspendComp,
      this.bolusAxis
   ];

   // Callbacks:
   onStatisticsChange: (statistics: TimeRangeStatistics) => void;
   onSettingsChange: (settings: OverlaySettings) => void;

   constructor(canvas: HTMLCanvasElement,
               graphRepo: GraphRepository,
               onStatisticsChange: (statistics: TimeRangeStatistics) => void,
               onSettingsChange: (settings: OverlaySettings) => void) {
      super(canvas, graphRepo, {
         fromDay: undefined,
         toDay: undefined,
         lastDays: undefined,
         timeSpanLength: 24 * 3600,
         intervals: [],
         glucoseGraphType: "glucose",
         type: OverlayType.Days,
         from: 0,
         to: 0,

         showCGM: true,
         showGlucose: true,
         showAutomaticSuspend: true,
         maxGlucose: DEFAULT_MAX_GLUCOSE,
      });
      this.nowString = graphRepo.lastSyncTime;
      this.onStatisticsChange = onStatisticsChange;
      this.onSettingsChange = onSettingsChange;
   }

   showLastDays = (nDays: number) => {
      console.log("showLastDays ", nDays);
      let now = (this.nowString ? moment(this.nowString) : moment()).utc(true);
      let toDate:Moment = now.clone().startOf("day").subtract(1, "day");
      let fromDate:Moment = toDate.clone().subtract(nDays - 1, "days");

      console.log("now: " + now.toISOString());
      console.log("toDate: " + toDate.toISOString());

      this.setInterval(fromDate.format("YYYY-MM-DD"), toDate.format("YYYY-MM-DD"));
      this.settings.lastDays = nDays;
   };

   showTimeSpan = (timeSpan: number) => {
      this.showEventOverlay(this.settings.type, timeSpan)
   };

   getMealEvents = (fromHour: number, toHour: number): OverlayInterval[] => {
      const getInterval = (bolus: [number, BolusData]):OverlayInterval  => {
         const timestamp = bolus[0];
         const moment = toMoment(timestamp);
         return {
            dateAsString: moment.format("DD/MM HH:mm"),
            date: moment.format("DD/MM HH:mm"),
            weekDay: 0,
            weekNumber: 0,
            isVisible: true,
            isHighlighted: false,
            statistics: undefined,
            from: timestamp - timeSpanLength / 2,
            to: timestamp + timeSpanLength / 2
         };
      }

      let intervals: OverlayInterval[] = [];
      const  [minI, maxI] = getRange(this.data.bolus, this.settings.from, this.settings.to);
      const timeSpanLength = this.settings.timeSpanLength
      let prevDay = -1;
      let largestBolus: [number, BolusData] = undefined;
      let largestBolusAmount = 0;
      for (let i = minI; i <= maxI; i++) {
         let bolus = this.data.bolus[i];
         let timestamp = bolus[0];
         let moment = toMoment(timestamp);
         let hour = moment.hour();
         let day = moment.dayOfYear();

         if(hour >= fromHour && hour < toHour) {
            if(day != prevDay) {
               prevDay = day;
               if(largestBolus) {
                  intervals.push(getInterval(largestBolus));
                  largestBolus = undefined;
                  largestBolusAmount = 0
               }
            }

            const bolusAmount = bolus[1].immediate + bolus[1].extended;
            if(!largestBolus || bolusAmount > largestBolusAmount) {
               largestBolus = bolus;
               largestBolusAmount = bolusAmount;
            }
         }
      }
      if(largestBolus) {
         intervals.push(getInterval(largestBolus));
      }
      return intervals;
   };

   showEventOverlay = (type: OverlayType, timeSpanLength?: number) => {
      this.settings.type = type;
      if(timeSpanLength) {
         this.settings.timeSpanLength = timeSpanLength;
      } else {
         timeSpanLength = this.settings.timeSpanLength
      }
      this.settings.intervals = [];

      let data = this.data;
      if(data) {
         if(type == OverlayType.Hypo) {
            let [minI, maxI] = getRange(data.cgm, this.settings.from, this.settings.to);
            let lastTimestamp = 0;
            for (let i = minI; i <= maxI; i++) {
               let cgm = data.cgm[i];
               let timestamp = cgm[0];
               let value = cgm[1];
               if (value < this.graphRepo.getPatientData().severe_low_limit && (timestamp - lastTimestamp) > 3600) {
                  this.settings.intervals.push({
                     dateAsString: toMoment(timestamp).format("DD/MM HH:mm"),
                     date: toMoment(timestamp).format("DD/MM HH:mm"),
                     weekDay: 0,
                     weekNumber: 0,
                     isVisible: true,
                     isHighlighted: false,
                     statistics: undefined,
                     from: timestamp - timeSpanLength / 2,
                     to: timestamp + timeSpanLength / 2
                  });
                  lastTimestamp = timestamp;
               }
            }
         } else if(type == OverlayType.ExerciseStart) {
            let [minI, maxI] = getRange(data.exercise, this.settings.from, this.settings.to);
            for (let i = minI; i <= maxI; i++) {
               let exercise = data.exercise[i];
               let timestamp = exercise[0];
               this.settings.intervals.push({
                  dateAsString: toMoment(timestamp).format("DD/MM HH:mm"),
                  date: toMoment(timestamp).format("DD/MM HH:mm"),
                  weekDay: 0,
                  weekNumber: 0,
                  isVisible: true,
                  isHighlighted: false,
                  statistics: undefined,
                  from: timestamp - timeSpanLength / 2,
                  to: timestamp + timeSpanLength / 2
               });
            }
         } else if(type == OverlayType.InfusionSetAndSiteChange) {
            let [minI, maxI] = getRange(data.events, this.settings.from, this.settings.to);
            for (let i = minI; i <= maxI; i++) {
               let event = data.events[i];
               if(event[1] == 500021) {
                  let timestamp = event[0];
                  this.settings.intervals.push({
                     dateAsString: toMoment(timestamp).format("DD/MM HH:mm"),
                     date: toMoment(timestamp).format("DD/MM HH:mm"),
                     weekDay: 0,
                     weekNumber: 0,
                     isVisible: true,
                     isHighlighted: false,
                     statistics: undefined,
                     from: timestamp - timeSpanLength / 2,
                     to: timestamp + timeSpanLength / 2
                  });
               }
            }
         } else if(type == OverlayType.Breakfast) {
            this.settings.intervals = this.getMealEvents(6, 10);
         } else if(type == OverlayType.Lunch) {
            this.settings.intervals = this.getMealEvents(11, 15);
         } else if(type == OverlayType.Dinner) {
            this.settings.intervals = this.getMealEvents(16, 22);
         }

         this.onSettingsChange(this.settings);
         this.scheduleRepaint();
      }
   };

   setInterval = (fromDayString:string, toDayString:string) => {
      this.scheduleRepaint();
      this.settings.lastDays = undefined;
      this.settings.fromDay = fromDayString;
      this.settings.toDay = toDayString;
      let fromDay = moment(this.settings.fromDay, 'YYYY-MM-DD').utc(true);
      let toDay = moment(this.settings.toDay, 'YYYY-MM-DD').utc(true).add(1, "day");
      this.settings.from = fromDay.unix();
      this.settings.to = toDay.unix();

      if(this.settings.type == OverlayType.Days) {
         this.settings.intervals = [];
         let lastWeekDay = -1;
         let lastWeekNumber = 0;
         for (let d = fromDay; d < toDay;) {
            let tomorrow = d.clone().add(1, "day");
            let weekDay = d.weekday() == 0 ? 6 : d.weekday() - 1;
            if (weekDay < lastWeekDay) {
               lastWeekNumber++;
            }
            let interval: OverlayInterval = {
               dateAsString: getShortMonthAndDay(d),
               weekDay: weekDay,
               weekNumber: lastWeekNumber,
               date: d.format("YYYYMMDD"),
               from: d.unix(),
               to: tomorrow.unix(),
               statistics: undefined,
               isVisible: true,
               isHighlighted: false,
            };
            this.updateIntervalStatistics(interval);
            this.settings.intervals.push(interval);
            if (this.settings.intervals.length > 14) {
               break;
            }
            d = tomorrow;
            lastWeekDay = interval.weekDay;
         }

         console.log("setsettings ", this.settings);
         this.onSettingsChange(this.settings);
      } else {
         this.showEventOverlay(this.settings.type, this.settings.timeSpanLength)
      }
   };

   onDataUpdate = () => {
      super.onDataUpdate();
      if(this.settings.type == OverlayType.Days) {
         for (let interval of this.settings.intervals) {
            this.updateIntervalStatistics(interval);
         }
         this.onSettingsChange(this.settings);
      } else {
         this.showEventOverlay(this.settings.type, this.settings.timeSpanLength);
      }
   };


   private updateIntervalStatistics(interval:OverlayInterval) {
      if(!(this.data && this.patientData)) {
         return;
      }
      let statistics = new GlucoseStatisticsGenerator(this.data, this.patientData, false);
      statistics.addTimeRange(interval.from, interval.to);
      interval.statistics = statistics.getGlucoseStatistics(this.patientData.unit);
   }

   updateStatistics = () => {
      if(!(this.patientData && this.data)) {
         return;
      }

      let intervals = this.settings.intervals.filter(interval => interval.isHighlighted && interval.isVisible);
      if(!intervals.length) {
         intervals = this.settings.intervals.filter(interval => interval.isVisible);
      }

      this.statisticsGenerator = new StatisticsGenerator(
         this.data, this.patientData,
         this.resources,
         this.hiddenEventIds,
         true);
      for (let interval of intervals) {
         this.statisticsGenerator.addTimeRange(toMoment(interval.from), toMoment(interval.to - 1));
      }
      let statistics = this.statisticsGenerator.getStatistics(this.patientData.unit);
      if(intervals.length > 1) {
         statistics.timeRange = "";
      }
      if(this.onStatisticsChange) {
         this.onStatisticsChange(statistics);
      }
   };

   private getTimeString(x: number, labelW: number = 1000) {
      if(this.settings.type == OverlayType.Days) {
         return this.area.getTimeString(x);
      } else {
         let minutes = Math.round((this.area.getT(x) - this.settings.timeSpanLength / 2) / 60);
         let hours = Math.floor(minutes / 60);
         let timeString = (minutes > 0 ? "+" : "") + this.area.toDigit(hours);
         if(labelW / this.dpr > 42) {
            timeString += ":" + this.area.toDigit(minutes - hours * 60);
         }
         return timeString;
      }
   }

   repaint = () => {
      //let start_paint_time = new Date().valueOf();
      let ctx = this.ctx;
      let dpr = this.dpr;
      let h = this.canvas.height;
      let w = this.canvas.width;
      let patientData = this.patientData;
      this.scaleW = this.dpr * ((this.settings && this.settings.type == OverlayType.Days) ? 65 : 95);
      let scaleW = this.scaleW;

      this.suspendComp.isVisible = this.settings.showAutomaticSuspend;
      this.glucoseAxis.isVisible = this.settings.showCGM || this.settings.showGlucose;
      GraphComponent.place(this.components, dpr, h)

      this.setFont(12);

      console.log("OverlayGraph.repaint " )
      if(!this.graphRepo) {
         console.log("no Repo")
      }
      if(!patientData) {
         console.log("no patientData")
      }

      if(!this.graphRepo || !this.graphRepo.requestData(this.settings.from, this.settings.to) || !patientData) {
         ctx.textAlign = "center";
         ctx.fillText("Loading data....", w / 2, h / 2);
         return;
      }

      this.updateStatistics();

      this.area.initiate(0, this.settings.timeSpanLength);
      this.area.setSize(scaleW, this.canvas.width - scaleW);

      // Draw scales:
      drawScale(this, this.glucoseAxis, scaleW, "Glucose", "(" + this.patientData.unit + ")",
         [0, this.getGlucoseValue(patientData.goal_min), this.getGlucoseValue(patientData.goal_max)],
         this.graphRepo.getPatientData().unit == GlucoseUnit.MMOL ? 1 : 0);
      drawScale(this, this.basalAxis, scaleW, "Basal", "(Units/h)", [0, 1, 2, 3, 4]);
      drawScale(this, this.bolusAxis, scaleW, "Bolus", "", []);

      // Draw lines:
      let upperLineY = 21 * dpr;
      ctx.fillStyle = Style.AXIS_HOUR_FG;
      ctx.textAlign="center";
      ctx.strokeStyle = Style.AXIS_HORIZONTAL;
      drawLine(ctx, scaleW,upperLineY, w, upperLineY);
      ctx.beginPath();

      let incrementHours = this.settings.type == OverlayType.Days ? 3 : 1;
      let hourLabelW = this.area.getW(incrementHours * 3600);
      for(let hour = 0; hour <= 24; hour+=incrementHours) {
         let x = this.area.getX(hour * 3600);
         ctx.moveTo(x, upperLineY);
         ctx.lineTo(x, h);
         ctx.fillText(this.getTimeString(x, hourLabelW), x, upperLineY - 8 * dpr);
      }
      ctx.closePath();
      ctx.stroke();
      ctx.textAlign = "left";
      drawLine(ctx, scaleW, this.glucoseAxis.getBottom(), w, this.glucoseAxis.getBottom());

      let cursorInfo = new CursorInfo(this.cursorX, this.cursorY);

      // Is any highlighted?
      let anyHighlighted = this.settings.intervals.find(i => i.isVisible && i.isHighlighted) != undefined;

      this.settings.intervals.forEach((interval, index) => {
         if(interval.isVisible && !interval.isHighlighted) {
            this.drawInterval(index, anyHighlighted, cursorInfo);
         }
      });

      if(this.settings.glucoseGraphType == "agp") {
         this.drawAgp();
      }

      // Draw min/max:
      let minY = this.glucoseAxis.getY(this.getGlucoseValue(patientData.goal_min));
      let maxY = this.glucoseAxis.getY(this.getGlucoseValue(patientData.goal_max));
      ctx.strokeStyle = Style.GLOCOSE_TARGET_LINE;
      ctx.setLineDash(Style.GLUCOSE_TARGET_DASH.map(l => l * dpr));
      drawLine(ctx, scaleW, minY, w, minY);
      drawLine(ctx, scaleW, maxY, w, maxY);
      ctx.setLineDash([]);

      this.settings.intervals.forEach((interval, index) => {
         if(interval.isVisible && interval.isHighlighted) {
            this.drawInterval(index, anyHighlighted, cursorInfo);
         }
      });

      // Cursor:
      if(this.cursorX >= scaleW) {
         ctx.beginPath();
         ctx.strokeStyle = Style.CURSOR;
         ctx.moveTo(this.cursorX, 18 * dpr);
         ctx.lineTo(this.cursorX, h);
         ctx.stroke();
         ctx.closePath();

         // Draw date:
         let timeString = this.getTimeString(this.cursorX);
         drawDownArrow(this, this.cursorX, 0, timeString);

         // Draw cursor data:
         if(cursorInfo.data) {
            let timeString = cursorInfo.getTimeString(true);
            if(cursorInfo.data instanceof Array) {
               drawMultiline(this, cursorInfo.markerX, cursorInfo.markerY, w, h, cursorInfo.data, timeString);
            } else if(typeof cursorInfo.data === "object") {
               drawBolus(this, cursorInfo.markerX, cursorInfo.markerY, w, h, cursorInfo.data, timeString)
            } else {
               drawMultiline(this, cursorInfo.markerX, cursorInfo.markerY, w, h, [[cursorInfo.data]], timeString);
            }
         }
      }
   };

   drawInterval = (intervalIndex:number, anyIsHighlighted: boolean, cursorInfo: CursorInfo) => {
      let interval = this.settings.intervals[intervalIndex];
      let highlight = interval.isHighlighted || !anyIsHighlighted;
      this.drawExerciseInterval(interval, highlight, cursorInfo);
      this.drawEventsInterval(interval, highlight, cursorInfo);
      this.drawBasalInterval(interval, highlight, anyIsHighlighted, cursorInfo);
      if(this.settings.glucoseGraphType != "agp") {
         if(this.settings.showCGM) {
            this.drawGlucoseInterval(interval, this.data.cgm, highlight, Style.CGM_SIZE * this.dpr, cursorInfo);
         }
         if(this.settings.showGlucose) {
            this.drawGlucoseInterval(interval, this.data.glucose, highlight, Style.GLUCOSE_SIZE * this.dpr, cursorInfo);
         }
      }

      let graphY = this.bolusAxis.top;
      let nDays =  this.settings.intervals.length;
      let bolusDayH = this.bolusAxis.height / nDays;
      graphY += bolusDayH / 2 + intervalIndex * bolusDayH;
      this.drawBolusInterval(interval, graphY, highlight, cursorInfo);
   };

   drawAgp = () => {
      let ctx = this.ctx;
      let agpHours = this.statisticsGenerator.getAgpHours();

      // Draw highest/lowest:
      ctx.setLineDash([4,4]);
      ctx.strokeStyle = 'rgba(6,144,237,0.50)';
      this.drawAgpLine(hour => agpHours[hour].max);
      this.drawAgpLine(hour => agpHours[hour].min);

      ctx.setLineDash([]);
      /*ctx.strokeStyle = 'rgb(75,114,255)';
      this.drawAgpLine(hour => agpHours[hour].p10);
      this.drawAgpLine(hour => agpHours[hour].p90);
      this.drawAgpLine(hour => agpHours[hour].p25);
      this.drawAgpLine(hour => agpHours[hour].p75);*/

      ctx.fillStyle = 'rgba(215,239,250,0.85)';
      this.fillAgpArea(hour => agpHours[hour].p90, hour => agpHours[hour].p10);

      ctx.fillStyle = 'rgba(161,223,253,0.75)';
      this.fillAgpArea(hour => agpHours[hour].p75, hour => agpHours[hour].p25);

      // Draw median:
      ctx.strokeStyle = '#0690ED';
      this.drawAgpLine(hour => agpHours[hour].median);
   };

   drawAgpLine = (getValue: (hour:number) => number) => {
      this.ctx.beginPath();
      this.ctx.moveTo(this.area.getX(0), this.getGlucoseY(getValue(0)));
      for(let i = 1; i <= 24; i++) {
         this.ctx.lineTo(this.area.getX(i * 3600), this.getGlucoseY(getValue(i)));
      }
      this.ctx.stroke();
   };

   fillAgpArea = (getUpperValue: (hour:number) => number, getLowerValue: (hour:number) => number) => {
      this.ctx.beginPath();
      this.ctx.moveTo(this.area.getX(0), this.getGlucoseY(getUpperValue(0)));
      for(let i = 1; i <= 24; i++) {
         this.ctx.lineTo(this.area.getX(i * 3600), this.getGlucoseY(getUpperValue(i)));
      }
      for(let i = 24; i >= 0; i--) {
         this.ctx.lineTo(this.area.getX(i * 3600), this.getGlucoseY(getLowerValue(i)));
      }
      this.ctx.closePath();
      this.ctx.fill();
   };

   drawGlucoseInterval = (interval: OverlayInterval, dataList: any, highlight: boolean, size: number, cursorInfo: CursorInfo) => {
      let [minI, maxI] = getRange(dataList, interval.from, interval.to);
      this.ctx.globalAlpha = highlight ? 1.0 : Style.HIDE_ALPHA;
      for(let i = minI; i <= maxI; i++) {
         let glucose = dataList[i];
         let value = glucose[1];
         if (value) {
            let x = this.area.getX(glucose[0] - interval.from);
            this.drawGlucosePoint(glucose, x, size);
         }
      }
      this.ctx.globalAlpha = 1.0;
      let nearest = findValue(dataList, interval.from + this.area.getT(cursorInfo.cursorX), SearchType.Nearest);
      if(nearest) {
         cursorInfo.suggestPoint(
            this.area.getX(nearest[0] - interval.from),
            this.getGlucoseY(nearest[1]), this.getGlucoseString(nearest[1]),
            nearest[0])
      }
   };

   drawEventsInterval = (interval: OverlayInterval, highlight: boolean, cursorInfo: CursorInfo) => {
      let events = this.data.events;
      let [minI, maxI] = getRange(events, interval.from, interval.to);
      let ctx = this.ctx;
      for(let i = minI; i <= maxI; i++) {
         let event = events[i];
         if(event) {
            let eventId = event[1];
            if (!this.hiddenEventIds.has(eventId)) {
               let eventDescription = this.resources.events[eventId];
               let x = this.area.getX(event[0] - interval.from);
               let y = this.alarmComp.top + 18;
               if(this.highlightedEventId) {
                  highlight = this.highlightedEventId == eventId;
               }
               ctx.globalAlpha = highlight ? 1.0 : Style.HIDE_ALPHA;
               drawIcon(this, x, y, eventDescription);
               ctx.globalAlpha = 1.0;
               cursorInfo.suggestPoint(
                  x,
                  y,
                  eventDescription ? eventDescription.text : ("Unknown event " + eventId),
                  event[0])
            }
         }
      }
   };

   drawExerciseInterval = (interval: OverlayInterval, highlight: boolean, cursorInfo: CursorInfo) => {
      let exercises = this.data.exercise;
      let exerciseBarH = 10 * this.dpr;
      let exerciseY = this.glucoseAxis.getBottom() + 2;
      let [minI, maxI] = getRange(exercises, interval.from, interval.to);
      let ctx = this.ctx;
      ctx.globalAlpha = highlight ? 1.0 : Style.HIDE_ALPHA;
      ctx.fillStyle = Style.EXERCISE_FILL;
      ctx.strokeStyle = Style.EXERCISE_STROKE;
      for(let i = minI; i <= maxI; i++) {
         let exercise = exercises[i];
         let x = this.area.getX(exercise[0] - interval.from);
         let w = this.area.getDurationW(exercise[1] * 60);
         ctx.fillRect(x, exerciseY, w, exerciseBarH);
         ctx.strokeRect(x, exerciseY, w, exerciseBarH);
         cursorInfo.suggestArea(x, exerciseY, w, exerciseBarH, "Exercise: " + exercise[1] + " minutes", exercise[0], exercise[0] + exercise[1] * 60);
      }
      ctx.globalAlpha = 1.0;
   };

   drawBasalInterval = (interval: OverlayInterval, highlight:boolean, anyIsHighlighted: boolean, cursorInfo: CursorInfo) => {
      let basalList = this.data.basal;
      let [minI, maxI] = getRange(basalList, interval.from, interval.to);
      let lastY = undefined;
      let ctx = this.ctx;
      let graphY = this.basalAxis.top;
      let graphH = this.basalAxis.height;
      let lastBasalType = BasalType.Normal;

      // Set alpha:
      let alpha = 0.5;
      if(anyIsHighlighted) {
         alpha = highlight ? 1.0 : 0.15;
      }
      ctx.globalAlpha = alpha;

      // Set the clip:
      ctx.save();
      ctx.beginPath();
      ctx.rect(this.scaleW, this.basalAxis.top, this.canvas.width - this.scaleW,
        this.basalAxis.height + (this.settings.showAutomaticSuspend ? this.suspendComp.height : 0));
      ctx.clip();

      for(let i = Math.max(0, minI - 1); i <= maxI; i++) {
         let basal = basalList[i];
         let nextBasal = basalList[i + 1];
         if(!nextBasal) {
            break;
         }
         let basalType = basal[3];
         let isNormalType = basalType == BasalType.Normal;

         let fromX = this.area.getX(basal[0] - interval.from);
         let toX = this.area.getX(nextBasal[0] - interval.from);

         let scheduledY = this.basalAxis.getY(basal[1]);
         let deliveredY = this.basalAxis.getY(basal[2]);
         if (basalType == BasalType.LowGlucoseSuspend || basalType == BasalType.PredictiveLowGlucoseSuspend) {
            //lastY = undefined;
            if(this.settings.showAutomaticSuspend) {
               ctx.fillStyle = getBasalColor(basalType);
               ctx.globalAlpha = Style.HIDE_ALPHA * alpha;
               ctx.fillRect(fromX, this.suspendComp.top + 4 * this.dpr, toX - fromX, this.suspendComp.height - 8 * this.dpr);
               ctx.globalAlpha = alpha;
               let cursorDesc = basalType == BasalType.LowGlucoseSuspend ? "Low glucose suspend" : "Predictive low glucose suspend";
               cursorInfo.suggestArea(fromX, this.suspendComp.top, toX - fromX, this.suspendComp.height, cursorDesc, undefined, undefined, true);
            }
         }
         if(fromX <= cursorInfo.cursorX &&
            cursorInfo.cursorX <= toX &&
            cursorInfo.cursorY > graphY &&
            cursorInfo.cursorY < graphY + graphH) {
            const cursorText: string[][] = []
            cursorText.push(["Delivered: " + basal[2] + " U/h"]);
            if(basal[1] != basal[2]) {
               cursorText.push(["Scheduled: " + basal[1] + " U/h"])
            }
            if(basalType == BasalType.TempModified) {
               cursorText.push([" (Temp. modified)"]);
            } else if(basalType == BasalType.LowGlucoseSuspend) {
               cursorText.push([" (Low glucose suspend)"]);
            } else if(basalType == BasalType.PredictiveLowGlucoseSuspend) {
               cursorText.push([" (Predictive low glucose suspend)"]);
            }
            cursorInfo.suggestPoint(cursorInfo.cursorX, deliveredY, cursorText, basal[0], nextBasal[0]);
         }
         if (lastY != undefined) {
            let firstLineType = isNormalType ? lastBasalType : basalType;
            drawBasalLine(this, fromX, lastY, fromX, deliveredY, firstLineType);
         }
         drawBasalLine(this, fromX, deliveredY, toX, deliveredY, basalType);
         lastY = deliveredY;
         if(deliveredY != scheduledY) {
            // Draw delivered:
            ctx.fillStyle = scheduledY > deliveredY ? "rgba(0, 0, 255, 0.1)" : "rgba(0, 255, 0, 0.2)";
            ctx.fillRect(fromX, scheduledY, toX - fromX,deliveredY - scheduledY);
            //drawBasalLine(this, fromX, scheduledY, toX, scheduledY, BasalType.Normal);
         }

         lastBasalType = basalType;
      }
      ctx.globalAlpha = 1.0;
      ctx.restore();
   };

   drawBolusInterval = (interval: OverlayInterval, graphY: number, highlight: boolean, cursorInfo: CursorInfo) => {
      let bolusList = this.data.bolus;
      let [minI, maxI] = getRange(bolusList, interval.from, interval.to);
      let ctx = this.ctx;
      let dpr = this.dpr;

      ctx.globalAlpha = highlight ? 1.0 : Style.HIDE_ALPHA;

      ctx.strokeStyle = Style.AXIS_HORIZONTAL;
      drawLine(ctx, this.area.getX(0) - 5, graphY, this.area.getX(3600 * 24), graphY);

      ctx.fillStyle = "#919191";
      ctx.textBaseline = "middle";
      ctx.textAlign = "right";
      this.setFont(10);
      ctx.fillText(interval.dateAsString, this.area.x - 8, graphY);
      ctx.textBaseline = "alphabetic";
      ctx.textAlign = "left";

      ctx.strokeStyle = Style.BOLUS_BUBBLE_STROKE;
      ctx.fillStyle = Style.BOLUS_BUBBLE_FILL;
      for(let i = Math.max(0, minI); i <= maxI; i++) {
         let bolus = bolusList[i];
         if(bolus) {
            let bolusData:BolusData = bolus[1];
            let x = this.area.getX(bolus[0] - interval.from);
            ctx.beginPath();
            if(bolusData.immediate) {
               ctx.arc(x, graphY, dpr * (1 + 1.7 * Math.pow(bolusData.immediate, 0.7)), 0, 2 * Math.PI);
            }
            let w = 0;
            if(bolusData.extended) {
               w = this.area.getX(bolus[0] - interval.from + bolusData.duration * 60) - x;
               ctx.fillRect(x, graphY - 3, w, 6);
            }

            ctx.fill();
            ctx.stroke();
            ctx.closePath();

            cursorInfo.suggestArea(x, graphY, w, 0, bolusData, bolus[0], bolus[0] + bolusData.duration * 60);
         }
      }
      ctx.globalAlpha = 1;
   };
}