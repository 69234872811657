import {findValue, SearchType, unixToString} from "./DataTools";

export class GraphData {
   basal: [number, number, number, BasalType][] = []; // time, scheduled rate, delivered rate, type
   insulin: [number, number][] = [];
   bolus: [number, BolusData][] = [];
   events: any[] = [];
   carbs: [number, number, string][] = [];
   exercise: [number, number, number][] = []; // time, duration, intensity
   glucose: [number, number, number][] = []; // time, value in mmol, hour, day
   cgm: [number, number, number][] = []; // time, value in mmol, hour, day
   tdds: [number, number, number][] = []; // time, basal, bolus
   pumpModes: [number, number, string][] = []; // time, duration, type

   from: number;
   to:number;

   constructor(from: number, to:number) {
      this.from = from;
      this.to = to;
   }

   add(graphData: GraphData) {
      if(graphData.from > this.from) {
         this.basal = this.basal.concat(graphData.basal);
         this.insulin = this.insulin.concat(graphData.insulin);
         this.bolus = this.bolus.concat(graphData.bolus);
         this.events = this.events.concat(graphData.events);
         this.carbs = this.carbs.concat(graphData.carbs);
         this.exercise = this.exercise.concat(graphData.exercise);
         this.glucose = this.glucose.concat(graphData.glucose);
         this.cgm = this.cgm.concat(graphData.cgm);
         this.tdds = this.tdds.concat(graphData.tdds);
         this.pumpModes = this.pumpModes.concat(graphData.pumpModes);
      } else {
         this.basal = graphData.basal.concat(this.basal);
         this.insulin = graphData.insulin.concat(this.insulin);
         this.bolus = graphData.bolus.concat(this.bolus);
         this.events = graphData.events.concat(this.events);
         this.carbs = graphData.carbs.concat(this.carbs);
         this.exercise = graphData.exercise.concat(this.exercise);
         this.glucose = graphData.glucose.concat(this.glucose);
         this.cgm = graphData.cgm.concat(this.cgm);
         this.tdds = graphData.tdds.concat(this.tdds);
         this.pumpModes = graphData.pumpModes.concat(this.pumpModes);
      }

      this.from = Math.min(this.from, graphData.from);
      this.to = Math.max(this.to, graphData.to);
   }

   getMissingRange(from: number, to:number):[number, number]{
      if(from < this.from) {
         // Return range before this:
         return [from, this.from - 1];
      } else if(to > this.to) {
         // Return range after this:
         return [this.to + 1, to];
      } else {
         return undefined;
      }
   }

   getTdd(from: number, to: number) : [number, number, number] {
      let value = findValue(this.tdds, to, SearchType.Backward);
      console.log(unixToString(from) + " - " + unixToString(to) + ": " + unixToString(value[0]));
      return undefined;
   }

   covers(from: number, to:number) {
      return from >= this.from && to <= this.to;
   }
}

export enum BasalType {
   Normal = 0,
   TempModified = 1,
   LowGlucoseSuspend = 2,
   PredictiveLowGlucoseSuspend = 3
}

export interface BolusData {
   immediate?: number,
   extended?: number,
   duration?: number,

   automatic?: boolean,

   bgValue?: number;
   carbs?: number;
   suggCorr?: number;
   suggMeal?: number;
   isf?: number;
   iob?: number;
   bgTarget?: number;
   icRatio?: number;
}
