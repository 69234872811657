import {TimelineSettings} from "../graphs/TimelineSettings";
import GlookoGraphRepository from "../graphs/GlookoGraphRepository";
import {TimeRangeStatistics} from "../graphs/TimeRangeStatistics";
import React, {useEffect, useRef, useState} from "react";
import {MiniatureGraphController} from "./GraphController";
import MiniatureGraph from "../graphs/MiniatureGraph";

export interface Props {
  style?: any;
  graphController: MiniatureGraphController;
  repo: GlookoGraphRepository;
  settings: TimelineSettings;
  setStatistics: (statistics: TimeRangeStatistics) => void;
  initialRange?: any;
}

export const MiniatureGraphComp = (props: Props) =>{
  const canvas = useRef(null);
  const [miniatureGraph, setMiniatureGraph] = useState<MiniatureGraph | undefined>(undefined)

  const initiate = () => {
    if(!miniatureGraph && canvas.current) {
      const mg = new MiniatureGraph(
        canvas.current,
        props.repo,
        props.settings,
        props.setStatistics);

      if(props.initialRange) {
        mg.showRange(props.initialRange.from, props.initialRange.to, false);
      } else {
        mg.showLastDays(7, false);
      }

      props.graphController.graph = mg;
      setMiniatureGraph(mg);
    }
  }

  useEffect(initiate, [canvas, props.repo]);

  const unmount = () => {
    miniatureGraph?.destroyGraph();
    setMiniatureGraph(undefined);
  }

  useEffect( () => unmount, [] );

  return <canvas style={props.style} ref={canvas} ></canvas>
}
