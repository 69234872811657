import {BasalType, GraphData} from "./GraphData";
import moment, {Moment} from "moment";
import {findIndex, SearchType, toMoment} from "./DataTools";
import GraphRepository from "./GraphRepository";
import {GraphResources} from "./GraphResources";
import {PatientData} from "./PatientData";

export default class GlookoGraphRepository extends GraphRepository {

   private readonly glookoCode: string;
   private readonly csrfToken?: string;
   private readonly apiUrl: string;

   private addedModes = new Set<string>()

   static readonly FORMAT = "YYYY-MM-DDTHH:mm:ss[.000Z]";
   static readonly IGNORED_EVENTS = [
      500014, // Low suspended
      500015, // Predictive low suspended
   ];

   constructor(apiUrl: string, glookoCode: string, resources: GraphResources, lastSyncTime: string, patientData: PatientData, csrfToken?: string) {
      super(resources, lastSyncTime, patientData);
      this.apiUrl = apiUrl;
      this.csrfToken = csrfToken;
      this.glookoCode = glookoCode;
      setTimeout(() => {this.callOnUpdate()})
   }

   requestData = (from:number, to: number):boolean => {
      if(this.requestingGraphData) {
         // Already requesting. Do nothing.
         return false;
      } else if(this.graphData) {
         let missingRange = this.graphData.getMissingRange(from, to);
         if(missingRange) {
            this.requestingGraphData = new GraphData(missingRange[0], missingRange[1]);
         }
      } else {
         // No data at all. Request everything:
         this.requestingGraphData = new GraphData(from, to);
      }

      if(this.requestingGraphData) {
         let fromString = toMoment(this.requestingGraphData.from).format(GlookoGraphRepository.FORMAT);
         let toString = toMoment(this.requestingGraphData.to).format(GlookoGraphRepository.FORMAT);


         let exerciseDataUrl = "v3/graph/exercise_data?startDate=" + fromString + "&endDate=" + toString + "&includeRoutines=true&series[]=steps"

         let graphDataUrl = "v3/graph/data?startDate=" + fromString + "&endDate=" + toString +
            "&series[]=bgHigh" +
            "&series[]=bgNormal" +
            "&series[]=bgLow" +
            "&series[]=bgAbove400" +
            "&series[]=bgHighManual" +
            "&series[]=bgNormalManual" +
            "&series[]=bgLowManual" +
            "&series[]=bgAbove400Manual" +

            "&series[]=cgmNormal" +
            "&series[]=cgmHigh" +
            "&series[]=cgmLow" +
            "&series[]=cgmCalibrationHigh" +
            "&series[]=cgmCalibrationNormal" +
            "&series[]=cgmCalibrationLow" +

            "&series[]=suggestedBolus" +
            "&series[]=deliveredBolus" +
            "&series[]=extendedBolusStep" +
            "&series[]=interruptedBolus" +
            "&series[]=overrideAboveBolus" +
            "&series[]=overrideBelowBolus" +
            "&series[]=injectionBolus" +
            "&series[]=automaticBolus" +

            "&series[]=scheduledBasal" +
            "&series[]=temporaryBasal" +
            "&series[]=suspendBasal" +
            "&series[]=lgsPlgs" +
            "&series[]=basalModulation" +

            "&series[]=pumpBasaliqAutomaticMode" +
            "&series[]=pumpBasaliqManualMode" +
            "&series[]=pumpControliqAutomaticMode" +
            "&series[]=pumpControliqExerciseMode" +
            "&series[]=pumpControliqManualMode" +
            "&series[]=pumpControliqSleepMode" +
            "&series[]=pumpGenericAutomaticMode" +
            "&series[]=pumpGenericManualMode" +
            "&series[]=pumpOp5AutomaticMode" +
            "&series[]=pumpOp5HypoprotectMode" +
            "&series[]=pumpOp5LimitedMode" +
            "&series[]=pumpOp5ManualMode" +

            "&series[]=reservoirChange" +
            "&series[]=setSiteChange" +
            "&series[]=pumpAlarm" +

            "&series[]=carbAll" +
            "&series[]=gkCarb" +
            "&series[]=carbNonManual" +

            "&series[]=gkInsulin" +
            "&series[]=gkInsulinBolus" +
            "&series[]=gkInsulinBasal" +
            "&series[]=gkInsulinPremixed" +
            "&series[]=gkInsulinOther" +

            "&series[]=totalInsulinPerDay" +
            "&series[]=basalUnitsPerDay" +
            "&series[]=bolusUnitsPerDay" +

            "&locale=en";
         if(this.glookoCode) {
            graphDataUrl += "&patient=" + this.glookoCode
            exerciseDataUrl += "&patient=" + this.glookoCode
         }
         console.log("Request " + fromString + " -> " + toString);
         let headers:any = {};
         if(this.csrfToken) {
            headers["X-CSRF-Token"] = this.csrfToken;
         }
         Promise.all([
            fetch(this.apiUrl + graphDataUrl, {
              headers: headers,
              credentials: 'include'
            }),
            fetch(this.apiUrl + exerciseDataUrl, {
               headers: headers,
               credentials: 'include'
            })]).then(data => {
               Promise.all([data[0].json(), data[1].json()]).then(data => {
                  this.dataReceived(data[0]);
                  console.log("Exercise data ", data[1]);
               })
         })

         /*promises[0]
           .then(response => response.json())
           .then(this.dataReceived);
*/
         //this.errorMessage = GlookoGraphRepository.formatErrorMessage(xmlHttp);
         //this.callOnUpdate();
         return false;
      } else {
         return true;
      }
   };

   dataReceived = (data: any) => {
      let graphData = this.requestingGraphData!;
      console.log("Received ", data);
      const series = data.series;
      const getDate = (string:string) => moment(string, GlookoGraphRepository.FORMAT).utc(true);
      const getHour = (date: Moment) => {
         let hour = date.hours();
         if (date.minutes() >= 30) {
            hour = hour == 23 ? 0 : (hour + 1);
         }
         return hour;
      };
      const sort = (list: any) => {
         list.sort((a: any, b: any) => (a[0] - b[0]))
      };

      for(let cgmNormal of series.cgmNormal) {
         /*mealTag: "none"
         timestamp: "2020-10-25T01:15:00.000Z"
         value: 7100
         x: 1603588500
         y: 71*/
         const date = getDate(cgmNormal.timestamp);
         const value = cgmNormal.value / 1801.6;
         graphData.cgm.push([date.unix(), value, getHour(date)]);
      }
      for(let cgmHigh of series.cgmHigh) {
         /*mealTag: "none"
         timestamp: "2020-10-25T01:15:00.000Z"
         value: 7100
         x: 1603588500
         y: 71*/
         const date = getDate(cgmHigh.timestamp);
         const value = cgmHigh.value / 1801.6;
         graphData.cgm.push([date.unix(), value, getHour(date)]);
      }
      for(let cgmLow of series.cgmLow) {
         /*mealTag: "none"
         timestamp: "2020-10-25T01:15:00.000Z"
         value: 7100
         x: 1603588500
         y: 71*/
         const date = getDate(cgmLow.timestamp);
         const value = cgmLow.value / 1801.6;
         graphData.cgm.push([date.unix(), value, getHour(date)]);
      }
      sort(graphData.cgm);

      for(let bgLow of series.bgLow) {
         /*mealTag: "none"
         timestamp: "2020-10-25T01:15:00.000Z"
         value: 7100
         x: 1603588500
         y: 71*/
         const date = getDate(bgLow.timestamp);
         const value = bgLow.value / 1801.6;
         graphData.glucose.push([date.unix(), value, getHour(date)]);
      }
      for(let bgNormal of series.bgNormal) {
         /*mealTag: "none"
         timestamp: "2020-10-25T01:15:00.000Z"
         value: 7100
         x: 1603588500
         y: 71*/
         const date = getDate(bgNormal.timestamp);
         const value = bgNormal.value / 1801.6;
         graphData.glucose.push([date.unix(), value, getHour(date)]);
      }
      for(let bgHigh of series.bgHigh) {
         /*mealTag: "none"
         timestamp: "2020-10-25T01:15:00.000Z"
         value: 7100
         x: 1603588500
         y: 71*/
         const date = getDate(bgHigh.timestamp);
         const value = bgHigh.value / 1801.6;
         graphData.glucose.push([date.unix(), value, getHour(date)]);
      }
      for(let bgLow of series.bgLowManual) {
         /*mealTag: "none"
         timestamp: "2020-10-25T01:15:00.000Z"
         value: 7100
         x: 1603588500
         y: 71*/
         const date = getDate(bgLow.timestamp);
         const value = bgLow.value / 1801.6;
         graphData.glucose.push([date.unix(), value, getHour(date)]);
      }
      for(let bgNormal of series.bgNormalManual) {
         /*mealTag: "none"
         timestamp: "2020-10-25T01:15:00.000Z"
         value: 7100
         x: 1603588500
         y: 71*/
         const date = getDate(bgNormal.timestamp);
         const value = bgNormal.value / 1801.6;
         graphData.glucose.push([date.unix(), value, getHour(date)]);
      }
      for(let bgHigh of series.bgHighManual) {
         /*mealTag: "none"
         timestamp: "2020-10-25T01:15:00.000Z"
         value: 7100
         x: 1603588500
         y: 71*/
         const date = getDate(bgHigh.timestamp);
         const value = bgHigh.value / 1801.6;
         graphData.glucose.push([date.unix(), value, getHour(date)]);
      }
      sort(graphData.glucose);

      for(let deliveredBolus of series.deliveredBolus) {
         /*
         carbsInput: 23
         durationString: null
         extendedDelivery: null
         extendedDeliveryPercentage: null
         highestBolusValue: 2.85
         initialDelivery: null
         initialDeliveryPercentage: null
         insulinDelivered: 2.85
         insulinOnBoard: 0
         insulinProgrammed: 2.85
         insulinRecommendationForCarbs: 2.85
         insulinRecommendationForCorrection: 0
         isInterrupted: false
         isManual: false
         isOverrideAbove: false
         isOverrideBelow: false
         isUnknownComboBolus: null
         presetVolume: null
         timestamp: "2020-10-25T05:44:00.000Z"
         totalInsulinRecommendation: 2.85
         x: 1603604640
         y: 2.85
         */
         const date = getDate(deliveredBolus.timestamp);
         graphData.bolus.push([date.unix(), {
            immediate: deliveredBolus.insulinDelivered,
            extended: 0,
            duration: 0,

            //bgValue?: number;
            carbs: deliveredBolus.carbsInput,
            suggCorr: deliveredBolus.insulinRecommendationForCorrection,
            suggMeal: deliveredBolus.insulinRecommendationForCarbs,
            //isf?: number;
            iob: deliveredBolus.insulinOnBoard,
            //bgTarget?: number;
            //icRatio?: number;
         }]);
      }

      for(let extendedBolusStep of series.extendedBolusStep) {
         /*
         carbsInput: 75
         durationString: "4 hrs"
         extendedDelivery: 4
         extendedDeliveryPercentage: 50
         highestBolusValue: 5
         initialDelivery: 4
         initialDeliveryPercentage: 50
         insulinDelivered: 8
         insulinOnBoard: 0
         insulinProgrammed: 8
         insulinRecommendationForCarbs: 5
         insulinRecommendationForCorrection: 0
         isInterrupted: false
         isManual: false
         isOverrideAbove: true
         isOverrideBelow: false
         isUnknownComboBolus: false
         presetVolume: null
         timestamp: "2020-10-19T19:00:00.000Z"
         totalInsulinRecommendation: 5
         */
         const date = getDate(extendedBolusStep.timestamp);
         graphData.bolus.push([date.unix(), {
            immediate: extendedBolusStep.initialDelivery,
            extended: extendedBolusStep.extendedDelivery,
            duration: 30, // TODO: Duration

            //bgValue?: number;
            carbs: extendedBolusStep.carbsInput,
            suggCorr: extendedBolusStep.insulinRecommendationForCorrection,
            suggMeal: extendedBolusStep.insulinRecommendationForCarbs,
            //isf?: number;
            iob: extendedBolusStep.insulinOnBoard,
            //bgTarget?: number;
            //icRatio?: number;
         }]);
      }

      for(let automaticBolus of series.automaticBolus) {
         const date = getDate(automaticBolus.timestamp);
         graphData.bolus.push([date.unix(), {
            automatic: true,
            immediate: automaticBolus.insulinDelivered,
            extended: 0,
            duration: 0,

            suggCorr: automaticBolus.insulinRecommendationForCorrection,
            suggMeal: automaticBolus.insulinRecommendationForCarbs,
            iob: automaticBolus.insulinOnBoard,
         }]);
      }
      sort(graphData.bolus);

      let lastBasalTimestamp:number = 0;
      for(let scheduledBasal of series.scheduledBasal) {
         /*
         displayTooltip: true
         duration: 7200
         durationString: "12:00am to  2:00am"
         endTimestamp: "2020-10-25T02:00:00.000Z"
         interpolated: false
         rate: 0.7
         startTime: 25200
         timestamp: "2020-10-25T00:00:00.000Z"
          */
         let timestamp = getDate(scheduledBasal.timestamp).unix();
         if(lastBasalTimestamp != timestamp) {
            lastBasalTimestamp = timestamp;
            graphData.basal.push([
               timestamp,
               scheduledBasal.rate,
               scheduledBasal.rate,
               BasalType.Normal
            ])
         }
      }
      for(let temporaryBasal of series.temporaryBasal) {
         /*
         duration: 3600
         durationString: "11:00pm to 12:00am"
         endTimestamp: "2020-10-19T00:00:00.000Z"
         interpolated: false
         percentage: 0.5
         rate: 0.25
         startTime: 21600
         timestamp: "2020-10-18T23:00:00.000Z"
          */
         graphData.basal.push([
            getDate(temporaryBasal.timestamp).unix(),
            temporaryBasal.rate,
            temporaryBasal.rate,
            BasalType.TempModified
         ])
      }
      for(let suspendBasal of series.suspendBasal) {
         /*
         duration: 1800
         durationString: " 7:20pm to  7:50pm"
         endTimestamp: "2020-10-26T19:50:00.000Z"
         marker: {enabled: true}
         rateAtResume: 0.6499999761581421
         rateAtStart: 0.6499999761581421
         startTime: 84000
         timestamp: "2020-10-26T19:20:00.000Z"
         */
         graphData.basal.push([
            getDate(suspendBasal.timestamp).unix(),
            0,
            0,
            BasalType.TempModified
         ])
      }
      sort(graphData.basal);

      for(let item of series.lgsPlgs) {
         const from = getDate(item.timestamp).unix();
         const to = getDate(item.endTimestamp).unix();
         this.setDeliveredBasal(graphData.basal, from, to, 0, BasalType.LowGlucoseSuspend);
      }

      for(let item of series.basalModulation) {
         const from = getDate(item.timestamp).unix();
         const to = getDate(item.endTimestamp).unix();
         const rate = item.rate;
         this.setDeliveredBasal(graphData.basal, from, to, rate, BasalType.Normal);
      }

      for(let gkCarb of series.gkCarb) {
         /*
         carbs: 18
         name: "Granola Bar"
         timestamp: "2020-10-25T15:00:00.000Z"
         x: 1603638000
         y: 50
         yOrig: 18
         */
         graphData.carbs.push([
            getDate(gkCarb.timestamp).unix(),
            gkCarb.carbs,
            gkCarb.name])
      }
      for(let carb of series.carbAll) {
         /*
         carbs: 18
         name: "Granola Bar"
         timestamp: "2020-10-25T15:00:00.000Z"
         x: 1603638000
         y: 50
         yOrig: 18
         */
         graphData.carbs.push([
            getDate(carb.timestamp).unix(),
            carb.carbs,
            carb.name])
      }
      sort(graphData.carbs);


      for(let setSiteChange of series.setSiteChange) {
         graphData.events.push([getDate(setSiteChange.timestamp).unix(), 500021])
      }
      for(let reservoirChange of series.reservoirChange) {
         graphData.events.push([getDate(reservoirChange.timestamp).unix(), 510001])
      }
      sort(graphData.events)

      for(let time in series.dailyInsulinTotals) {
         /*
            1603627200:
            basalUnitsPerDay: 14
            bolusUnitsPerDay: 10.5
            totalInsulinPerDay: 24.5
            totalOtherInsulinPerDay: 0
            totalPumpInsulinPerDay: 24.5
          */
         let dailyInsulinTotals = series.dailyInsulinTotals[time]
         graphData.tdds.push([
            time as any as number,
            dailyInsulinTotals["basalUnitsPerDay"],
            dailyInsulinTotals["bolusUnitsPerDay"]]);
      }

      const capitalizeFirstLetter = (s: string) => {
         return s.charAt(0).toUpperCase() + s.slice(1);
      };

      const addModes = (name: string, type: string) => {
         for(let mode of series[name]) {
            const modeAsString = mode.timestamp + "-" + mode.duration + "-" + mode.type;
            if(!this.addedModes.has(modeAsString)) {
               this.addedModes.add(modeAsString);
               console.log("mode type=" + type, mode);
               graphData.pumpModes.push([
                  getDate(mode.timestamp).unix(),
                  mode.duration,
                  capitalizeFirstLetter(type.replace("_", " ").replace("cgm", "CGM"))
               ])
            }
         }
      }

      addModes("pumpControliqAutomaticMode", "Control-IQ");
      addModes("pumpControliqExerciseMode", "exercise");
      addModes("pumpControliqManualMode", "manual");
      addModes("pumpControliqSleepMode", "sleep");
      addModes("pumpOp5AutomaticMode", "automatic");
      addModes("pumpOp5HypoprotectMode", "activity");
      addModes("pumpOp5LimitedMode", "limited");
      addModes("pumpOp5ManualMode", "manual");
      addModes("pumpBasaliqAutomaticMode", "Basal-IQ");
      addModes("pumpBasaliqManualMode", "manual");
      addModes("pumpGenericAutomaticMode", "automatic");
      addModes("pumpGenericManualMode", "manual");

      sort(graphData.pumpModes);

      /*for(let item of data) {
         //console.log(item);
         let date = moment(item.created_at, GlookoGraphRepository.FORMAT).utc(true);
         let timestamp = date.unix();
         switch(item.type) {
            case "glucose":
               // Get hour for AGP graph:
               if(item.value) {
                  let hour = date.hours();
                  if (date.minutes() >= 30) {
                     hour = hour == 23 ? 0 : (hour + 1);
                  }
                  let isCGM = item.flags && item.flags.some(flag => flag.flag == 123);
                  if (isCGM) {
                     graphData.cgm.push([timestamp, item.value, hour]);
                  } else {
                     graphData.glucose.push([timestamp, item.value, hour]);
                  }
               }
               break;
            case "insulin_pen":
               graphData.insulin.push([timestamp, item.value]);
               break;
            case "insulin_basal":
               let basalType = BasalType.Normal;
               if(item.flags) {
                  if(item.flags.some(flag => flag.flag == 1201)) {
                     basalType = BasalType.LowGlucoseSuspend;
                  } else if(item.flags.some(flag => flag.flag == 1200)) {
                     basalType = BasalType.PredictiveLowGlucoseSuspend;
                  } else if(item.flags.some(flag => flag.flag == 1000)) {
                     basalType = BasalType.TempModified;
                  }
               }
               graphData.basal.push([timestamp, item.value, basalType]);
               break;
            case "insulin_bolus":
               let bolus:BolusData = {};
               if(item.duration) {
                  bolus.duration = item.duration / 60;
               } else {
                  bolus.duration = 0;
               }
               if(item.spike_value) {
                  bolus.immediate = item.spike_value;
               }
               if(item.total_value !== item.spike_value) {
                  bolus.extended = item.total_value - (item.spike_value || 0);
               }
               if(item.bolus_calc) {
                  let bolus_calc = item.bolus_calc;
                  if (bolus_calc.bg_target) {
                     bolus.bgTarget = bolus_calc.bg_target.value;
                  }
                  if (bolus_calc.bg_value) {
                     bolus.bgValue = bolus_calc.bg_value.value;
                  }
                  if (bolus_calc.carbs) {
                     bolus.carbs = bolus_calc.carbs.value;
                  }
                  if (bolus_calc.ic_ratio) {
                     bolus.icRatio = bolus_calc.ic_ratio.value;
                  }
                  if (bolus_calc.iob) {
                     bolus.iob = bolus_calc.iob.value;
                  }
                  if (bolus_calc.isf) {
                     bolus.isf = bolus_calc.isf.value;
                  }
                  if (bolus_calc.sugg_corr) {
                     bolus.suggCorr = bolus_calc.sugg_corr.value;
                  }
                  if (bolus_calc.sugg_meal) {
                     bolus.suggMeal = bolus_calc.sugg_meal.value;
                  }
               }
               graphData.bolus.push([timestamp, bolus]);
               break;
            case "carb":
               graphData.carbs.push([timestamp, parseFloat(item.value as unknown as string)]);
               break;
            case "event":
            case "alarm":
               if(GlookoGraphRepository.IGNORED_EVENTS.indexOf(item.value) == -1) {
                  graphData.events.push([timestamp, item.value]);
               }
               break;
            case "insulin_tdd":
               graphData.tdds.push([timestamp, item.basal, item.total - item.basal]);
               break;
            default:
               console.log("Unknown item type in ", item);
         }
      }*/

      if(!this.graphData) {
         this.graphData = graphData;
      } else {
         this.graphData.add(graphData);
      }
      this.requestingGraphData = undefined;
      this.callOnUpdate();
   };

   setDeliveredBasal = (data: [number, number, number, BasalType][], from: number, to: number, rate: number, basalType: BasalType) => {
      const s = data[0][0];

      let fromIndex = findIndex(data, from, SearchType.Backward)!;
      const fromItem = data[fromIndex];
      if(fromItem[0] != from) {
         // Need to insert a new item:
         fromIndex++;
         data.splice(fromIndex, 0, [from, fromItem[1], fromItem[2], fromItem[3]]);
      }

      let toIndex = findIndex(data, to, SearchType.Backward)!;
      const toItem = data[toIndex];
      if(toItem[0] != to) {
         // Need to insert a new item:
         toIndex++;
         data.splice(toIndex, 0, [to, toItem[1], toItem[2], toItem[3]]);
      }

      for(let i = fromIndex; i < toIndex; i++) {
         data[i][2] = rate;
         data[i][3] = basalType;
      }
   }

   getGraphData = () => {
      return this.graphData;
   };
}