import TimelineGraph from "../graphs";
import {TimelineSettings} from "../graphs/TimelineSettings";
import AbstractGraph from "../graphs/AbstractGraph";
import OverlayGraph from "../graphs/OverlayGraph";
import {OverlaySettings, OverlayType} from "../graphs/OverlaySettings";
import {GraphSettings} from "../graphs/GraphSettings";
import MiniatureGraph from "../graphs/MiniatureGraph";

export abstract class GraphController<S extends GraphSettings, T extends AbstractGraph<S>> {
  graph? : T;
  setSettings = (settings: S) => this.graph?.setSettings(settings);
  scheduleRepaint = () => this.graph?.scheduleRepaint();
  setHighlightedEventId = (eventId?:number) => this.graph?.setHighlightedEventId(eventId);
  showLastDays = (nDays: number) => this.graph?.showLastDays(nDays);
}

export class TimelineGraphController extends GraphController<TimelineSettings, TimelineGraph> {
  showDays = (nDays: number) => this.graph?.showDays(nDays);
  panDays = (nDays: number) => this.graph?.panDays(nDays);
  showRange = (from: number, to: number, ) => this.graph?.showRange(from, to);
}

export class MiniatureGraphController extends GraphController<TimelineSettings, MiniatureGraph> {
  showRange = (from: number, to: number, ) => this.graph?.showRange(from, to);
}

export class OverlayGraphController extends GraphController<OverlaySettings, OverlayGraph> {
  showEventOverlay = (type: OverlayType, timeSpanLength?: number) => this.graph?.showEventOverlay(type, timeSpanLength);
  setInterval= (from:string, to: string) => this.graph?.setInterval(from, to);
}