import React from "react";
import 'chart.js/auto';
import { Doughnut } from 'react-chartjs-2';
import {InsulinStatistics} from "../graphs/TimeRangeStatistics";

export interface Props {
  style: any;
  insulinStatistics: InsulinStatistics;
}

export const InsulinChartComp = (props: Props) => {
  const data = {
    labels: ['Bolus', 'Basal'],
    datasets: [
      {
        backgroundColor: ['#9cb6e4', "#0c56c4"],
        data: [props.insulinStatistics.totalBolusPercentage, props.insulinStatistics.totalBasalPercentage]
      }
    ]
  }

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        enabled: false
      }
    },
    animation: {
      duration: 300
    }
  }

  return <Doughnut style={props.style} data={data} options={options}/>
}