import TimelineGraph from "../graphs";
import {TimelineSettings} from "../graphs/TimelineSettings";
import GlookoGraphRepository from "../graphs/GlookoGraphRepository";
import {TimeRangeStatistics} from "../graphs/TimeRangeStatistics";
import {ZoomStatus} from "../graphs/ZoomStatus";
import React, {useEffect, useRef, useState} from "react";
import {TimelineGraphController} from "./GraphController";

export interface Props {
  style?: any;
  graphController: TimelineGraphController;
  repo: GlookoGraphRepository;
  settings: TimelineSettings;
  setStatistics: (statistics: TimeRangeStatistics) => void;
  setZoomStatus: (zoomStatus: ZoomStatus) => void;
  initialRange?: any;
}

export const TimelineGraphComp = (props: Props) =>{
  const canvas = useRef(null);
  const [timelineGraph, setTimelineGraph] = useState<TimelineGraph | undefined>(undefined)

  const initiate = () => {
    if(!timelineGraph && canvas.current) {
      const tlg = new TimelineGraph(
        canvas.current,
        props.repo,
        props.settings,
        props.setStatistics,
        props.setZoomStatus);

      if(props.initialRange) {
        tlg.showRange(props.initialRange.from, props.initialRange.to, false);
      } else {
        tlg.showLastDays(1, false);
      }

      props.graphController.graph = tlg;

      window.addEventListener("resize", tlg.scheduleRepaint);
      setTimelineGraph(tlg);
    }
  }

  useEffect(initiate, [canvas, props.repo]);

  const unmount = () => {
    timelineGraph?.destroyGraph();
    setTimelineGraph(undefined);
  }

  useEffect( () => unmount, [] );

  return <canvas style={props.style} ref={canvas} ></canvas>
}
