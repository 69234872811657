import React, {ChangeEvent, KeyboardEvent, useState} from "react";
import {OverlayInterval, OverlaySettings} from "../graphs/OverlaySettings";
import {OverlayGraphController} from "./GraphController";

interface WeekDay {
   name:string;
   index:number;
   isSelected:boolean;
}

export interface Props {
  settings:OverlaySettings;
  updateSettings: (settings: OverlaySettings) => void;
  graphController: OverlayGraphController;
}

const headerH = 20;
const dayH = 35;
const dayW = 65;

export const OverlayControlsComp = (props: Props) => {
   const settings = props.settings;
   const [showOptions, setShowOptions] = useState<boolean>(false);
   const [weekDays, setWeekDays] = useState<WeekDay[]>([
    {name: "Mon", index: 0, isSelected: true},
    {name: "Tue", index: 1, isSelected: true},
    {name: "Wed", index: 2, isSelected: true},
    {name: "Thu", index: 3, isSelected: true},
    {name: "Fri", index: 4, isSelected: true},
    {name: "Sat", index: 5, isSelected: true},
    {name: "Sun", index: 6, isSelected: true}
  ]);

   const onLastDaysClick = (nDays: number) => {
     props.graphController.showLastDays(nDays);
   }

   const onCustomClick = () => {
     let newSettings = structuredClone(settings);
     newSettings.lastDays = undefined;
     props.updateSettings(newSettings);
   }

  const clearHighlighted = () => {
      let newSettings = structuredClone(settings);
      newSettings.intervals.forEach((interval:OverlayInterval) => interval.isHighlighted = false);
      props.updateSettings(newSettings);
  }

  const onMouseEnterDay = (index: number) => {
    let newSettings = structuredClone(settings);
    newSettings.intervals.forEach((interval:OverlayInterval) => interval.isHighlighted = false);
    newSettings.intervals[index].isHighlighted = true;
    props.updateSettings(newSettings);
  }

  const onMouseLeaveDay = () => {
    clearHighlighted();
  }

  const onMouseEnterWeekDay = (weekDay: number) => {
    let newSettings = structuredClone(settings);
    newSettings.intervals.forEach((interval:OverlayInterval) => interval.isHighlighted = interval.weekDay == weekDay);
    props.updateSettings(newSettings);
  }

  const onMouseLeaveWeekDay = () => {
    clearHighlighted();
  }

  const onWeekDayClick = (weekDayIndex:number) => {
    let newWeekDays = structuredClone(weekDays);
    let newSettings = structuredClone(settings);

    let weekDay = newWeekDays[weekDayIndex];
    weekDay.isSelected = !weekDay.isSelected;
    newSettings.intervals.forEach((interval:OverlayInterval) => {
      if(interval.weekDay == weekDay.index) {
        interval.isVisible = weekDay.isSelected;
      }
    });
    setWeekDays(newWeekDays);
    props.updateSettings(newSettings);
  }

  const updateWeekDayStatus = (settings: OverlaySettings) => {
     let newWeekDays = structuredClone(weekDays);
     newWeekDays.forEach((weekDay:WeekDay) => {
      weekDay.isSelected = settings.intervals.find((interval:OverlayInterval) => interval.weekDay == weekDay.index && interval.isVisible) != undefined;
    });
    setWeekDays(newWeekDays);
  }

  const onCheckboxChange = (index: number) => {
    let newSettings = structuredClone(settings);
    newSettings.intervals[index].isVisible = !newSettings.intervals[index].isVisible
    updateWeekDayStatus(newSettings);
    props.updateSettings(newSettings);
  }

  const onGlucoseGraphType = (graphType: string) => {
    let newSettings = structuredClone(settings);
    newSettings.glucoseGraphType = graphType;
    props.updateSettings(newSettings);
  }

  const getWidth = (percentage:number, cgmCoveragePercentage: number) => {
    return (percentage * cgmCoveragePercentage / 100) + "%";
  }

  const onSettingsCheckbox = (settingsName: string) => {
    let newSettings = structuredClone(settings);
    newSettings[settingsName] = !newSettings[settingsName];
    props.updateSettings(newSettings);
  }

  const onIntervalChange = (e: KeyboardEvent<HTMLInputElement> | ChangeEvent<HTMLInputElement>) => {
    let newSettings = structuredClone(settings);
    newSettings[e.currentTarget.name] = e.currentTarget.value;
    props.updateSettings(newSettings);
    props.graphController.setInterval(newSettings.fromDay, newSettings.toDay);
  }

   /*watch: {
      settings: function (newVal: OverlaySettings, oldVal: OverlaySettings) {
         this.updateWeekDayStatus();
      }
   },*/
   return (
     <div style={{display:"flex", flexGrow: 1, flexDirection:"column"}}>
        <div style={{display:"flex", flexGrow: 1}}>
          <div>
              <div className="buttonGroup" style={{marginBottom:10}}>
                 <div className={`button ${settings.lastDays == 14 ? "selected" : ""}`} onClick={() => onLastDaysClick(14)}>Last 14 days</div>
                 <div className={`button ${settings.lastDays == 7 ? "selected" : ""}`} onClick={() => onLastDaysClick(7)}>Last 7 days</div>
                 <div className={`button ${!settings.lastDays ? "selected" : ""}`} onClick={onCustomClick}>Custom</div>
              </div>
            {!settings.lastDays && <div style={{display:"flex", alignItems:"center", marginBottom:10}}>
                  <input name="fromDay" value={settings.fromDay} onChange={onIntervalChange} onKeyUp={onIntervalChange} />
                  <div style={{margin: "0 10px"}}>-</div>
                  <input name="toDay" value={settings.toDay} onChange={onIntervalChange} onKeyUp={onIntervalChange} />
              </div>}
           </div>
          {settings.intervals.length && <div style={{marginLeft: "auto", position: 'relative', width: dayW * 7, height: dayH * (1 + settings.intervals[settings.intervals.length - 1].weekNumber) + headerH}} className="agpDays">
            {weekDays.map((weekDay, index) =>
              <div className="headerContainer"
                   key={weekDay.name}
                   style={{position: 'absolute', height: headerH, width: dayW, left: (weekDay.index * dayW)}}
                   onClick={() => onWeekDayClick(index)}
                   onMouseEnter={() => onMouseEnterWeekDay(weekDay.index)}
                   onMouseLeave={onMouseLeaveWeekDay}
                   >
                    <div className={`header ${weekDay.isSelected ? "selected" : ""}`}>{weekDay.name}</div>
              </div>)
            }
            {settings.intervals.map((interval, index) =>
              <div className="agpDayContainer"
                   key={interval.from}
                   style={{position: 'absolute', top: interval.weekNumber * dayH + headerH, left: interval.weekDay * dayW, height: dayH, width: dayW}}
                   onMouseEnter={() => onMouseEnterDay(index)}
                   onMouseLeave={onMouseLeaveDay}>
                   <div className={`agpDay ${interval.isHighlighted && interval.isVisible ? "highlighted" : ""}`}>
                       <div className="agpTop"><input type="checkbox" checked={interval.isVisible} onChange={() => onCheckboxChange(index)}/>{interval.dateAsString}</div>
                       <div className="agpBar">
                         {(interval.statistics ? interval.statistics.intervals : []).map(statInterval =>
                           <div key={statInterval.description} style={{background: statInterval.color, width: getWidth(statInterval.percentage, interval.statistics.cgmCoveragePercentage)}}></div>)
                         }
                         {interval.statistics && <div style={{background: interval.statistics.missingColor, width: (100 - interval.statistics.cgmCoveragePercentage) + '%'}}></div>}
                       </div>
                   </div>
               </div>)
            }
          </div>}
       </div>
       <div style={{background: "#cccccc", height:1, margin:"15px 0"}}></div>
       <div style={{display:"flex", flexGrow:1, position:"relative"}}>
           <div className="buttonGroup" style={{margin: "auto"}}>
               <div className={`button ${settings.glucoseGraphType == "glucose" ? "selected" : ""}`} onClick={() => onGlucoseGraphType('glucose')}>Spaghetti</div>
               <div className={`button ${settings.glucoseGraphType == "agp" ? "selected" : ""}`} onClick={() => onGlucoseGraphType('agp')}>AGP</div>
           </div>
 
           <div className="buttonGroup">
               <div className={`button ${showOptions ? "selected" : ""}`} onClick={() => setShowOptions(!showOptions)}>Options</div>
           </div>
           <div className="optionsBox"
                style={{display: showOptions ? 'block' : 'none'}}>
               <div>
                  <input style={{marginLeft:10}} type="checkbox" checked={settings.showGlucose} onChange={() => onSettingsCheckbox("showGlucose")} />Glucose
               </div>
               <div>
                  <input style={{marginLeft:10}} type="checkbox" checked={settings.showCGM} onChange={() => onSettingsCheckbox("showCGM")} />CGM
               </div>
               <div>
                  <input style={{marginLeft:10}} type="checkbox" checked={settings.showAutomaticSuspend} onChange={() => onSettingsCheckbox("showAutomaticSuspend")} />Automatic suspend
               </div>
           </div>
       </div>
   </div>)
}