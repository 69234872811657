import {GlucoseStatistics} from "./TimeRangeStatistics";
import {GraphSettings} from "./GraphSettings";

export interface OverlaySettings extends GraphSettings{
   fromDay: string // Format: "YYYY-MM-DD"
   toDay: string   // Format: "YYYY-MM-DD"
   timeSpanLength: number // Length of time span in seconds
   intervals: OverlayInterval[]
   glucoseGraphType: "agp" | "glucose"
   type: OverlayType
   lastDays: number

   from: number
   to: number

   showGlucose: boolean;
   showCGM: boolean;
   showAutomaticSuspend: boolean;
}

export enum OverlayType {
   Days = "days",
   Hypo = "hypo",
   InfusionSetAndSiteChange = "infusionSetAndSiteChange",
   ExerciseStart = "exerciseStart",
   Breakfast = "breakfast",
   Lunch = "lunch",
   Dinner = "dinner",
}

export interface OverlayInterval {
   dateAsString: string
   date: string
   weekDay: number
   weekNumber: number
   isVisible: boolean
   isHighlighted: boolean

   statistics: GlucoseStatistics;

   from: number
   to: number
}


