import React, {useState} from "react";
import {TimelineSettings} from "../graphs/TimelineSettings";
import {ZoomStatus} from "../graphs/ZoomStatus";
import ButtonGroupComp from "./ButtonGroupComp";
import {TimelineGraphController} from "./GraphController";
import {TimelineDataTypesComp} from "./TimelineDataTypesComp";

export interface Props {
  settings: TimelineSettings,
  zoomStatus: ZoomStatus,
  graphController: TimelineGraphController;

  updateSettings: (settings: TimelineSettings) => void
}

const showDaysValues: [number, string][] = [
  [14, "14 days"],
  [7, "7 days"],
  [1, "1 day"]
]

export const TimelineControlsComp = (props: Props) => {
  const settings = props.settings;
  const zoomStatus = props.zoomStatus;
  const [showOptions, setShowOptions] = useState<boolean>(false);

  const toggleSetting = (name: string) => {
    let newSettings = structuredClone(props.settings);
    newSettings[name] = !newSettings[name];
    props.updateSettings(newSettings);
  };

   return (
    <div style={{display:"flex", marginLeft:"auto", alignItems: "center", position:"relative"}}>
      <ButtonGroupComp<number> style={{marginRight:"auto"}} values={showDaysValues} value={zoomStatus.nShownDays} onValueChange={props.graphController.showDays}/>
      <div className="buttonGroup" style={{marginRight:"auto"}}>
        <div className={`button ${zoomStatus.prevEnabled ? "" : "disabled"}` } onClick={() => props.graphController.panDays(-1)}>&lt;</div>
        <div className={`button ${zoomStatus.lastTimeRangeEnabled ? "" : "disabled"}`} onClick={() => props.graphController.showLastDays(zoomStatus.nShownDays)} style={{margin: "0 3px"}}>{zoomStatus.lastTimeRangeName}</div>
        <div className={`button ${zoomStatus.nextEnabled ? "" : "disabled"}`} onClick={() => props.graphController.panDays(1)}>&gt;</div>
      </div>

        <div className="buttonGroup">
          <div className={`button ${showOptions ? "selected" : ""}`} onClick={() => setShowOptions(!showOptions)}>Options</div>
        </div>
        <div className="optionsBox" style={{display: showOptions ? 'block' : 'none', padding:10}}>
          <TimelineDataTypesComp settings={props.settings} updateSettings={props.updateSettings} />
          <div>
            <input type="checkbox" checked={settings.advancedCursor} onChange={() => toggleSetting("advancedCursor")} />Advanced cursor
          </div>
        </div>
    </div>)
}