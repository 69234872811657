import {ApiData} from "./ApiData";
import {BasalType, BolusData, GraphData} from "./GraphData";
import {PatientData} from "./PatientData";
import moment from "moment";
import {toMoment} from "./DataTools";
import {GraphResources} from "./GraphResources";

export default abstract class GraphRepository {

   readonly resources: GraphResources;
   readonly lastSyncTime: string;
   protected readonly patientData: PatientData;

   protected requestingGraphData?: GraphData;

   private onUpdate: (() => void)[] = [];
   protected errorMessage?: string;
   protected graphData?: GraphData;

   protected constructor(resources: GraphResources,
                         lastSyncTime: string,
                         patientData: PatientData) {
      this.resources = resources;
      this.lastSyncTime = lastSyncTime;
      this.patientData = patientData;
   }

   abstract requestData(from:number, to: number):boolean;

   /*requestPatientData = () => {
      let xmlHttp = new XMLHttpRequest();
      xmlHttp.onreadystatechange = () => {
         if (xmlHttp.readyState == 4 && xmlHttp.status == 200) {
            this.patientDataReceived(JSON.parse(xmlHttp.responseText));
         }
      };
      let url = "patient";

      xmlHttp.open("get", this.apiUrl + url, true);
      xmlHttp.setRequestHeader("Authorization", this.authHeader);
      xmlHttp.send()
   };

   patientDataReceived = (patientData: PatientData) => {
      this.patientData = patientData;

      this.patientData.goal_max = parseFloat((this.patientData.goal_max || "10") as unknown as string);
      this.patientData.goal_min = parseFloat((this.patientData.goal_min || "4") as unknown as string);

      for(let f of this.onUpdate) {
         f()
      }
   };

   getPatientData = () => {
      return this.patientData;
   };

   requestData = (from:number, to: number):boolean => {
      if(this.requestingGraphData) {
         // Already requesting. Do nothing.
         return false;
      } else if(this.graphData) {
         let missingRange = this.graphData.getMissingRange(from, to);
         if(missingRange) {
            this.requestingGraphData = new GraphData(missingRange[0], missingRange[1]);
         }
      } else {
         // No data at all. Request everything:
         this.requestingGraphData = new GraphData(from, to);
      }

      if(this.requestingGraphData) {
         let xmlHttp = new XMLHttpRequest();
         xmlHttp.onreadystatechange = () => {
            if (xmlHttp.readyState == 4) {
               console.log("HTTP", xmlHttp);
               if (xmlHttp.status == 200) {
                  this.dataReceived(JSON.parse(xmlHttp.responseText));
               } else {
                  this.errorMessage = GraphRepository.formatErrorMessage(xmlHttp);
                  this.callOnUpdate();
               }
            }
         };
         let fromString = toMoment(this.requestingGraphData.from).format(GraphRepository.FORMAT);
         let toString = toMoment(this.requestingGraphData.to).format(GraphRepository.FORMAT);
         let url = "patient/data?type=combined&date_from=" + fromString + "&date_to=" + toString + "&unit=mmol_l&ext=alarm,event,tdd,bolus_calc";
         console.log("Request " + fromString + " -> " + toString);

         xmlHttp.open("get", this.apiUrl + url, true);
         xmlHttp.setRequestHeader("Authorization", this.authHeader);
         xmlHttp.send();
         return false;
      } else {
         return true;
      }
   };

   dataReceived = (data: ApiData[]) => {
      let graphData = this.requestingGraphData;
      for(let item of data) {
         //console.log(item);
         let date = moment(item.created_at, GraphRepository.FORMAT).utc(true);
         let timestamp = date.unix();
         switch(item.type) {
            case "glucose":
               // Get hour for AGP graph:
               if(item.value) {
                  let hour = date.hours();
                  if (date.minutes() >= 30) {
                     hour = hour == 23 ? 0 : (hour + 1);
                  }
                  let isCGM = item.flags && item.flags.some(flag => flag.flag == 123);
                  if (isCGM) {
                     graphData.cgm.push([timestamp, item.value, hour]);
                  } else {
                     graphData.glucose.push([timestamp, item.value, hour]);
                  }
               }
               break;
            case "insulin_pen":
               graphData.insulin.push([timestamp, item.value]);
               break;
            case "insulin_basal":
               let basalType = BasalType.Normal;
               if(item.flags) {
                  if(item.flags.some(flag => flag.flag == 1201)) {
                     basalType = BasalType.LowGlucoseSuspend;
                  } else if(item.flags.some(flag => flag.flag == 1200)) {
                     basalType = BasalType.PredictiveLowGlucoseSuspend;
                  } else if(item.flags.some(flag => flag.flag == 1000)) {
                     basalType = BasalType.TempModified;
                  }
               }
               graphData.basal.push([timestamp, item.value, basalType]);
               break;
            case "insulin_bolus":
               let bolus:BolusData = {};
               if(item.duration) {
                  bolus.duration = item.duration / 60;
               } else {
                  bolus.duration = 0;
               }
               if(item.spike_value) {
                  bolus.immediate = item.spike_value;
               }
               if(item.total_value !== item.spike_value) {
                  bolus.extended = item.total_value - (item.spike_value || 0);
               }
               if(item.bolus_calc) {
                  let bolus_calc = item.bolus_calc;
                  if (bolus_calc.bg_target) {
                     bolus.bgTarget = bolus_calc.bg_target.value;
                  }
                  if (bolus_calc.bg_value) {
                     bolus.bgValue = bolus_calc.bg_value.value;
                  }
                  if (bolus_calc.carbs) {
                     bolus.carbs = bolus_calc.carbs.value;
                  }
                  if (bolus_calc.ic_ratio) {
                     bolus.icRatio = bolus_calc.ic_ratio.value;
                  }
                  if (bolus_calc.iob) {
                     bolus.iob = bolus_calc.iob.value;
                  }
                  if (bolus_calc.isf) {
                     bolus.isf = bolus_calc.isf.value;
                  }
                  if (bolus_calc.sugg_corr) {
                     bolus.suggCorr = bolus_calc.sugg_corr.value;
                  }
                  if (bolus_calc.sugg_meal) {
                     bolus.suggMeal = bolus_calc.sugg_meal.value;
                  }
               }
               graphData.bolus.push([timestamp, bolus]);
               break;
            case "carb":
               graphData.carbs.push([timestamp, parseFloat(item.value as unknown as string)]);
               break;
            case "event":
            case "alarm":
               if(GraphRepository.IGNORED_EVENTS.indexOf(item.value) == -1) {
                  graphData.events.push([timestamp, item.value]);
               }
               break;
            case "insulin_tdd":
               graphData.tdds.push([timestamp, item.basal, item.total - item.basal]);
               break;
            default:
               console.log("Unknown item type in ", item);
         }
      }

      if(!this.graphData) {
         graphData.carbs.push([moment("2019-10-11T12:00:00").unix(), 40]);
         graphData.carbs.push([moment("2019-10-11T14:00:00").unix(), 70]);
         graphData.carbs.push([moment("2019-10-12T13:00:00").unix(), 55]);
         graphData.carbs.push([moment("2019-10-12T14:00:00").unix(), 30]);

         graphData.exercise.push([moment("2019-10-14T12:00:00").unix(), 120, 1]);
         graphData.exercise.push([moment("2019-10-14T21:00:00").unix(), 60, 2]);
         graphData.exercise.push([moment("2019-10-15T13:00:00").unix(), 30, 3]);
         graphData.exercise.push([moment("2019-10-15T21:00:00").unix(), 30, 1]);

         this.graphData = graphData;
      } else {
         this.graphData.add(graphData);
      }
      this.requestingGraphData = undefined;
      this.callOnUpdate();
   };
   */

   getPatientData = () => {
      return this.patientData;
   };

   getGraphData = () => {
      return this.graphData;
   };

   protected callOnUpdate() {
      for(let f of this.onUpdate) {
         f()
      }
   }

   getErrorMessage = () => {
      return this.errorMessage;
   };

   clearErrorMessage = () => {
      console.log("clearErrorMessage");
      this.errorMessage = undefined;
      this.requestingGraphData = undefined;
      this.callOnUpdate();
   };

   addOnUpdate(onUpdate: () => void) {
      this.onUpdate.push(onUpdate)
   }

   deleteOnUpdate(onUpdate : () => void) {
      const index = this.onUpdate.indexOf(onUpdate, 0);
      if (index > -1) {
         this.onUpdate.splice(index, 1);
      }
   }

   protected static formatErrorMessage(xmlHttp: XMLHttpRequest) {
      return "Error code " + xmlHttp.status + ": " + xmlHttp.responseText;
   }
}