import React, {ChangeEvent, KeyboardEvent} from "react";
import {OverlaySettings, OverlayType} from "../graphs/OverlaySettings";
import {OverlayGraphController} from "~/TimelineGraphComp";

export interface Props {
  settings:OverlaySettings;
  updateSettings: (settings: OverlaySettings) => void;
  graphController: OverlayGraphController;
}

export const EventOverlayControlsComp = (props: Props) => {
   const settings = props.settings;

  const onIntervalChange = (e: KeyboardEvent<HTMLInputElement> | ChangeEvent<HTMLInputElement>) => {
    let newSettings = structuredClone(settings);
    newSettings[e.currentTarget.name] = e.currentTarget.value;
    props.updateSettings(newSettings);
    props.graphController.setInterval(newSettings.fromDay, newSettings.toDay);
  }

   const onLastDaysClick = (nDays: number) => {
     props.graphController.showLastDays(nDays);
   }

   const onTimeSpanClick = (seconds: number) => {
    props.graphController.showEventOverlay(settings.type, seconds);
   }

   const onCustomClick = () => {
     let newSettings = structuredClone(settings);
     newSettings.lastDays = undefined;
     props.updateSettings(newSettings);
   }

   const setEventType = (eventType: OverlayType) => {
      props.graphController.showEventOverlay(eventType);
   }

   return(
     <div style={{display:"flex", flexGrow: 1}}>
       <div>
           <div className="buttonGroup" style={{marginBottom:10}}>
              <div className={`button ${settings.type == 'hypo' ? "selected" : ""}`} onClick={() => setEventType(OverlayType.Hypo)}>Hypoglycemia</div>
              <div className={`button ${settings.type == 'infusionSetAndSiteChange' ? "selected" : ""}`} onClick={() => setEventType(OverlayType.InfusionSetAndSiteChange)}>Infusion set and site change</div>
              <div className={`button ${settings.type == 'exerciseStart' ? "selected" : ""}`} onClick={() => setEventType(OverlayType.ExerciseStart)}>Exercise start</div>
              <div className={`button ${settings.type == 'breakfast' ? "selected" : ""}`} onClick={() => setEventType(OverlayType.Breakfast)}>Breakfast</div>
              <div className={`button ${settings.type == 'lunch' ? "selected" : ""}`} onClick={() => setEventType(OverlayType.Lunch)}>Lunch</div>
              <div className={`button ${settings.type == 'dinner' ? "selected" : ""}`} onClick={() => setEventType(OverlayType.Dinner)}>Dinner</div>
           </div>
           <div className="buttonGroup" style={{marginBottom:10}}>
              <div className={`button ${settings.lastDays == 30 ? "selected" : ""}`} onClick={() => onLastDaysClick(30)}>Last 30 days</div>
              <div className={`button ${settings.lastDays == 14 ? "selected" : ""}`} onClick={() => onLastDaysClick(14)}>Last 14 days</div>
              <div className={`button ${settings.lastDays == 7 ? "selected" : ""}`} onClick={() => onLastDaysClick(7)}>Last 7 days</div>
              <div className={`button ${!settings.lastDays ? "selected" : ""}`} onClick={() => onCustomClick}>Custom</div>
           </div>
           <div className="buttonGroup" style={{marginBottom:10}}>
              <div className={`button ${settings.timeSpanLength == 2 * 3600 ? "selected" : ""}`} onClick={() => onTimeSpanClick(2 * 3600)}>&plusmn;1 hour</div>
              <div className={`button ${settings.timeSpanLength == 6 * 3600 ? "selected" : ""}`} onClick={() => onTimeSpanClick(6 * 3600)}>&plusmn;3 hours</div>
              <div className={`button ${settings.timeSpanLength == 12 * 3600 ? "selected" : ""}`} onClick={() => onTimeSpanClick(12 * 3600)}>&plusmn;6 hours</div>
              <div className={`button ${settings.timeSpanLength == 24 * 3600 ? "selected" : ""}`} onClick={() => onTimeSpanClick(24 * 3600)}>&plusmn;12 hours</div>
           </div>
         {!settings.lastDays && <div style={{display:"flex", alignItems:"center", marginBottom:10}}>
              <input name="fromDay" value={settings.fromDay} onChange={onIntervalChange} onKeyUp={onIntervalChange} />
              <div style={{margin: "0 10px"}}>-</div>
              <input name="toDay" value={settings.toDay} onChange={onIntervalChange} onKeyUp={onIntervalChange} />
           </div>}
        </div>
     </div>)
}