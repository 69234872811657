import {OverlayInterval} from "../graphs/OverlaySettings";
import {toMoment} from "../graphs/DataTools";
import {OverlaySettings} from "../graphs/OverlaySettings";
import React, {useState} from "react";

interface EventInterval {
   name: string;
   events: OverlayInterval[];
}

interface EventMonth {
   name: string;
   month: number;
   days: EventDay[];
}

interface EventDay {
   weekDay: number;
   weekNumber: number;
   from: number;
   to: number;
   date: number;
   events: OverlayInterval[];
}

const headerH = 20;
const dayH =  35;
const dayW = 43;

export interface Props {
  settings?: OverlaySettings;
  updateSettings?: (settings: OverlaySettings) => void;
}

export const EventOverlayCalendarComp = (props: Props) => {
  const settings = structuredClone(props.settings) as OverlaySettings;
  const [expanded, setExpanded] = useState<boolean>(false);

   const onMouseEnterEvent = (interval: OverlayInterval) => {
      interval.isHighlighted = true;
      props.updateSettings!(settings);
   }

   const onMouseEnterDay = (day: EventDay) => {
     day.events.forEach(e => e.isHighlighted = true);
     props.updateSettings!(settings);
   }

   const onMouseEnterInterval = (interval: EventInterval) => {
      interval.events.forEach(e => e.isHighlighted = true);
      props.updateSettings!(settings);
   }

   const onMouseLeave = () => {
     settings.intervals.forEach((interval:OverlayInterval) => interval.isHighlighted = false);
     props.updateSettings!(settings);
   }

   const getIntervals = ():[EventMonth[], EventInterval[]] => {
    let fromDay = toMoment(settings.from);
    let toDay = toMoment(settings.to);
    let lastWeekDay = -1;
    let currentMonth:EventMonth | undefined = undefined;
    let months: EventMonth[] = [];
    let lastWeekNumber = 0;
    for (let d = fromDay; d < toDay;) {
       if(!currentMonth || currentMonth.month != d.month()) {
          // Create a new month:
          currentMonth = {
             name: d.format("MMMM YYYY"),
             month: d.month(),
             days: []
          };
          lastWeekNumber = 0;
          months.push(currentMonth)
       }

       let tomorrow = d.clone().add(1, "day");
       let weekDay = d.weekday() == 0 ? 6 : d.weekday() - 1;
       if (weekDay < lastWeekDay) {
          lastWeekNumber++;
       }
       let day: EventDay = {
          weekDay: weekDay,
          weekNumber: lastWeekNumber,
          from: d.unix(),
          to: tomorrow.unix(),
          date: d.date(),
          events: []
       };
       // Find all events for this day:
       for(let interval of settings.intervals){
          let mid = (interval.from + interval.to) / 2;
          if(day.from <= mid && mid < day.to) {
             day.events.push(interval)
          }
       }

       currentMonth.days.push(day);
       d = tomorrow;
       lastWeekDay = day.weekDay;
    }

    // Create the intervals:
    let intervals: EventInterval[] = [];
    for(let i = 0; i < 12; i++) {
       let from = i * 2;
       let to = i * 2 + 2;
       intervals.push({
          name: (from < 9 ? '0' : '') + from + "-" + (to < 9 ? '0' : '') + to,
          events: []
       })
    }
    for(let interval of settings.intervals){
       let mid = (interval.from + interval.to) / 2;
       intervals[Math.floor(toMoment(mid).hour() / 2)].events.push(interval);
    }
    return [months, intervals];
   }

    const [months, intervals] = getIntervals();

   return (
    <div className="eventOverlayCalendar">
      <div className="block" style={{display:"flex", paddingBottom: 10, paddingTop: 10}}>
        {intervals.map(interval => <div
                key={interval.name}
                className={`timeInterval ${interval.events.length ? "active" : ""}`}
                onMouseEnter={() => onMouseEnterInterval(interval)}
                onMouseLeave={onMouseLeave}
             >
               <div
                   style={{
                     textAlign:"center",
                     margin:"auto 5px 10px 5px",
                     borderBottom:"1px solid grey",
                     color:"#0690ed",
                     borderBottomWidth: Math.max(interval.events.length * 10, 1),
                     borderBottomColor: interval.events.length ? '#0690ed' : '#b8b8b8'}}
               >{interval.events.length ? interval.events.length : ""}</div>
               <div style={{transform: "rotate(90deg)", whiteSpace: "nowrap", width:24, color:"#b8b8b8"}}>{interval.name}</div>
            </div>)
        }
         </div>

         <div className="block">
            <div style={{margin: "10px auto 0 auto", height:20, position: 'relative', width: dayW * 7}} className="agpDays">
              {["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"].map((item, index) => <div
                    key={item}
                    style={{color:"#b8b8b8", textAlign: "center", position: 'absolute', height: headerH, width: dayW, left: index * dayW}}
              >{item}</div>)
              }
           </div>
           {months.map(month => <div key={month.month}>
               <div style={{margin:10, textAlign: "center"}}>{month.name}</div>
               <div className="agpDays" style={{margin: "auto", position: 'relative', width: dayW * 7, height: dayH * (1 + month.days[month.days.length - 1].weekNumber)}}>

                 {month.days.map(day => <div
                      key={day.to}
                      style={{position: 'absolute', top: day.weekNumber * dayH, left: day.weekDay * dayW, height: dayH, width: dayW}}
                      onMouseEnter={() => onMouseEnterDay(day)}
                      onMouseLeave={onMouseLeave}
                      >
                      <div className={`day ${day.events.length ? "active" : ""}`}>
                           <div style={{margin:"auto"}}>{day.date}</div>
                      </div>
                  </div>)}
               </div>
           </div>)}
        </div>

        <div className="block">
         <div className="header expandable" onClick={() => setExpanded(!expanded)}>
             <b>Events</b>
             <i className={`expandButton material-icons ${expanded ? "expanded" : ""}`}>keyboard_arrow_down</i>
         </div>
         <div className="statisticsBody" style={{maxHeight: expanded ? (30 * settings!.intervals.length) + 'px' : 0}} >
           {settings!.intervals.map(interval => <div
                key={interval.from}
                className="eventOverlayCalendarContainer"
                onMouseEnter={() => onMouseEnterEvent(interval)}
                onMouseLeave={onMouseLeave}
                >
                <div className="eventOverlayCalendarItem">
                   <div>{interval.dateAsString}</div>
                   <div style={{marginLeft:"auto", marginRight: 5, width:12, height:12, borderRadius:10, background:interval.isHighlighted ? '#0690ed' : 'transparent'}}></div>
                </div>
            </div>)}
         </div>
      </div>
    </div>)
}