import GraphComponent from "./GraphComponent";

export default class GraphAxis extends GraphComponent {
   min: number = 0;
   max: number = 1;

   constructor(max: number, weight: number) {
      super(1, weight);
      this.max = max;
   }

   getY = (y: number) => {
      return this.top + this.height - (y - this.min) / (this.max - this.min) * this.height;
   };
}