import React, {useEffect, useState} from 'react';
import '../index.less'
import {TimelineView} from "./TimelineView";
import {getUnitFromString} from "../graphs/UnitTools";
import GlookoGraphRepository from "../graphs/GlookoGraphRepository";
import {createRoot} from "react-dom/client";
import {GraphResources} from "../graphs/GraphResources";
import Icon from "../graphs/Icon";
import {OverlayView} from "./OverlayView";
import {EventsView} from "./EventsView";
import {GlucoseUnit} from "../graphs/GraphSettings";
import {ErrorMessageComp} from "./ErrorMessageComp";
import {DailyLogView} from "./DailyLogView";
import {PatientData} from "../graphs/PatientData";

let formatApiUrl = (url:string) => {
  return url.endsWith("/") ? url : (url + "/")
};

let resources:GraphResources = {
  events: {
    100020: {text: "Cartridge empty"},
    100022: {text: "Cartridge low"},
    100023: {text: "Cartridge loaded"},
    100102: {text: "Delivery started"},
    100121: {text: "Delivery suspended", icon: Icon.SUSPEND},
    500003: {text: "Delivery resumed"},
    500008: {text: "Basal program edited"},
    500009: {text: "Pump settings edited"},
    500011: {text: "Reminder settings edited"},
    200301: {text: "Patch Expired"},
    500002: {text: "Delivery suspended", icon: Icon.SUSPEND},
    500012: {text: "Pump activated"},
    500013: {text: "Pump deactivated"},
    500021: {text: "Infusion set and site changed", icon: Icon.SITE_AND_SET_CHANGED},
    510001: {text: "Reservoir changed"},
  },
  translations: {
  }
};
const STORAGE_TAB_KEY = "tab"
const DAILY_LOG = "dailyLog";
const TIMELINE = "timeline";
const OVERLAY = "overlay";
const EVENTS = "events";

const Application = () => {

  const [repo, setRepo] = useState<GlookoGraphRepository | undefined>(undefined);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [selectedTab, setSelectedTab] = useState<string>(localStorage.getItem(STORAGE_TAB_KEY) || TIMELINE);
  const [showSettingsTab, setShowSettingsTab] = useState<boolean>(false);

  let initiate = (apiUrl: string, glookoCode: string, csrfToken?:string) => {
    let headers:any = {};
    if(csrfToken) {
      headers["X-CSRF-Token"] = csrfToken;
    }
    fetch(apiUrl + "v3/session/users?patient=" + glookoCode, {
      headers: headers,
      credentials: 'include'
    }).then(response => response.json())
      .then(response => {
        let lastSyncTime = response.currentPatient.lastSyncTimestamps.lastSyncTimestamp; //"2020-11-01T11:45:22";
        let unit = getUnitFromString(response.currentPatient.preference.meterUnits)!;
        let patientData: PatientData = {
          severe_high_limit: 13.9,
          goal_max: response.currentPatient.preference.afterMealNormalGlucoseMax / 1801.6,
          goal_min: response.currentPatient.preference.normalGlucoseMin / 1801.6,
          severe_low_limit: unit == GlucoseUnit.MG ? (54 / 18.016) : 3.1,
          unit: unit,
        };

        console.log(response.currentPatient.preference.meterUnits + "->" + patientData.unit)
        console.log(patientData)

        const repo = new GlookoGraphRepository(formatApiUrl(apiUrl), glookoCode, resources, lastSyncTime, patientData, csrfToken);
        repo.addOnUpdate(() => {
          setErrorMessage(repo.getErrorMessage());
        });

        setRepo(repo);
    });
  }

  let initiateWithCredentials = (apiUrl: string, login: string, password: string) => {
    console.log("glooko", apiUrl, login, password);

    let postBody= {
      "userLogin": {
        "email": login,
        "password": password
      },
      "deviceInformation":{
        "applicationType": "logbook",
        "os": "android",
        "osVersion": "4.4.2",
        "applicationVersion": "2.1.0",
        "buildNumber": 2889,
        "gitHash": "ab48de20",
        "device": "samsung s5",
        "deviceId": "device UUID",
        "deviceManufacturer": "samsung",
        "deviceModel": "SM-G900F",
        "serialNumber": "129ycg46v737"
      }
    }
    fetch(apiUrl + "v2/users/sign_in", {
      method: 'POST',
      body: JSON.stringify(postBody),
      headers: {
       "Content-Type": "application/json; charset=utf-8"
      }
    }).then(response => response.json()).then(response => {
      console.log("Got patient data", response);
      let glookoCode = response.userLogin.glookoCode
      initiate(apiUrl, glookoCode)
    })
  };


  useEffect(() => {
    let url = new URL(window.location.href);
    let apiUrl = url.searchParams.get("apiUrl");
    if(apiUrl) {
      console.log("Initiate with ", apiUrl);
      let glookoCode = url.searchParams.get("glookoCode")!;
      let csrfToken = url.searchParams.get("csrfToken") || undefined;
      // Hide the settings tab:
      setShowSettingsTab(false);

      initiate(apiUrl + "/api/", glookoCode, csrfToken)
    } else {
      //initiateWithCredentials("/glookoElmo/api/", "elmo.20220309@example.com", "tester1!");
      initiateWithCredentials("/glookoProdEu/api/", "anja.hase+eutest@glooko.com ", "Tester1!");
    }
  },[]);

  const clearErrorMessage = () => {
    setErrorMessage(undefined);
    repo?.clearErrorMessage();
  }

  const onTabClicked = (tab: string) => {
    setSelectedTab(tab);
    localStorage.setItem(STORAGE_TAB_KEY, tab);
  }

  const print = () => {
    window.document.getElementById("root").classList.add("print");
    window.dispatchEvent(new Event('resize'));
    setTimeout(window.print, 500);
  }

  return (
    <div style={{height:"100%", display:"flex", flexDirection:"column"}}>
      <div style={{
        background: "#ff9520",
        fontSize: 13,
        lineHeight: "18px",
        textAlign: "center",
        padding: 10,
        color: "#FFF",
      }}>
        This is a <b>BETA</b> version which may include bugs. <b>DO NOT MAKE CLINICAL DECISIONS
        BASED ON INFORMATION IN THE BETA VERSION.</b><br/>
        Using the <b>BETA</b> version is done at the user’s own responsibility without any liability
        for Glooko.
      </div>
      <div className="tabContainer">
        <div className={`headerButton tabButton ${selectedTab == DAILY_LOG ? "selected" : ""}`} onClick={() => onTabClicked(DAILY_LOG)}>Daily Log</div>
        <div className={`headerButton tabButton ${selectedTab == TIMELINE ? "selected" : ""}`} onClick={() => onTabClicked(TIMELINE)}>Timeline</div>
        <div className={`headerButton tabButton ${selectedTab == OVERLAY ? "selected" : ""}`} onClick={() => onTabClicked(OVERLAY)}>Overlay</div>
        <div className={`headerButton tabButton ${selectedTab == EVENTS ? "selected" : ""}`} onClick={() => onTabClicked(EVENTS)} style={{marginRight: "auto"}}>Events</div>
        {/*<div className="headerButton" id="helpButton"><i className="material-icons">help</i></div>*/}
        {showSettingsTab && <div className="headerButton tabButton" id="settingsTabButton"><i className="material-icons">settings</i></div>}
      </div>
      {errorMessage && <ErrorMessageComp errorMessage={errorMessage} clearErrorMessage={clearErrorMessage}/>}
      {/*{<div id="helpModal">Help modal</div>*/}
      {selectedTab == DAILY_LOG && repo && <DailyLogView repo={repo} />}
      {selectedTab == TIMELINE && repo && <TimelineView repo={repo} />}
      {selectedTab == OVERLAY && repo && <OverlayView repo={repo} />}
      {selectedTab == EVENTS && repo && <EventsView repo={repo} />}
    </div>
  )
};

createRoot(document.getElementById('root') as Element).render(<Application/>)