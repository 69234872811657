import {getRange} from "./DataTools";
import {BasalType, GraphData} from "./GraphData";
import {BasalStatistics, PumpModeStatisticsItem} from "./TimeRangeStatistics";
import {getPumpModeDescription} from "./PumpModeDescription";

export class PumpModeStatisticsGenerator {

   private graphData: GraphData;
   private total: number = 0;
   private statistics: { [type: string]: PumpModeStatisticsItem } = {};

   constructor(graphData: GraphData) {
      this.graphData = graphData;
   }

   addTimeRange(from: number, to:number) {
      this.total += (to - from);

      let [minI, maxI] = getRange(this.graphData.pumpModes, from - 1, to + 1);
      for (let i = minI; i <= maxI; i++) {
         const [start, duration, pumpMode] = this.graphData.pumpModes[i];
         if(!this.statistics[pumpMode]) {
            this.statistics[pumpMode] = {
               percentage: 0,
               description: getPumpModeDescription(pumpMode),
               pumpMode: pumpMode,
               totalTime: 0
            }
         }
         this.statistics[pumpMode].totalTime += Math.min(to, start + duration) - Math.max(from, start);
      }
   }

   getStatistics(): PumpModeStatisticsItem[][] {
      const items = Object.values(this.statistics) as PumpModeStatisticsItem[]
      const itemsPerType: PumpModeStatisticsItem[][] = [[], []];
      items.forEach(item => {
         item.percentage = 100 * item.totalTime / this.total;
         const desc = item.description;
         const type = (desc.rowNum == 1 || desc.rowHeight == 2) ? 0 : 1;
         itemsPerType[type].push(item);
      });
      items.sort((a, b) => a.pumpMode.localeCompare(b.pumpMode));
      return itemsPerType.filter(items =>items.length);
   }
}