let Icon = (() => {

   let ICONS: {[key: string]: string;} = {
      SITE_AND_SET_CHANGED: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAEKADAAQAAAABAAAAEAAAAAA0VXHyAAABs0lEQVQ4Eb2TPUsDQRCG704jIogixkZMKVrYxiadrZAqCAZBREGCQhDygZZaJAYttJEI2mgVbez8AZGAP8AvUmhUzkKCHATC5cNnjpzkMNeJA3O7OzPvu+/O7inKf1goFOpy20d1SWjxeDysquoy7qemF39rNpvX+C72YON+ESQSiQGSlwBn7CLGGt4tawiq5CKpVOpE1pp8bGtJtcAUvuJhwzCGKfY0Go0J6o7wHvw4FovNCs6hIJlMLhI7xUv1et2fyWR05g5D4ToKDgiWdF0fdygguCrV7BbtBJZcOp0+RNkN0zGv1xtUYfTBeEegTwrEKDAYvhinadi7FWz70OA1TdOE6FCD8YXJdltegbAf3+8EbtVZpNQMWkcol8t7JO7bSG6LxaKc0818kmDjD4sgm82anDvSCpokVnK5XN0FrSJ/XnIoKDhugX6cE3zm2jZdwArn34Jgh02KKJ+0HoddXKlUoty7NFCBLEjRKNMr0zQ/PR7PFMAN1nN4TVSKcocCEpYBltf4iJqRVuhnAGjgSzT4QoIdf5J8Pl8NBALy3uVqhyCSfjwBPMMXABdY/419A3n4xfTjc05IAAAAAElFTkSuQmCC",
      SUSPEND: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACYAAAAmCAYAAACoPemuAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAJqADAAQAAAABAAAAJgAAAACE8iBbAAADHklEQVRYCe1YPWgUQRR+b3bXH8TKTvHn5PSCqSIIWoggKFhoEjVNsLQRK60trrDW0sZSbKImaiEoCGKhIJhKyemR8we1shR/7nbG/VYmLOubudmLRIU7yM3Mm+9779uZtzPvwjTg582xXWMp6wmlzH5jaJNi2ghX2tBHZvqgNT+OjJrbdufl/CAhuCrp7fH6yTSNLjKbRgjXGG5FUXph6632jRC8xQQLW5hq1Fb39HUyvNeSK7Vsnn6P1fTITKsTwgsS9n6yfiA10U1DZkOIUxeGiT9HnJ7YPNt+5MJYe19hENWl6AEbk1jSclrD3E0oPdRPnFcYtm9Nl54td6XKD4KV+5bQHt+2qjKpOEZO+URlL8ALrficSpLRdUmyHn/ow4a5oq9iHz7zfC0aS33niuHt01rNlPD5MNuOH6TT87WxU1e42dQiptlUnflrZ0hFl7I0WCVhlNJTrrfVKWxxvLEgHQkQpXt0pH639VAKVra1jzYOqpjuSeJwlGy/3RopczAWtxKHpyQqd5CtVKgo4HNsxsm5pS/EQKySOR+KwnCiS2DkDbZPmvPZ8i135JwrligM14wUKGV11ZVTEt7awAHXjoutK5YoDHdfkWz7cRTft/2qrYvriiUKsxdyOfhaondlW+jYxXXFEoWFBvsTOK3JSH5EYShdJPBXoi2SPcTm5DJ9kviiMNRTEriX9g5L9hCbi+uKJQpDkScFi4w+bbITXZrz2cABV8K4YolBUHlKTrKTejS/ZqRJjw0ccCWIK9b/dSXhyVAOS0+IOw933+J4/axvWzEHjOue9MXAnHPFMNmZ3PHEV0rjisKJjsPTnlN4+5DoeT46tg++KSu1a7Ov9+V94SsWbEsm1Oi+QhF5o4y5rHWXviyxflUGxvPMeaEY83SB8lvXu2JA/63SWnwri/JRm6NGx1MW7YP04SOk3ofvvsIAgjjU6MgLjAf6ZFz46PcjxPruu5UWaNt/7gevFWZbVJ5G9Z7bsdSyjnev2L8IigI6EzvFysBianOvKu+I5QblmAWvZDsUVnW1hys2XLGqK1AVP8yxqiv2Ew0dXD88O6+MAAAAAElFTkSuQmCC"
   };
   let images: {[key: string]: HTMLImageElement;} = {};

   let getImage = (icon: string) => {
      return images[icon];
   };

   for(let icon in ICONS) {
      let image = new Image();
      image.src = ICONS[icon];
      images[ICONS[icon]] = image;
   }

   return {
      getImage: getImage,
      ...ICONS
   };
})();

export default Icon;