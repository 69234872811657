import React from "react";
import {BasalStatistics, InsulinStatistics} from "../graphs/TimeRangeStatistics";
import {InsulinChartComp} from "./InsulinChartComp";

export interface Props {
   insulinStatistics: InsulinStatistics;
   basalStatistics: BasalStatistics;
}

export const InsulinStatisticsComp = (props: Props) => {
   const insulinStatistics = props.insulinStatistics;
   const basalStatistics = props.basalStatistics;
   return(
     <div className="block">
           <div className="header"><b>Insulin</b></div>
           <div style={{display:"flex", marginTop:10}}>
               <div style={{flexBasis: 0, flexGrow:1}}>
                   <div className="valueHeader">Average daily Basal</div>
                   <div>
                       <span className="value">{insulinStatistics.averageDailyBasal.toFixed(1)}</span>
                       <span>units</span>
                   </div>
               </div>
               <div style={{flexBasis: 0, flexGrow:1}}>
                   <div className="valueHeader">Average daily Bolus</div>
                   <div>
                       <span className="value">{insulinStatistics.averageDailyBolus.toFixed(1)}</span>
                       <span>units</span>
                   </div>
               </div>
           </div>
           <div style={{display:"flex", marginTop:20}}>
               <div style={{flexBasis: 0, flexGrow:1, flexShrink:1, width:"50%"}}>
                  <InsulinChartComp style={{width:"100%", height:106}} insulinStatistics={insulinStatistics} />
               </div>
               <div style={{flexBasis: 0, flexGrow:1}}>
                   <div className="valueHeader">Total</div>
                   <div style={{marginBottom:8}}>
                       <span className="value">{insulinStatistics.total.toFixed(1)}</span>
                       <span>units</span>
                   </div>
                  {insulinStatistics.total && <div className="legendItem">
                       <span className="colorMarker" style={{backgroundColor: insulinStatistics.basalColor}}></span>
                       {insulinStatistics.totalBasalPercentage.toFixed(1)}% Basal
                   </div>}
                  {insulinStatistics.total && <div className="legendItem">
                       <span className="colorMarker"  style={{backgroundColor: insulinStatistics.bolusColor}}></span>
                       {insulinStatistics.totalBolusPercentage.toFixed(1)}% Bolus
                   </div>}
                  {insulinStatistics.nMissingDays > 0 && <div style={{marginTop:8, fontWeight:"bold", color:"#a50000"}}>
                    {insulinStatistics.nMissingDays}
                      {insulinStatistics.nMissingDays == 1 && <span> missing day</span>}
                      {insulinStatistics.nMissingDays > 1 && <span> missing days</span>}
                   </div>}
               </div>
           </div>
           <div className="basalTable">
               <div className="tableRow">
                   <div className="tableHeader">Temp. modified:</div>
                   <div className="tableValue">{basalStatistics.percentageTemporaryModified.toFixed(1)} %</div>
               </div>
              {basalStatistics.percentagePredictiveLowGlucoseSuspend > 0 && <div className="tableRow">
                   <div className="tableHeader">Predicitive low glucose suspend:</div>
                   <div className="tableValue">{basalStatistics.percentagePredictiveLowGlucoseSuspend.toFixed(1)} %</div>
               </div>}
              {basalStatistics.percentageLowGlucoseSuspend > 0 && <div className="tableRow">
                   <div className="tableHeader">Low glucose suspend:</div>
                   <div className="tableValue">{basalStatistics.percentageLowGlucoseSuspend.toFixed(1)} %</div>
               </div>}
            </div>
       </div>
   )
}