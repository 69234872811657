import {getRange} from "./DataTools";
import {GraphData} from "./GraphData";
import Style from "./Style";
import {InsulinStatistics} from "./TimeRangeStatistics";

export class InsulinStatisticsGenerator {

   private graphData: GraphData;
   private totalBasal: number = 0;
   private totalBolus: number = 0;
   private nDays: number = 0;
   private nMissingDays: number = 0;

   constructor(graphData: GraphData) {
      this.graphData = graphData;
   }

   addTimeRange(from: number, to:number) {
      let nDays = Math.round((to - from) / (24 * 3600.0));

      let [minI, maxI] = getRange(this.graphData.tdds, from, to);
      let nTdds = 0;
      for (let i = minI; i <= maxI; i++) {
         let tdd = this.graphData.tdds[i];
         this.totalBasal += tdd[1];
         this.totalBolus += tdd[2];
         nTdds++;
      }
      this.nDays += nTdds;
      this.nMissingDays += (nDays - nTdds);
   }

   getStatistics() {
      let total = this.totalBasal + this.totalBolus;
      if(total) {
         const insulinStatistics : InsulinStatistics = {
            total: total,
            totalBasalPercentage: 100 * this.totalBasal / total,
            totalBolusPercentage: 100 * this.totalBolus / total,
            averageDailyBasal: this.nDays ? (this.totalBasal / this.nDays) : 0,
            averageDailyBolus: this.nDays ? (this.totalBolus / this.nDays) : 0,
            nMissingDays: this.nMissingDays,

            basalColor: Style.BASAL_STROKE,
            bolusColor: Style.BOLUS_STROKE
         }
         return insulinStatistics;
      } else {
         return undefined;
      }
   }
}