import React, {useState} from "react";
import {TimeRangeStatistics} from "../graphs/TimeRangeStatistics";
import {GlucoseStatisticsComp} from "./GlucoseStatisticsComp";
import {InsulinStatisticsComp} from "./InsulinStatisticsComp";
import {EventsStatisticsComp} from "./EventsStatisticsComp";
import {TimelineGraphController} from "./GraphController";
import {PumpModeStatisticsComp} from "./PumpModeStatisticsComp";

export interface Props {
  statistics?: TimeRangeStatistics;
  graphController: TimelineGraphController;
}

export const StatisticsComp = (props: Props) => {
   const [expanded, setExpanded] = useState<boolean>(true);
   const [carbsExpanded, setCarbsExpanded] = useState<boolean>(false);
   const [exerciseExpanded, setExerciseExpanded] = useState<boolean>(false);
   const statistics = props.statistics;

  const onToggleExpanded = () => {
     setExpanded(!expanded);
     setTimeout(props.graphController.scheduleRepaint, 250);
   }

  return(
     <div className={`box statistics ${!expanded ? "collapsed" : ""}`} >
        <div className="collapseButton" onClick={onToggleExpanded}><i className="material-icons">keyboard_arrow_right</i></div>
        <div className="summary">Summary</div>
        <div className="clipContainer">
          {statistics && <div style={{overflow:"auto", top:0, bottom:0, left:0, width:320, position:"absolute"}}>
               <div style={{position:"absolute", right:0, left:0}} className={`${statistics.inactive ? "inactive" : ""}`}>
                   <div style={{color:"#696969", margin:15, textAlign:"right", fontSize:14, minHeight: 17}}>
                       {statistics.timeRange}
                   </div>
                 {statistics.cgm && <GlucoseStatisticsComp statistics={statistics.cgm}/>}

                 {statistics.insulin && <InsulinStatisticsComp insulinStatistics={statistics.insulin} basalStatistics={statistics.basal} />}

                 {statistics.pumpMode.length > 0 && <PumpModeStatisticsComp statistics={statistics.pumpMode} />}

                 {statistics.carbsTotal > 0 && <div className="block" >
                       <div className="header expandable" onClick={() => setCarbsExpanded(!carbsExpanded)}>
                           <b>Carbs</b>
                          <i className={`expandButton material-icons ${carbsExpanded ? "expanded" : ""}`}>keyboard_arrow_down</i>
                       </div>
                       <div className="statisticsBody" style={{maxHeight: carbsExpanded ? 50 : 0, display:"flex", flexDirection:"row"}}>
                           <div style={{flexBasis:0, flexGrow:1}}>
                               <div className="valueHeader">Total carbs</div>
                               <span className="value">{statistics.carbsTotal.toFixed(1)}</span>
                               <span>gram</span>
                           </div>
                           <div style={{flexBasis:0, flexGrow:1}}>
                               <div className="valueHeader">Average daily carbs</div>
                               <span className="value">{statistics.carbsAverageDaily.toFixed(1)}</span>
                               <span>gram</span>
                           </div>
                       </div>
                   </div>}

                 {statistics.exerciseTotal > 0 && <div className="block" >
                       <div className="header expandable" onClick={() => setExerciseExpanded(!exerciseExpanded)}>
                          <b>Exercise</b>
                          <i className={`expandButton material-icons ${exerciseExpanded ? "expanded" : ""}`}>keyboard_arrow_down</i>
                       </div>
                       <div className="statisticsBody" style={{maxHeight: (exerciseExpanded ? 50 : 0), display:"flex", flexDirection:"row"}}>
                           <div style={{flexBasis:0, flexGrow:1}}>
                               <div className="valueHeader">Total exercise</div>
                               <span className="value">{"Math.floor(statistics.exerciseTotal / 60)"}</span>
                               <span>h</span>
                               <span className="value">{"Math.round(statistics.exerciseTotal % 60)"}</span>
                               <span>min</span>
                           </div>
                           <div style={{flexBasis:0, flexGrow:1}}>
                               <div className="valueHeader">Average daily exercise</div>
                               <span className="value">{"Math.floor(statistics.exerciseAverageDaily / 60)"}</span>
                               <span>h</span>
                               <span className="value">{"Math.round(statistics.exerciseAverageDaily % 60)"}</span>
                               <span>min</span>                               
                           </div>
                           <div>
                           </div>
                       </div>
                   </div>}
                  {statistics.events && <EventsStatisticsComp eventStatisticsItems={statistics.events}
                                                              setHighlightedEventId={props.graphController.setHighlightedEventId} />}
               </div>
           </div>}
        </div>
    </div>
)};