import {GlucoseUnit} from "./GraphSettings";

export function getGlucoseValue(unit: GlucoseUnit, glucoseInMmol: number) {
   return unit == GlucoseUnit.MG ? (glucoseInMmol * 18.016) : glucoseInMmol;
}

export function getGlucoseString(unit: GlucoseUnit, glucoseInMmol: number) {
   return getGlucoseValue(unit, glucoseInMmol).toFixed(unit == GlucoseUnit.MMOL ? 1 : 0) + " " + unit;
}

export function getGlucoseIntervalString(unit: GlucoseUnit, glucoseInMmol1: number, glucoseInMmol2: number) {
   const nDigits = unit == GlucoseUnit.MMOL ? 1 : 0
   return getGlucoseValue(unit, glucoseInMmol1).toFixed(nDigits) + "-" + getGlucoseValue(unit, glucoseInMmol2).toFixed(nDigits) + " " + unit;
}

export function getUnitFromString(string: string) {
   if(string) {
      switch (string.toLowerCase()) {
         case "mmol":
         case "mmoll":
         case "mmol/l":
            return GlucoseUnit.MMOL;
         case "mg":
         case "mg/dl":
         case "mgdl":
            return GlucoseUnit.MG;
      }
   }
   return undefined;
}

