import GlookoGraphRepository from "../graphs/GlookoGraphRepository";
import {TimeRangeStatistics} from "../graphs/TimeRangeStatistics";
import React, {useEffect, useRef, useState} from "react";
import OverlayGraph from "../graphs/OverlayGraph";
import {OverlaySettings, OverlayType} from "../graphs/OverlaySettings";
import {OverlayGraphController} from "~/GraphController";

export interface Props {
  style?: any;
  graphController: OverlayGraphController;
  repo: GlookoGraphRepository;
  setSettings: (settings: OverlaySettings) => void;
  setStatistics: (statistics: TimeRangeStatistics) => void;
  initialOverlayType?: OverlayType;
}

export const OverlayGraphComp = (props: Props) =>{
  const canvas = useRef(null);
  const [overlayGraph, setOverlayGraph] = useState<OverlayGraph | undefined>(undefined)

  const initiate = () => {
    if(!overlayGraph && canvas.current) {
      const olg = new OverlayGraph(canvas.current, props.repo, props.setStatistics, props.setSettings);
      olg.showLastDays(7);

      props.graphController.graph = olg;
      if(props.initialOverlayType) {
        olg.showEventOverlay(props.initialOverlayType, 6 * 3600);
      }
      olg.scheduleRepaint();
      window.addEventListener("resize", olg.scheduleRepaint);
      setOverlayGraph(olg);
    }
  }

  useEffect(initiate, [canvas, props.repo]);

  const unmount = () => {
    overlayGraph?.destroyGraph();
    setOverlayGraph(undefined);
  }

  useEffect( () => unmount, [] );

  return <canvas style={props.style} ref={canvas} ></canvas>
}
