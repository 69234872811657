import React from "react";
import {GlucoseStatistics} from "../graphs/TimeRangeStatistics";
import {TimeInRangeBarComp} from "./TimeInRangeBarComp";
import {GlucoseUnit} from "../graphs/GraphSettings";

export interface Props {
  statistics: GlucoseStatistics;
}

export const GlucoseStatisticsComp = (props: Props) => {
   const statistics = props.statistics;
   const nDigits = statistics.unit == GlucoseUnit.MMOL ? 1 : 0;
   return(
     <div className="block">
        <div className="header"><b>Glucose</b> (CGM)</div>
        <div style={{display:"flex"}}>
            <div style={{flexBasis: 0, flexGrow:1}}>
                <div className="valueHeader">Average glucose</div>
                <div>
                    <span className="value">{statistics.average.toFixed(nDigits)}</span>
                    <span>{statistics.unit}</span>
                </div>
            </div>
            <div style={{flexBasis:0, flexGrow:1}}>
                <div className="valueHeader">Standard deviation</div>
                <div>
                    <span className="value">{statistics.standardDeviation.toFixed(nDigits)}</span>
                    <span>{statistics.unit}</span>
                    <span style={{fontWeight:"bold", fontSize:12, marginLeft:2}}>({(100 * statistics.standardDeviation / statistics.average).toFixed(1)}%)</span>
                </div>
            </div>
        </div>
        <div style={{display:"flex", marginTop:20}}>
            <TimeInRangeBarComp style={{marginRight:15, width: 40}} statistics={statistics} />
            <div style={{flexBasis: 0, flexGrow:1}}>
               { statistics.intervals.map(interval =>
                  <div className="legendItem" key={interval.description}>
                      <span className="colorMarker" style={{background: interval.color}}></span>
                      <span style={{minWidth:35, marginRight:5}}>{interval.percentage.toFixed(1)}%</span>
                      <span style={{marginRight:5}}>{interval.description}</span>
                      <span style={{fontSize:9, color:"#696969"}}>({interval.range})</span>
                  </div>)}
           </div>
        </div>
        <div style={{marginTop:10}}>Time CGM is active: <b>{statistics.cgmCoveragePercentage.toFixed(1)}%</b></div>
    </div>)
}