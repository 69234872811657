import React, {useState} from "react";
import {EventStatisticsItem} from "../graphs/TimeRangeStatistics";

export interface Props {
  eventStatisticsItems: EventStatisticsItem[];
  setHighlightedEventId?: (eventId?:number) => void;
}

export const EventsStatisticsComp = (props: Props) => {
   const eventStatisticsItems = props.eventStatisticsItems;
   const [expanded, setExpanded] = useState<boolean>(false)

   const onMouseEnter = (event: EventStatisticsItem) => {
     props.setHighlightedEventId!(event.id);
   };

   const onMouseLeave = () => {
     props.setHighlightedEventId!(undefined);
   };

  return(
    <div className="block">
        <div className="header expandable" onClick={() => setExpanded(!expanded)}>
            <b>Events</b>
           <i className={`expandButton material-icons ${expanded ? "expanded" : ""}`}>keyboard_arrow_down</i>
        </div>
        <div className="statisticsBody" style={{maxHeight: expanded ? 200 : 0}}>
           {eventStatisticsItems.map(event =>
             <div className="eventStatisticsRow"
                  key={event.id}
                  onMouseEnter={() => onMouseEnter(event)}
                  onMouseLeave={onMouseLeave}>
               <div style={{width:20}}>
                   <img style={{width:16, height:16}} src={event.icon}/>
               </div>
               <div style={{width:20}}>{event.count}</div>
               <div>{event.name}</div>
             </div>)
           }
        </div>
   </div>)
}