import GraphRepository from "./GraphRepository";
import moment, {Moment} from 'moment';
import {getRange, toMoment} from "./DataTools";
import {TimeRangeStatistics} from "./TimeRangeStatistics";
import Style from "./Style";
import {StatisticsGenerator} from "./StatisticsGenerator";
import AbstractGraph from "./AbstractGraph";
import {TimelineSettings} from "./TimelineSettings";

export default class MiniatureGraph extends AbstractGraph<TimelineSettings> {

   // Callbacks:
   readonly onStatisticsChange: (statistics: TimeRangeStatistics) => void;

   constructor(canvas: HTMLCanvasElement,
               graphRepo: GraphRepository,
               settings:TimelineSettings,
               onStatisticsChange: (statistics: TimeRangeStatistics) => void) {
      super(canvas, graphRepo, settings);

      this.onStatisticsChange = onStatisticsChange;

      let globToDate:Moment = moment(this.graphRepo.lastSyncTime).utc(true);
      let globFromDate = globToDate.clone().startOf("day").subtract(90, "days");
      this.area.initiate(globFromDate.unix(), globToDate.unix());
   }

   showDays = (nDays: number) => {
      this.area.showDays(nDays);
      this.scheduleRepaint();
   };

   showRange = (from: number, to: number, animate: boolean = true) => {
      this.area.showRange(from, to, animate);
      this.scheduleRepaint();
   }

   showLastDays = (nDays: number, animate: boolean = true) => {
      this.area.showLastDays(nDays, animate);
      this.scheduleRepaint();
   };

   private requestData = () => {
      if(this.area.isSet() && this.graphRepo) {
         let [statMinT, statMaxT] = this.getStatisticsRange();
         let minT = Math.min(statMinT.unix(), this.area.getMinT(false));
         let maxT = Math.max(statMaxT.unix(), this.area.getMaxT(false));
         this.graphRepo.requestData(minT, maxT);
      }
   };

   protected repaint = () => {
      let h = this.canvas.height;
      let w = this.canvas.width;
      let minT = this.area.getMinT();
      let maxT = this.area.getMaxT();
      let ctx = this.ctx;
      let dpr = this.dpr;
      ctx.textAlign = undefined;
      this.setFont(12);

      // Place axises:
      this.glucoseAxis.setPos(0, h);
      this.area.setSize(0, this.canvas.width);

      this.requestData();

      if(!this.data) {
         return;
      }
      let patientData = this.patientData;
      let area = this.area;
      area.freeze();

      ctx.strokeStyle = Style.AXIS_HORIZONTAL;
      this.drawLine(0, this.glucoseAxis.top - 1, w, this.glucoseAxis.top - 1);
      this.drawLine(0, this.glucoseAxis.getBottom(), w, this.glucoseAxis.getBottom());

      if(this.data.cgm && this.settings.showCGM) {
         this.drawGlucose(this.data.cgm, Style.CGM_SIZE * dpr);
      }
      if(this.data.glucose && this.settings.showGlucose) {
         this.drawGlucose(this.data.glucose, Style.GLUCOSE_SIZE * dpr);
      }

      // Draw min/max:
      let minY = this.glucoseAxis.getY(this.getGlucoseValue(patientData.goal_min));
      let maxY = this.glucoseAxis.getY(this.getGlucoseValue(patientData.goal_max));
      ctx.strokeStyle = Style.GLOCOSE_TARGET_LINE;
      ctx.setLineDash(Style.GLUCOSE_TARGET_DASH.map(l => l * dpr));
      this.drawLine(0, minY, w, minY);
      this.drawLine(0, maxY, w, maxY);
      ctx.setLineDash([]);

      if(area.isAnimating()) {
         requestAnimationFrame(this.doRepaint)
      } else {
         this.updateStatistics()
      }
   };

   private drawLine = (x1: number, y1: number, x2: number, y2: number) => {
      this.ctx.beginPath();
      this.ctx.moveTo(x1, y1);
      this.ctx.lineTo(x2, y2);
      this.ctx.stroke();
      this.ctx.closePath();
   };

   private drawGlucose = (dataList : any, size: number) => {
      let minT = this.area.getMinT();
      let maxT = this.area.getMaxT();
      let [minI, maxI] = getRange(dataList, minT, maxT);
      for(let i = minI; i <= maxI; i++) {
         let glucose = dataList[i];
         let x = this.area.getX(glucose[0]);
         this.drawGlucosePoint(glucose, x, size);
      }
   };

   lastStatisticsRange?:string = undefined;
   statisticsTimeout:any = undefined;
   statistics?: TimeRangeStatistics = undefined;
   updateStatistics = (delay?:number) => {
      let [minT, maxT] = this.getStatisticsRange();
      if(!isNaN(minT as any)) {
         let statisticsRange = "" + minT + ":" + maxT;
         if (this.lastStatisticsRange != statisticsRange) {
            if (this.statistics && !this.statistics.inactive) {
               // Clear shown statistics:
               this.statistics = {...this.statistics, inactive: true};
               if(this.onStatisticsChange) {
                  this.onStatisticsChange(this.statistics)
               }
            }
            this.lastStatisticsRange = undefined;
            clearTimeout(this.statisticsTimeout);
            if(this.data!.covers(minT.unix(), maxT.unix())) {
               console.log("Covers!");
               this.lastStatisticsRange = statisticsRange;
               if (delay) {
                  this.statisticsTimeout = setTimeout(this.loadStatistics, delay);
               } else {
                  this.loadStatistics()
               }
            }
         }
      }
   };

   private getStatisticsRange = () => {
      return [
         toMoment(this.area.getMinT(false)),
         toMoment(this.area.getMaxT(false)),
      ];
   };

   loadStatistics = () => {
      let [minT, maxT] = this.getStatisticsRange();
      console.log("loadStatistics " + minT.toISOString() + " - " + maxT.toISOString());

      // Get TDDS:
      let statisticsGenerator = new StatisticsGenerator(
         this.data!,
         this.patientData,
         this.resources,
         this.hiddenEventIds,
         false);
      statisticsGenerator.addTimeRange(minT, maxT);

      this.statistics = statisticsGenerator.getStatistics(this.patientData.unit);
      if (this.onStatisticsChange) {
         this.onStatisticsChange(this.statistics);
      }
   };
}