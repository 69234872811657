export const DEFAULT_MAX_GLUCOSE = 25;

export interface GraphSettings {
   showGlucose: boolean;
   showCGM: boolean;

   maxGlucose: number;
}

export enum GlucoseUnit {
   MMOL = "mmol/L",
   MG = "mg/dL",
}
