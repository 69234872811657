import {Moment} from "moment";
import * as moment from "moment";

export enum SearchType {
   Nearest = 1,
   Backward = 2
}

export function toMoment(unix: number) : Moment {
   return moment.unix(unix).utc();
}

export function unixToString(unix: number) : string {
   return toMoment(unix).toISOString();
}

export function formatTimeString(fromTime: number, toTime: number, getDate: boolean) {
   let timeString = undefined;
   if (fromTime) {
      timeString = toMoment(fromTime).format(getDate ? "DD/MM HH:mm" : "HH:mm");
      if (fromTime != toTime) {
         timeString += " - " + toMoment(toTime).format("HH:mm");
      }
   }
   return timeString;
}

export function findIndex(data: any[], t: number, searchType: SearchType): number | undefined {
   if(data) {
      let index = binarySearch(data, t);
      if(index == 0) {
         return index;
      }
      let next = data[index];
      let prevIndex = index - 1;
      let prev = data[prevIndex];
      if(next) {
         switch (searchType) {
            case SearchType.Nearest:
               let timeToNext = next[0] - t;
               let timeToPrev = t - prev[0];
               return timeToNext < timeToPrev ? index : prevIndex;

            case SearchType.Backward:
               return next[0] == t ? index : prevIndex;
         }
      } else {
         return prevIndex;
      }
   }
}

export function findValue(data: any[], t: number, searchType: SearchType) {
   let index = findIndex(data, t, searchType);
   return index == undefined ? undefined : data[index];
}

export function getRange(data: any[], fromT: number, toT: number): [number, number] {
   let fromIndex = binarySearch(data, fromT);
   let toIndex = binarySearch(data, toT);
   if(toIndex == data.length || (data[toIndex] && data[toIndex][0] > toT)) {
      toIndex = toIndex - 1;
   }
   return [fromIndex, toIndex];
}

/**
 * Returns index of the item on or after the specified time
 *
 * @param data
 * @param t
 */
function binarySearch(data: any[], t: number): number {
   let m = 0;
   let n = data.length - 1;
   while (m <= n) {
      let k = (n + m) >> 1;
      let cmp = t - data[k][0];
      if (cmp > 0) {
         m = k + 1;
      } else if(cmp < 0) {
         n = k - 1;
      } else {
         return k;
      }
   }
   return m;
}
