import {GraphData} from "./GraphData";
import {EventStatisticsGenerator} from "./EventStatisticsGenerator";
import {GraphResources} from "./GraphResources";
import {InsulinStatisticsGenerator} from "./InsulinStatisticsGenerator";
import {Moment} from "moment";
import {TimeRangeStatistics} from "./TimeRangeStatistics";
import {GlucoseStatisticsGenerator} from "./GlucoseStatisticsGenerator";
import {PatientData} from "./PatientData";
import {getRange} from "./DataTools";
import {BasalStatisticsGenerator} from "./BasalStatisticsGenerator";
import {GlucoseUnit} from "./GraphSettings";
import {PumpModeStatisticsGenerator} from "./PumpModeStatisticsGenerator";

export class StatisticsGenerator {

   private graphData:GraphData;
   private eventStatisticsGenerator: EventStatisticsGenerator;
   private insulinStatisticsGenerator: InsulinStatisticsGenerator;
   private glucoseStatisticsGenerator: GlucoseStatisticsGenerator;
   private basalStatisticsGenerator: BasalStatisticsGenerator;
   private pumpModeStatisticsGenerator: PumpModeStatisticsGenerator;

   private carbsTotal: number = 0;
   private exerciseTotal: number = 0;
   private nDays: number = 0;

   private timeRange:string = "";

   static readonly DATE_FORMAT = "ddd, DD MMM";

   constructor(graphData: GraphData, patientData: PatientData, graphResources: GraphResources, hiddenEventIds: Set<number>, generateAgp: boolean) {
      this.graphData = graphData;
      this.eventStatisticsGenerator = new EventStatisticsGenerator(graphData, graphResources, hiddenEventIds);
      this.insulinStatisticsGenerator = new InsulinStatisticsGenerator(graphData);
      this.glucoseStatisticsGenerator = new GlucoseStatisticsGenerator(graphData, patientData, generateAgp);
      this.basalStatisticsGenerator = new BasalStatisticsGenerator(graphData);
      this.pumpModeStatisticsGenerator = new PumpModeStatisticsGenerator(graphData);
   }

   addTimeRange(from: Moment, to:Moment) {
      let startTimeDateString = from.format(StatisticsGenerator.DATE_FORMAT);
      let endTimeDateString = to.format(StatisticsGenerator.DATE_FORMAT);
      let fromUnix = from.unix();
      let toUnix = to.unix();
      this.nDays += Math.round((toUnix - fromUnix) / (24 * 3600));


      this.timeRange += startTimeDateString;
      if(startTimeDateString != endTimeDateString) {
         this.timeRange += " - " + endTimeDateString;
      }

      this.eventStatisticsGenerator.addTimeRange(fromUnix, toUnix);
      this.insulinStatisticsGenerator.addTimeRange(fromUnix, toUnix);
      this.glucoseStatisticsGenerator.addTimeRange(fromUnix, toUnix);
      this.basalStatisticsGenerator.addTimeRange(fromUnix, toUnix);
      this.pumpModeStatisticsGenerator.addTimeRange(fromUnix, toUnix);

      let [minI, maxI] = getRange(this.graphData.carbs, fromUnix, toUnix);
      for (let i = minI; i <= maxI; i++) {
         let carbs = this.graphData.carbs[i];
         this.carbsTotal += carbs[1];
      }

      [minI, maxI] = getRange(this.graphData.exercise, fromUnix, toUnix);
      for (let i = minI; i <= maxI; i++) {
         let exercise = this.graphData.exercise[i];
         this.exerciseTotal += exercise[1];
      }
   }

   getStatistics(unit: GlucoseUnit) {
      const statistics : TimeRangeStatistics = {
         inactive: false,
         timeRange: this.timeRange,
         cgm: this.glucoseStatisticsGenerator.getGlucoseStatistics(unit),
         hypos: this.glucoseStatisticsGenerator.getHypos(),
         insulin: this.insulinStatisticsGenerator.getStatistics(),
         events: this.eventStatisticsGenerator.getStatistics(),
         basal: this.basalStatisticsGenerator.getStatistics(),
         pumpMode: this.pumpModeStatisticsGenerator.getStatistics(),
         carbsTotal: this.carbsTotal,
         carbsAverageDaily: this.carbsTotal / this.nDays,
         exerciseTotal: this.exerciseTotal,
         exerciseAverageDaily: this.exerciseTotal / this.nDays,
      };
      return statistics;
   }

   getAgpHours() {
      return this.glucoseStatisticsGenerator.getAgpHours();
   }
}