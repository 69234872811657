import * as moment from 'moment';
import AnimatedValue from "./AnimatedValue";
import {ZoomStatus} from "./ZoomStatus";
import {toMoment} from "./DataTools";

export default class GraphArea {

   globMinT:number = undefined;
   globMaxT:number = undefined;

   private minT:AnimatedValue = new AnimatedValue(0);
   private maxT:AnimatedValue = new AnimatedValue(0);

   w: number;
   x: number;

   lastPanX:number;
   zoomStatus: ZoomStatus = {
      nShownDays: undefined,
      lastTimeRangeName: undefined,
      lastTimeRangeEnabled: false,
      nextEnabled: false,
      prevEnabled: false,
   };
   onZoomStatusChange: (zoomStatus:ZoomStatus) => void;

   constructor() {
      this.initiate()
   }

   initiate(globMinT:number = 0, globMaxT:number = 3600 * 24) {
      this.globMinT = globMinT;
      this.globMaxT = globMaxT;
      this.minT.setVal(globMinT);
      this.maxT.setVal(globMaxT);
   }

   freeze = () => {
      this.minT.freeze();
      this.maxT.freeze();
   };

   isAnimating = () => {
      return this.minT.isAnimating() || this.maxT.isAnimating();
   };

   changeZoom = (x: number, deltaZoom:number) => {
      let part = x / this.w;
      let totT = this.maxT.getToVal() - this.minT.getToVal();
      let deltaT = totT * deltaZoom;
      let secPerPixel = (totT + deltaT) / this.w;
      let maxSecPerPixel = 3600 * 24 * 14 / this.w; // Show maximum 14 days of data
      if(secPerPixel < 30) {
         deltaT = this.w * 30 - totT;
      } else if(secPerPixel > maxSecPerPixel) {
         deltaT = this.w * maxSecPerPixel - totT;
      }
      this.minT.animateValue(Math.max(this.globMinT, this.minT.getToVal() - deltaT * part), 100);
      this.maxT.animateValue(Math.min(this.globMaxT, this.maxT.getToVal() + deltaT * (1 - part)), 100);

      this.zoomStatus.nShownDays = undefined;
      this.zoomStatus.lastTimeRangeName = "(Custom period)";
      this.zoomStatus.lastTimeRangeEnabled = false;
      this.updateZoomStatus();
   };

   pan = (isFirst: boolean, deltaX:number) => {
      if(isFirst) {
         this.lastPanX = 0;
      }
      let p = (this.lastPanX - deltaX) * (this.maxT.getVal()- this.minT.getVal()) / this.w;
      this.lastPanX = deltaX;

      // Limit on global min/max:
      p = Math.max(p, this.globMinT - this.minT.getVal());
      p = Math.min(p, this.globMaxT - this.maxT.getVal());

      this.minT.setVal(this.minT.getVal() + p);
      this.maxT.setVal(this.maxT.getVal() + p);
      this.updateZoomStatus();
   };

   setSize = (x: number, w:number) => {
      this.x = x;
      this.w = w;
   };

   getW = (t: number) => {
      return t / (this.maxT.getVal() - this.minT.getVal()) * this.w;
   };

   getX = (t: number) => {
      return this.x + (t - this.minT.getVal()) / (this.maxT.getVal() - this.minT.getVal()) * this.w;
   };

   getDayX = (t: moment.Moment) => {
      let dayT = (t.hours() * 60 + t.minutes()) * 60 + t.seconds();
      return this.getX(dayT);
   };

   getT = (x: number) => {
      return this.minT.getVal() + (x - this.x) / this.w * (this.maxT.getVal() - this.minT.getVal())
   };

   toDigit = (n: number): string => {
      if(n < 0) {
         return "-" + this.toDigit(-n);
      }
      return n >= 10 ? n.toString() : ("0" + n.toString())
   };

   getTimeAndDateString(x: number) {
      let t = this.getT(x);
      let d = new Date(t * 1000);
      let dateString = d.getUTCFullYear() + "-" + this.toDigit(1 + d.getUTCMonth()) + "-" +  this.toDigit(d.getUTCDate());
      let timeString =  this.toDigit(d.getUTCHours()) + ":" +  this.toDigit(d.getUTCMinutes());
      return dateString + " " + timeString;
   }

   getTimeString(x: number) {
      let t = this.getT(x);
      let d = new Date(t * 1000);
      return this.toDigit(d.getUTCHours()) + ":" +  this.toDigit(d.getUTCMinutes());
   }

   getStartOfFirstVisibleDay() {
      return GraphArea.getDayFromTime(this.minT.getVal());
   }

   getMinT(considerAnimation:boolean = true) {
      return considerAnimation ? this.minT.getVal() : this.minT.getToVal();
   }

   getMaxT(considerAnimation:boolean = true) {
      return considerAnimation ? this.maxT.getVal() : this.maxT.getToVal();
   }

   getDurationW(seconds: number) {
      return seconds * this.w / (this.maxT.getVal() - this.minT.getVal());
   }

   static getDayFromTime(t: number) {
      let d = new Date(t * 1000);
      d.setUTCHours(0, 0, 0, 0);
      return d.valueOf() / 1000;
   }

   getDay(x: number) {
      return GraphArea.getDayFromTime(this.getT(x));
   }

   showDays(nDays:number, x:number = undefined, animate:boolean = true) {
      let mid = (this.maxT.getVal() + this.minT.getVal()) / 2;
      if(x != undefined) {
         mid = this.getT(x);
      }
      let daysBefore = Math.floor(nDays / 2);
      let from = toMoment(mid).startOf("day").subtract(daysBefore, "days");
      let to = from.clone().add(nDays, "days");
      if(to.unix() > this.globMaxT) {
         this.showLastDays(nDays, animate);
      } else {
         if(animate) {
            this.minT.animateValue(from.unix());
            this.maxT.animateValue(to.unix());
         } else {
            this.minT.setVal(from.unix());
            this.maxT.setVal(to.unix());
         }
         this.setShownDaysStatus(nDays);
         this.updateZoomStatus();
      }
   }

   showLastDays(nDays: number, animate:boolean = true) {
      let to = toMoment(this.globMaxT).startOf("day");
      let from = to.clone().subtract(nDays, "days");
      if(animate) {
         this.minT.animateValue(from.unix());
         this.maxT.animateValue(to.unix());
      } else {
         this.minT.setVal(from.unix());
         this.maxT.setVal(to.unix());
      }
      this.setShownDaysStatus(nDays);
      this.updateZoomStatus();
   }

   showRange(from: number, to:number, animate: boolean) {
      if(animate) {
         this.minT.animateValue(from);
         this.maxT.animateValue(to);
      } else {
         this.minT.setVal(from);
         this.maxT.setVal(to);
      }
      this.updateZoomStatus();
   }


   private setShownDaysStatus(nDays:number) {
      if(this.zoomStatus.nShownDays != nDays) {
         this.zoomStatus.nShownDays = nDays;

         if (nDays == 1) {
            this.zoomStatus.lastTimeRangeName = "Yesterday";
            this.zoomStatus.lastTimeRangeEnabled = true;
         } else if (nDays) {
            this.zoomStatus.lastTimeRangeName = "Last " + nDays + " days";
            this.zoomStatus.lastTimeRangeEnabled = true;
         } else {
            this.zoomStatus.lastTimeRangeName = "(Custom period)";
            this.zoomStatus.lastTimeRangeEnabled = false;
         }
      }
   }

   private updateZoomStatus() {
      this.zoomStatus.nextEnabled = this.maxT.getToVal() + 24 * 3600 < this.globMaxT;
      this.zoomStatus.prevEnabled = this.minT.getToVal() - 24 * 3600 > this.globMinT;
      this.onZoomStatusChange && this.onZoomStatusChange(this.zoomStatus);
   }

   panDays(nDays: number) {
      let newMinT = toMoment(this.minT.getToVal()).add(nDays, "days").unix();
      let newMaxT = toMoment(this.maxT.getToVal()).add(nDays, "days").unix();
      if(newMinT >= this.globMinT && newMaxT <= this.globMaxT) {
         this.minT.animateValue(newMinT);
         this.maxT.animateValue(newMaxT);
         this.updateZoomStatus();
      }
   }

   getPixPerSec() {
      return this.w / (this.maxT.getVal() - this.minT.getVal());
   }

   isSet() {
      return this.globMinT != undefined && this.w;
   }
}