import {formatTimeString} from "./DataTools";

export class CursorInfo {

   cursorX: number;
   cursorY: number;

   private distSqr: number;
   markerY: number;
   markerX: number;
   data: any;
   fromTime: any;
   toTime: any;

   private lowPrioDistSqr: number;
   private isLowPrioData: boolean = true;

   constructor(cursorX: number, cursorY: number, maxDist: number = 20) {
      this.cursorX = cursorX;
      this.cursorY = cursorY;
      this.distSqr = maxDist * maxDist;
      this.lowPrioDistSqr = this.distSqr;
   }

   suggestPoint(x: number, y: number, data: any, fromTime: number, toTime?: number) {
      let dist = (x - this.cursorX) * (x - this.cursorX) + (y - this.cursorY) * (y - this.cursorY);
      if(dist < this.distSqr) {
         this.isLowPrioData = false;
         this.distSqr = dist;
         this.data = data;
         this.fromTime = fromTime;
         this.toTime = toTime ? toTime : fromTime;
         this.markerY = y;
         this.markerX = x;
      }
   }

   suggestArea(x: number, y: number, w: number, h: number, data: any, fromTime: number, toTime: number, lowPrio: boolean = false) {
      let dx = 0;
      let markerX = this.cursorX;
      if(this.cursorX < x) {
         dx = this.cursorX - x;
         markerX = x;
      } else if(this.cursorX > x + w) {
         dx = this.cursorX - x - w;
         markerX = x + w;
      }

      let dy = 0;
      let markerY = this.cursorY;
      if(this.cursorY < y) {
         dy = this.cursorY - y;
         markerY = y;
      } else if(this.cursorY > y + h) {
         dy = this.cursorY - y - h;
         markerY = y + h;
      }

      let dist = dx * dx + dy * dy;
      let useData = false;
      if(lowPrio) {
         useData = this.isLowPrioData && dist < this.lowPrioDistSqr;
      } else {
         useData = dist < this.distSqr;
      }


      if(useData) {
         if(lowPrio) {
            this.lowPrioDistSqr = dist;
         } else {
            this.distSqr = dist;
            this.isLowPrioData = false;
         }
         this.data = data;
         this.fromTime = fromTime;
         this.toTime = toTime;
         this.markerY = markerY;
         this.markerX = markerX;
      }
   }

   getTimeString(getDate: boolean): string {
      return formatTimeString(this.fromTime, this.toTime, getDate);
   }
}