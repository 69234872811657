import {BasalType, BolusData} from "./GraphData";
import Style from "./Style";
import {PatientData} from "./PatientData";
import {GlucoseStatisticsGenerator} from "./GlucoseStatisticsGenerator";
import GraphAxis from "./GraphAxis";
import Icon from "./Icon";
import {EventDescription} from "./GraphResources";
import AbstractGraph from "./AbstractGraph";

export function drawDownArrow(graph: AbstractGraph<any>, x: number, y: number, text: string) {
   let ctx = graph.ctx;
   let dpr = graph.dpr;
   ctx.fillStyle = Style.CURSOR;
   graph.setFont(Style.TEXT_SIZE);
   let tm = ctx.measureText(text);
   let m = 5 * dpr;
   let h = 16 * dpr;
   let boxX = x - tm.width / 2 - m;
   drawRoundRect(ctx, boxX, y, tm.width + m * 2, h, 5 * dpr);
   ctx.fill();

   ctx.moveTo(x, y + h + m);
   ctx.lineTo(x - m, y + h);
   ctx.lineTo(x + m, y + h);
   ctx.closePath();
   ctx.fill();

   ctx.fillStyle = "#FFFFFF";
   ctx.fillText(text, boxX + m, y + 12 * dpr);
}

export function drawCarbsArrow(graph: AbstractGraph<any>, x: number, y: number, text: string, scale: number = 1.0): number {
   let ctx = graph.ctx;
   ctx.fillStyle = Style.CARBS_FILL;
   graph.setFont(Style.TEXT_SIZE * scale);
   scale *= graph.dpr;
   let tm = ctx.measureText(text);
   let m = 5 * scale;
   let h = 16 * scale;
   let boxX = x - tm.width / 2 - m;
   drawRoundRect(ctx, boxX, y + m, tm.width + m * 2, h, 5 * scale);
   ctx.fill();

   ctx.moveTo(x, y);
   ctx.lineTo(x - m, y + m);
   ctx.lineTo(x + m, y + m);
   ctx.closePath();
   ctx.fill();

   if(scale > 0.9) {
      ctx.fillStyle = "#FFFFFF";
      ctx.fillText(text, boxX + m, y + 12 * scale + m);
   }
   return y + m + (h / 2);
}

export function drawMultiline(graph: AbstractGraph<any>, x: number, y: number, maxX: number, maxY: number, lines: string[][], timeString?: string) {
   let ctx = graph.ctx;
   let dpr = graph.dpr;
   ctx.fillStyle = Style.CURSOR;

   let lineH = (Style.TEXT_SIZE + 2) * dpr;
   let w0 = 0;
   let w1 = 0;
   let boxH = 9 * dpr;
   let m = 5 * dpr;

   let timeStringW;
   if(timeString) {
      graph.setFont(Style.POPUP_TIME_SIZE);
      timeStringW = ctx.measureText(timeString).width;
      w0 = Math.max(timeStringW, w0);
      boxH += (Style.POPUP_TIME_SIZE + 1) * dpr;
   }

   graph.setFont(Style.TEXT_SIZE);
   for(let line of lines) {
      w0 = Math.max(ctx.measureText(line[0]).width, w0);
      if(line[1]) {
         w1 = Math.max(ctx.measureText(line[1]).width, w1);
      }
      if(line[0] == "") {
         boxH += m;
      } else {
         boxH += lineH;
      }
   }
   let boxY = Math.min(maxY - boxH - m, y - boxH / 2);
   let boxW = w0 + m * 2;
   if(w1) {
      boxW += w1 + m;
   }
   let boxX = x + m + 1;
   ctx.beginPath();
   if(boxX + boxW < maxX) {
      ctx.moveTo(x + 1, y);
      ctx.lineTo(x + 2 + m, y - m);
      ctx.lineTo(x + 2 + m, y + m);
   } else {
      // Draw box to the left:
      boxX = x - m - 1 - boxW;
      ctx.moveTo(x - 1, y);
      ctx.lineTo(x - 2 - m, y - m);
      ctx.lineTo(x - 2 - m, y + m);

   }
   ctx.closePath();
   ctx.fill();
   drawRoundRect(ctx, boxX, boxY, boxW, boxH, 5);
   ctx.fill();

   ctx.fillStyle = "#FFFFFF";
   let lineY = boxY + 15 * dpr;
   let textX = boxX + m;

   if(timeString) {
      graph.setFont(Style.POPUP_TIME_SIZE);
      ctx.fillText(timeString, boxX + boxW - timeStringW - m, lineY - 5);
      lineY += (Style.POPUP_TIME_SIZE + 1) * dpr;
   }
   graph.setFont(Style.TEXT_SIZE);

   for(let line of lines) {
      ctx.fillText(line[0], textX, lineY);
      if(line[1]) {
         ctx.fillText(line[1], textX + w0 + m, lineY);
      }
      if(line[0] == "") {
         lineY += m;
      } else {
         lineY += lineH;
      }
   }
}

export function drawBolus(graph: AbstractGraph<any>, x: number, y: number, maxX: number, maxY: number, bolusData: BolusData, timeString?: string) {
   let lines: string[][] = [];
   if(bolusData.automatic) {
      lines.push(["Automatic", ""]);
      lines.push(["", ""]);
   }
   if(bolusData.immediate) {
      lines.push(["Immediate bolus:", "" + bolusData.immediate + " U"]);
   }

   if(bolusData.extended) {
      lines.push(["Extended bolus:", "" + bolusData.extended + " U"]);
      lines.push(["Duration:", "" + bolusData.duration + " min"]);
   }

   lines.push(["", ""]);

   if(bolusData.suggMeal) {
      lines.push(["Suggested meal:", "" + bolusData.suggMeal.toFixed(1) + " U"]);
   }
   if(bolusData.suggCorr) {
      lines.push(["Suggested corr:", "" + bolusData.suggCorr.toFixed(1) + " U"]);
   }
   if(bolusData.iob) {
      lines.push(["Insulin on board:", "" + bolusData.iob.toFixed(1) + " U"]);
   }

   if(lines[lines.length - 1][0] != "") {
      lines.push(["", ""]);
   }

   if(bolusData.carbs) {
      lines.push(["Carbs:", "" + bolusData.carbs + " g"]);
   }
   if(bolusData.icRatio) {
      lines.push(["I/C ratio:", "" + bolusData.icRatio.toFixed(1) + " g/U"]);
   }
   if(bolusData.bgTarget) {
      lines.push(["BG target:", graph.getGlucoseString(bolusData.bgTarget)]);
   }
   if(bolusData.bgValue) {
      lines.push(["BG value:", graph.getGlucoseString(bolusData.bgValue)]);
   }
   if(bolusData.isf) {
      lines.push(["ISF:", "" + bolusData.isf.toFixed(1) + " mmol/U"]);
   }

   if(lines[lines.length - 1][0] == "") {
      lines.pop();
   }
   drawMultiline(graph, x, y, maxX, maxY, lines, timeString);
}

export function drawLeftArrow(graph: AbstractGraph<any>, x: number, y: number, text: string) {
   let ctx = graph.ctx;
   ctx.fillStyle = Style.CURSOR;
   graph.setFont(Style.TEXT_SIZE);
   let tm = ctx.measureText(text);
   let m = 5;
   drawRoundRect(ctx, x + m + 1, y - 11, tm.width + m * 2, 22, 5);
   ctx.fill();

   ctx.moveTo(x + 1, y);
   ctx.lineTo(x + 1 + m, y - m);
   ctx.lineTo(x + 1 + m, y + m);
   ctx.closePath();
   ctx.fill();

   ctx.fillStyle = "#FFFFFF";
   ctx.fillText(text, x + m + m + 1, y + 5);
}

export function drawUpArrow(graph: AbstractGraph<any>, x: number, y: number) {
   let ctx = graph.ctx;
   let sizeX = graph.dpr * 5;
   let sizeY = graph.dpr * 4;
   ctx.beginPath();
   ctx.moveTo(x - sizeX, y + sizeY);
   ctx.lineTo(x, y - 1);
   ctx.lineTo(x + sizeX, y + sizeY);
   ctx.closePath();
   ctx.fill()
}

export function drawRightArrow(graph: AbstractGraph<any>, x: number, y: number, text: string) {
   let ctx = graph.ctx;
   ctx.fillStyle = Style.CURSOR;
   graph.setFont(Style.TEXT_SIZE);
   let tm = ctx.measureText(text);
   let m = 5;
   let boxW = tm.width + m * 2;
   drawRoundRect(ctx, x - m - 1 - boxW, y - 11, boxW, 22, 5);
   ctx.fill();

   ctx.moveTo(x - 1, y);
   ctx.lineTo(x - 1 - m, y - m);
   ctx.lineTo(x - 1 - m, y + m);
   ctx.closePath();
   ctx.fill();

   ctx.fillStyle = "#FFFFFF";
   ctx.fillText(text, x - 1 - boxW, y + 5);
}


export function drawRoundRect(ctx: CanvasRenderingContext2D, x: number, y: number, w: number, h: number, r: number) {
   if (w < 2 * r) r = w / 2;
   if (h < 2 * r) r = h / 2;
   ctx.beginPath();
   ctx.moveTo(x+r, y);
   ctx.arcTo(x+w, y,   x+w, y+h, r);
   ctx.arcTo(x+w, y+h, x,   y+h, r);
   ctx.arcTo(x,   y+h, x,   y,   r);
   ctx.arcTo(x,   y,   x+w, y,   r);
   ctx.closePath();
   return this;
}

export function getGlucoseColor(patientData:PatientData, value:number) {
   if(value >= GlucoseStatisticsGenerator.SEVERE_HIGH_LIMIT) {
      return Style.GLUCOSE_SEVERE_HIGH;
   } else if (value > patientData.goal_max) {
      return Style.GLUCOSE_HIGH;
   } else if (value < GlucoseStatisticsGenerator.SEVERE_LOW_LIMIT) {
      return Style.GLUCOSE_SEVERE_LOW;
   } else if (value < patientData.goal_min) {
      return Style.GLUCOSE_LOW;
   } else {
      return Style.GLUCOSE_IN_RANGE;
   }
}

export function drawLine(ctx:CanvasRenderingContext2D, x1: number, y1: number, x2: number, y2: number) {
   ctx.beginPath();
   ctx.moveTo(x1, y1);
   ctx.lineTo(x2, y2);
   ctx.stroke();
   ctx.closePath();
}

export function drawScale(graph:AbstractGraph<any>, axis: GraphAxis, scaleW:number, name:string, unit: string, values: number[], fractionDigits:number = 1) {
   if(!axis.isVisible) {
      return;
   }

   let ctx = graph.ctx;
   let dpr = graph.dpr;
   ctx.fillStyle = Style.AXIS_NAME;
   graph.setFont(Style.TEXT_SIZE + 1, "bold");
   let nameW = ctx.measureText(name).width;
   let textY = 16 * dpr;
   ctx.save();
   ctx.translate(0, axis.getBottom());
   ctx.rotate(-Math.PI/2);

   ctx.fillText(name, (axis.height - nameW) / 2, textY);
   graph.setFont(Style.TEXT_SIZE);
   ctx.fillStyle = Style.AXIS_UNIT;
   let unitW = ctx.measureText(unit).width;
   ctx.fillText(unit, (axis.height - unitW) / 2, textY + Style.TEXT_SIZE * dpr + 2);
   ctx.restore();

   graph.setFont(Style.AXIS_VALUE_SIZE);
   ctx.textAlign = "right";
   ctx.textBaseline = "bottom"
   ctx.strokeStyle = Style.AXIS_VALUE;
   for(let value of values) {
      let y = axis.getY(value);
      drawLine(ctx, scaleW, y, scaleW - 5, y);
      ctx.fillText(value.toFixed(fractionDigits), scaleW - 8, y + 2);
   }
   graph.setFont(Style.TEXT_SIZE);
   ctx.textAlign = "left";
   ctx.textBaseline = "alphabetic"

   ctx.strokeStyle = Style.AXIS_HORIZONTAL;
   drawLine(ctx, scaleW, axis.getBottom() - 1, ctx.canvas.width, axis.getBottom() - 1);
}

export function getBasalColor(basalType: BasalType) {
   switch(basalType) {
      case BasalType.TempModified: return Style.BASAL_TEMP_MODIFIED;
      case BasalType.PredictiveLowGlucoseSuspend: return Style.BASAL_PREDICTIVE_LOW_GLUCOSE_SUSPEND;
      case BasalType.LowGlucoseSuspend: return Style.BASAL_LOW_GLUCOSE_SUSPEND;
   }
}

export function getBasalTransparentColor(basalType: BasalType) {
   switch(basalType) {
      case BasalType.PredictiveLowGlucoseSuspend: return Style.BASAL_TRANSPARENT_PREDICTIVE_LOW_GLUCOSE_SUSPEND;
      case BasalType.LowGlucoseSuspend: return Style.BASAL_TRANSPARENT_LOW_GLUCOSE_SUSPEND;
   }
}

export function drawBasalLine(graph: AbstractGraph<any>, x1: number, y1: number, x2: number, y2: number, basalType: BasalType) {
   let ctx = graph.ctx;
   let dpr = graph.dpr;
   if(basalType != BasalType.Normal) {
      ctx.strokeStyle = getBasalColor(basalType);
      ctx.lineWidth = 2 * dpr;
      ctx.setLineDash([4 * dpr, 4 * dpr]);
   } else {
      ctx.strokeStyle = Style.BASAL_STROKE;
      ctx.lineWidth = dpr;
   }
   drawLine(ctx, x1, y1, x2, y2);
   ctx.setLineDash([]);
}

export function drawIcon(graph: AbstractGraph<any>, x: number, y: number, eventDescription: EventDescription) {
   let ctx = graph.ctx;
   let dpr = graph.dpr;
   if(eventDescription && eventDescription.icon) {
      let iconSize = 14 * dpr;
      let icon = Icon.getImage(eventDescription.icon);
      ctx.drawImage(icon, x - iconSize / 2, y - iconSize / 2, iconSize,iconSize);
   } else {
      let size = 6 * dpr;
      ctx.fillStyle = Style.EVENT_FILL;
      ctx.strokeStyle = Style.EVENT_STROKE;
      ctx.fillRect(x - size / 2, y - size / 2, size, size);
      ctx.strokeRect(x - size / 2, y - size / 2, size, size);
   }
}
