import React from "react"
import ButtonGroupComp from "./ButtonGroupComp";
import {TimelineDataTypesComp} from "./TimelineDataTypesComp";
import {TimelineSettings} from "../graphs/TimelineSettings";

export enum DailyLogRange {
  FULL_DAY,
  OVERNIGHT,
  BREAKFAST,
  LUNCH,
  DINNER,
}

export interface DailyLogSettings {
  nDays: number;
  range: DailyLogRange;
}

const daysValues: [number, string][] = [
  [7, "7 days"],
  [14, "14 days"],
]

const rangeValues: [DailyLogRange, string][] = [
  [DailyLogRange.FULL_DAY, "Full day"],
  [DailyLogRange.OVERNIGHT, "Overnight"],
  [DailyLogRange.BREAKFAST, "Breakfast"],
  [DailyLogRange.LUNCH, "Lunch"],
  [DailyLogRange.DINNER, "Dinner"]
]

export interface Props {
  settings: DailyLogSettings;
  setSettings: (settings: DailyLogSettings) => void;
  timelineSettings: TimelineSettings;
  setTimelineSettings: (settings: TimelineSettings) => void;
}

export const DailyLogControlsComp = (props: Props) => {
  const settings = structuredClone(props.settings) as DailyLogSettings

  const setDays = (nDays: number) => {
    settings.nDays = nDays;
    props.setSettings(settings);
  }

  const setRange = (range: DailyLogRange) => {
    settings.range = range;
    props.setSettings(settings);
  }

  return (
    <div className="box statistics" style={{width: "auto", marginLeft:0, marginRight:10}} >
      <div className="block">
        <ButtonGroupComp<number> vertical={true} values={daysValues} value={settings.nDays} onValueChange={setDays}/>
        <ButtonGroupComp<DailyLogRange> vertical={true} style={{marginTop:20}} values={rangeValues} value={settings.range} onValueChange={setRange}/>
        <TimelineDataTypesComp style={{marginTop:10}}settings={props.timelineSettings} updateSettings={props.setTimelineSettings} />
      </div>
    </div>
  )
}

