import {PatientData} from "./PatientData";
import {AgpHour} from "./AgpHour";
import {GlucoseStatistics} from "./TimeRangeStatistics";
import Style from "./Style";
import {getRange} from "./DataTools";
import {GraphData} from "./GraphData";
import {GlucoseUnit} from "./GraphSettings";
import {getGlucoseIntervalString, getGlucoseString, getGlucoseValue} from "./UnitTools";

export class GlucoseStatisticsGenerator {

   private graphData: GraphData;
   private patientData: PatientData;

   private nSevereHigh:number = 0;
   private nHigh:number = 0;
   private nInRange:number = 0;
   private nLow:number = 0;
   private nSevereLow:number = 0;

   private missingTime = 0;
   private totalTime: number = 0;
   private lastTime:number;
   private lastStartTime:number;

   private average:number;
   private deviation:number;
   private deviationPercentage:number;

   private agpHours:AgpHour[];

   private allValues:number[] = [];
   private valuesPerHourList:number[][];

   private hypos:number[] = [];

   constructor(graphData: GraphData, patientData: PatientData, generateAgp: boolean) {
      this.graphData = graphData;
      this.patientData = patientData;

      if(generateAgp) {
         this.valuesPerHourList = [];
         for (let i = 0; i < 24; i++) {
            this.valuesPerHourList[i] = [];
         }
      }
   }

   private start(time:number) {
      this.lastTime = time;
      this.lastStartTime = time;
   }

   private addValue(glucose: [number, number, number]) {
      let [time, value, hour] = glucose;
      this.allValues.push(value);

      if(this.valuesPerHourList) {
         this.valuesPerHourList[hour].push(value);
      }

      if(value < this.patientData.severe_low_limit) {
         this.nSevereLow++;
         this.hypos.push(time);
      } else if(value < this.patientData.goal_min) {
         this.nLow++;
      } else if(value > this.patientData.severe_high_limit) {
         this.nSevereHigh++;
      } else if(value > this.patientData.goal_max) {
         this.nHigh++;
      } else {
         this.nInRange++;
      }
      this.calcDiff(time);
      this.lastTime = time;
   }

   private stop(time:number) {
      this.calcDiff(time);
      this.totalTime += (time - this.lastStartTime);
   }

   private finalize() {
      if(this.allValues == undefined) {
         // Finalize has already been called.
         return;
      }

      // Calculate average:
      let sum = 0;
      for(let v of this.allValues) {
         sum += v;
      }
      this.average = sum / this.allValues.length;

      // Calculate standard deviation:
      let deviationSum = 0;
      for(let v of this.allValues) {
         deviationSum += (v - this.average) * (v - this.average);
      }
      this.deviation = Math.sqrt(deviationSum / this.allValues.length);
      this.deviationPercentage = 100 * this.deviation / this.average;

      // Calculate agp values:
      if(this.valuesPerHourList) {
         this.agpHours = this.valuesPerHourList.map(GlucoseStatisticsGenerator.generateAgpHour);
         this.agpHours[24] = this.agpHours[0];
      }

      this.allValues = undefined;
      this.valuesPerHourList = undefined;
   }

   private calcDiff(time: number) {
      let diff = time - this.lastTime;
      if(diff > 20 * 60) {
         this.missingTime += diff;
      }
      this.lastTime = time;
   }

   private static generateAgpHour(valueList: number[]): AgpHour {
      valueList.sort((a, b) => a - b);
      let length = valueList.length;

      // Get median:
      let mid = Math.floor(length / 2);
      let median = length % 2 !== 0 ? valueList[mid] : (valueList[mid - 1] + valueList[mid]) / 2;

      return {
         min: valueList[0],
         p10: GlucoseStatisticsGenerator.getPercentile(valueList, 10),
         p25: GlucoseStatisticsGenerator.getPercentile(valueList, 25),
         median: median,
         max: valueList[length - 1],
         p75: GlucoseStatisticsGenerator.getPercentile(valueList, 75),
         p90: GlucoseStatisticsGenerator.getPercentile(valueList, 90),
      }
   }

   private static getPercentile(valueList: number[], percentile: number): number {
      let len = valueList.length;
      return valueList[Math.round(len * percentile / 100)]
   }

   getHypos(): number[] {
      return this.hypos;
   }

   getGlucoseStatistics(unit:GlucoseUnit) : GlucoseStatistics {
      this.finalize();
      let totalPoints = this.nSevereLow + this.nLow + this.nInRange + this.nHigh + this.nSevereHigh;
      let missingPart = this.missingTime / this.totalTime;
      if(totalPoints) {
         return {
            average: getGlucoseValue(unit, this.average),
            standardDeviation: getGlucoseValue(unit, this.deviation),
            unit: unit,
            intervals: [
               {
                  percentage: 100 * this.nSevereHigh / totalPoints,
                  color: Style.GLUCOSE_SEVERE_HIGH,
                  description: "Very high",
                  range: ">" + getGlucoseString(unit, this.patientData.severe_high_limit)
               }, {
                  percentage: 100 * this.nHigh / totalPoints,
                  color: Style.GLUCOSE_HIGH,
                  description: "High",
                  range: getGlucoseIntervalString(unit, this.patientData.goal_max + 0.06, this.patientData.severe_high_limit)
               }, {
                  percentage: 100 * this.nInRange / totalPoints,
                  color: Style.GLUCOSE_IN_RANGE,
                  description: "Target range",
                  range: getGlucoseIntervalString(unit, this.patientData.goal_min, this.patientData.goal_max)
               }, {
                  percentage: 100 * this.nLow / totalPoints,
                  color: Style.GLUCOSE_LOW,
                  description: "Low",
                  range: getGlucoseIntervalString(unit, this.patientData.severe_low_limit, this.patientData.goal_min - 0.06)
               }, {
                  percentage: 100 * this.nSevereLow / totalPoints,
                  color: Style.GLUCOSE_SEVERE_LOW,
                  description: "Very low",
                  range: "<" + getGlucoseString(unit, this.patientData.severe_low_limit)
               },
            ],
            cgmCoveragePercentage: 100 - (100 * missingPart),
            missingColor: Style.GLUCOSE_MISSING,
         };
      } else {
         return undefined;
      }
   }

   getAgpHours() {
      this.finalize();
      return this.agpHours;
   }

   addTimeRange(from: number, to: number) {
      let cgmList = this.graphData.cgm;
      this.start(from);
      let [minI, maxI] = getRange(cgmList, from, to);
      for (let i = minI; i <= maxI; i++) {
         let glucose = cgmList[i];
         if (glucose && glucose[1]) {
            this.addValue(glucose);
         }
      }
      this.stop(to);
   }
}