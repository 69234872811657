import {GlucoseStatistics} from "../graphs/TimeRangeStatistics";
import React from "react";

export interface Props {
  statistics?: GlucoseStatistics;
  style?: any;
}

export const TimeInRangeBarComp = (props: Props) => {
  return (
    <div className="timeInRangeBar" style={props.style}>
      {props.statistics && props.statistics.intervals.map(interval =>
        <div style={{transition: "height 0.2s", background: interval.color, height: interval.percentage + '%'}}
             className="barItem"
             key={interval.description}></div>
      )}
    </div>)
}
