import {getRange} from "./DataTools";
import {BasalType, GraphData} from "./GraphData";
import {BasalStatistics} from "./TimeRangeStatistics";

export class BasalStatisticsGenerator {

   private graphData: GraphData;
   private total: number = 0;
   private predictiveLowGlucoseSuspend: number = 0;
   private lowGlucoseSuspend: number = 0;
   private temporaryModified: number = 0;

   constructor(graphData: GraphData) {
      this.graphData = graphData;
   }

   addTimeRange(from: number, to:number) {
      this.total += (to - from);

      let [minI, maxI] = getRange(this.graphData.basal, from, to);
      for (let i = minI; i < maxI; i++) {
         let basal = this.graphData.basal[i];
         let nextBasal = this.graphData.basal[i + 1];
         let length = nextBasal[0] - basal[0];
         let basalType = basal[2];
         switch (basalType) {
         case BasalType.TempModified:
            this.temporaryModified += length;
            break;

         case BasalType.LowGlucoseSuspend:
            this.lowGlucoseSuspend += length;
            break;

         case BasalType.PredictiveLowGlucoseSuspend:
            this.predictiveLowGlucoseSuspend += length;
            break;
         }
      }
   }

   getStatistics() {
      const basalStatistics : BasalStatistics = {
         percentagePredictiveLowGlucoseSuspend: 100 * this.predictiveLowGlucoseSuspend / this.total,
         percentageLowGlucoseSuspend: 100 * this.lowGlucoseSuspend / this.total,
         percentageTemporaryModified: 100 * this.temporaryModified / this.total
      }
      return basalStatistics
   }
}