import {EventStatisticsItem} from "./TimeRangeStatistics";
import {getRange} from "./DataTools";
import {GraphResources} from "./GraphResources";
import {GraphData} from "./GraphData";

export class EventStatisticsGenerator {

   private graphData: GraphData;
   private graphResources: GraphResources;
   private hiddenEventIds: Set<number>;
   private eventCounters:{ [key: number]: number; } = {};

   constructor(graphData: GraphData, graphResources: GraphResources, hiddenEventIds: Set<number>) {
      this.graphData = graphData;
      this.graphResources = graphResources;
      this.hiddenEventIds = hiddenEventIds;
   }

   addTimeRange(from: number, to:number) {
      let [minI, maxI] = getRange(this.graphData.events, from, to);
      for (let i = minI; i <= maxI; i++) {
         let event = this.graphData.events[i];
         let eventId = event[1];
         this.eventCounters[eventId] = this.eventCounters[eventId] ? this.eventCounters[eventId] + 1 : 1;
      }
   }

   getStatistics() {
      let entries = Object.entries(this.eventCounters);
      if(entries.length) {
         return entries.map(([idString, count]) => {
            let id = parseInt(idString);
            const item : EventStatisticsItem = {
               id: id,
               count: count,
               name: this.graphResources.events[id] ? this.graphResources.events[id].text : ("Unknown event " + id),
               icon: this.graphResources.events[id] ? this.graphResources.events[id].icon : undefined,
               isVisible: !this.hiddenEventIds.has(id)
            }
            return item;
         });
      } else {
         return undefined;
      }
   }
}