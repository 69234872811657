import React, {useEffect, useRef, useState} from "react";
import moment, {Moment} from 'moment'
import GlookoGraphRepository from "../graphs/GlookoGraphRepository";
import {DailyLogControlsComp, DailyLogRange, DailyLogSettings} from "./DailyLogControlsComp";
import {TimelineGraphComp} from "./TimelineGraphComp";
import {MiniatureGraphController, TimelineGraphController} from "./GraphController";
import {TimelineSettings} from "../graphs/TimelineSettings";
import {TimeRangeStatistics} from "../graphs/TimeRangeStatistics";
import {GlucoseStatisticsComp} from "./GlucoseStatisticsComp";
import {MiniatureGraphComp} from "./MiniatureGraphComp";
import {TimeInRangeBarComp} from "./TimeInRangeBarComp";
import {DEFAULT_MAX_GLUCOSE} from "../graphs/GraphSettings";

export interface Props {
  repo: GlookoGraphRepository;
}

export interface DailyLogInterval {
  dateAsString: string
  //date: string
  //weekDay: number
  //weekNumber: number
  //isVisible: boolean
  //isHighlighted: boolean

  from: number
  to: number
}

interface RangeDescription {
  start: number;
  length: number;
}

const RangeDescriptions: { [key in DailyLogRange] : RangeDescription } = {
  [DailyLogRange.FULL_DAY]: {start: 0, length: 24 * 60 * 60},
  [DailyLogRange.OVERNIGHT]: {start: 12 * 60 * 60, length: 24 * 60 * 60},
  [DailyLogRange.BREAKFAST]: {start: 6 * 60 * 60, length: 5 * 60 * 60},
  [DailyLogRange.LUNCH]: {start: 11 * 60 * 60, length: 3 * 60 * 60},
  [DailyLogRange.DINNER]: {start: 17 * 60 * 60, length: 3 * 60 * 60}
}

function DailyLogItem(props: {
      repo: GlookoGraphRepository,
      interval: DailyLogInterval,
      timelineSettings: TimelineSettings}) {
  const [graphController, _setGraphController] = useState<TimelineGraphController>(new TimelineGraphController());
  const [statistics, setStatistics] = useState<TimeRangeStatistics | undefined>(undefined)

  useEffect(() => graphController.showRange(props.interval.from, props.interval.to), [props.interval])
  useEffect(() => graphController.setSettings(props.timelineSettings), [props.timelineSettings])

  return (
    <div className="dailyLogItem box">
      <TimelineGraphComp style={{flexGrow:1, flexShrink:1, flexBasis:0, height:300, marginBottom:10, minWidth:0}}
                         repo={props.repo}
                         graphController={graphController}
                         settings={props.timelineSettings}
                         setStatistics={setStatistics}
                         setZoomStatus={() => {}}
                         initialRange={{
                           from: props.interval.from,
                           to: props.interval.to
                         }}/>
      <div className="statistics dailyStatistics" style={{width:300}}>
        {statistics?.cgm && <GlucoseStatisticsComp statistics={statistics.cgm}/>}
      </div>
    </div>);
}

function DailyLogMiniature(props: { repo: GlookoGraphRepository, interval: DailyLogInterval, onClick: () => void  }) {
  const [graphController, _setGraphController] = useState<MiniatureGraphController>(new MiniatureGraphController());
  const [statistics, setStatistics] = useState<TimeRangeStatistics | undefined>(undefined)
  const [settings, setSettings] = useState<TimelineSettings>({
    showBasal: true,
    showBolus: true,
    showCarbs: true,
    showEvents: true,
    showExercise: true,
    showGlucose: true,
    showCGM: true,
    showInsulin: true,
    showPumpModes: true,
    advancedCursor: false,
    allowPanning: false,
    useFullDayStatistics: false,
    maxGlucose: 15,
  })

  useEffect(() => graphController.showRange(props.interval.from, props.interval.to), [props.interval])

  return (
    <div className="dailyLogMiniature box" onClick={props.onClick}>
      <div style={{display:"flex", alignItems:"center"}}>
        <div>{props.interval.dateAsString}</div>
        {!!statistics?.hypos?.length && <span title="Severe low glucose" style={{color: "#B52222", marginLeft: "auto", fontSize:14, width:14}} className="material-icons">notifications</span>}
      </div>
      <div className="graphAndTIR">
        <MiniatureGraphComp style={{flexGrow:1, height:60, width:100, marginRight:5, minWidth:0}}
                           repo={props.repo}
                           graphController={graphController}
                           settings={settings}
                           setStatistics={setStatistics}
                           initialRange={{
                             from: props.interval.from,
                             to: props.interval.to
                           }}/>

        <TimeInRangeBarComp style={{width: 5}} statistics={statistics?.cgm}/>
      </div>
    </div>);
}

export const DailyLogView = (props: Props) => {
  const dailyLogItems = useRef<HTMLDivElement>(null);

  const [settings, setSettings] = useState<DailyLogSettings>({
    nDays: 7,
    range: DailyLogRange.FULL_DAY
  });
  const [timelineSettings, setTimelineSettings] = useState<TimelineSettings>({
    showBasal: true,
    showBolus: true,
    showCarbs: true,
    showEvents: true,
    showExercise: true,
    showGlucose: true,
    showCGM: true,
    showInsulin: true,
    showPumpModes: true,
    advancedCursor: false,
    allowPanning: false,
    useFullDayStatistics: false,
    maxGlucose: DEFAULT_MAX_GLUCOSE,
  })

  const [intervals, setIntervals] = useState<DailyLogInterval[]>([]);

  const scrollToItem = (n: number) => {
    if(dailyLogItems.current) {
      const item = dailyLogItems.current.children.item(n)!;
      item.scrollIntoView({behavior: "smooth", block: "center"});
      item.classList.add("flash");
      setTimeout(() => {
        item.classList.remove("flash");
      }, 1600)
    }
  }

  const updateRanges = () => {
    console.log("update ranges ", props.repo.lastSyncTime, settings);
    const lastSyncTime: Moment = moment(props.repo.lastSyncTime).utc(true);
    const lastSyncStartOfDay = lastSyncTime.clone().startOf("day");
    const rangeDescription = RangeDescriptions[settings.range];
    let intervals: DailyLogInterval[] = [];
    let dayStart: Moment = lastSyncStartOfDay
    for(let i = 0; i < settings.nDays; i++) {
      dayStart = dayStart.clone().subtract(1,"days");
      let intervalStart = dayStart.clone().add(rangeDescription.start, "seconds");
      let intervalEnd = intervalStart.clone().add(rangeDescription.length, "seconds");

      intervals.push({
        dateAsString: intervalStart.format("ddd, DD MMM"),
        from: intervalStart.unix(),
        to: intervalEnd.unix(),
      })
    }
    props.repo.requestData(intervals.at(-1)!.from, intervals[0].to)
    setIntervals(intervals)
  }

  useEffect(updateRanges, [settings, props.repo])
  return (
    <div className="tab">
      <div style={{display: "flex", flexGrow:1}}>
        <DailyLogControlsComp setSettings={setSettings} settings={settings} timelineSettings={timelineSettings} setTimelineSettings={setTimelineSettings}/>
        <div className={"dailyLogMiniatures"}>
          {intervals.map((interval, index) =>
            <DailyLogMiniature key={index} repo={props.repo} interval={interval} onClick={() => scrollToItem(index)}/>)
          }
        </div>
        <div className={"dailyLogItemsContainer"}>
          <div ref={dailyLogItems} className={"dailyLogItems"}>
            {intervals.map((interval, index) =>
              <DailyLogItem key={index} repo={props.repo} interval={interval} timelineSettings={timelineSettings}/>)
            }
          </div>
        </div>
      </div>
    </div>)
};
