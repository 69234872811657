export default class AnimatedValue {

   private fromVal: number;
   private fromTime: number;

   private toVal: number;
   private duration: number;

   private val:number = 0;

   constructor(val: number) {
      this.val = val;
   }

   freeze() {
      if(this.isAnimating()) {
         let t = (Date.now() - this.fromTime) / this.duration;
         if(t >= 1) {
            this.val = this.toVal;
         } else {
            let sqt = t * t;
            // Really neat ease-in-out function from
            // https://math.stackexchange.com/questions/121720/ease-in-out-function/121755#121755:
            t = sqt / (2.0 * (sqt - t) + 1.0);
            this.val = this.fromVal + (this.toVal - this.fromVal) * t;
         }
      }
   }

   animateValue(toVal:number, duration:number=500) {
      this.fromVal = this.val;
      this.fromTime = Date.now();
      this.toVal = toVal;
      this.duration = duration;
   }

   isAnimating():boolean {
      return this.toVal != this.val;
   }

   setVal(val: number) {
      this.val = val;
      this.toVal = val;
   }

   getVal() {
      return this.val;
   }

   getToVal() {
      return this.toVal;
   }
}