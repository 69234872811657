import React, {useState} from "react";
import GlookoGraphRepository from "../graphs/GlookoGraphRepository";
import {EventOverlayCalendarComp} from "./EventOverlayCalendarComp";
import {TimeRangeStatistics} from "../graphs/TimeRangeStatistics";
import {OverlaySettings, OverlayType} from "../graphs/OverlaySettings";
import {EventOverlayControlsComp} from "./EventOverlayControlsComp";
import {OverlayGraphController} from "./GraphController";
import {OverlayGraphComp} from "./OverlayGraphComp";

export interface Props {
  repo: GlookoGraphRepository;
}

export const EventsView = (props: Props) => {
  const [statistics, setStatistics] = useState<TimeRangeStatistics | undefined>(undefined)
  const [settings, setSettings] = useState<OverlaySettings | undefined>(undefined)
  const [graphController, _setGraphController] = useState<OverlayGraphController>(new OverlayGraphController())

  const updateSettings = (settings: OverlaySettings) => {
    setSettings(settings);
    graphController?.setSettings(settings);
  }

  return (
    <div className="tab">
      <div className="box" style={{flexGrow:1, flexShrink:1, flexBasis:0, display:"flex", flexDirection:"column"}}>
        <div style={{display:"flex", margin:15, alignItems: "flexStart"}}>
          {settings && <EventOverlayControlsComp settings={settings}
                                                 updateSettings={updateSettings}
                                                 graphController={graphController} />}
        </div>
        <div style={{flexGrow:1, flexShrink:1, flexBasis:0, position:"relative", height:600,marginBottom:15}}>
          <OverlayGraphComp style={{height:600, cursor:"crosshair", width:"100%"}}
                            repo={props.repo}
                            setSettings={setSettings}
                            graphController={graphController}
                            setStatistics={setStatistics}
                            initialOverlayType={OverlayType.Hypo}/>

        </div>
      </div>
      <div className="statistics box" style={{flexGrow:0, flexShrink:0, width:320, marginLeft:10, position:"relative", overflow:"auto"}}>
        {settings && <EventOverlayCalendarComp settings={settings} updateSettings={updateSettings}/>}
      </div>
    </div>)
};
