import {BasalType, BolusData} from "./GraphData";
import Hammer from "hammerjs";
import GraphAxis from "./GraphAxis";
import GraphRepository from "./GraphRepository";
import moment, {Moment} from 'moment';
import {findIndex, findValue, formatTimeString, getRange, SearchType, toMoment} from "./DataTools";
import {
   drawBasalLine,
   drawBolus,
   drawCarbsArrow,
   drawDownArrow,
   drawIcon,
   drawMultiline,
   drawRoundRect,
   drawScale,
   drawUpArrow,
   getBasalColor,
   getBasalTransparentColor
} from "./DrawTools";
import {CursorInfo} from "./CursorInfo";
import {TimeRangeStatistics} from "./TimeRangeStatistics";
import {ZoomStatus} from "./ZoomStatus";
import Style, {getPumpModeColors} from "./Style";
import {StatisticsGenerator} from "./StatisticsGenerator";
import AbstractGraph from "./AbstractGraph";
import {TimelineSettings} from "./TimelineSettings";
import GraphComponent from "./GraphComponent";
import {GlucoseUnit} from "./GraphSettings";
import {getPumpModeDescription} from "./PumpModeDescription";

export default class TimelineGraph extends AbstractGraph<TimelineSettings> {

   private basalAxis = new GraphAxis(4, 1);
   private bolusAxis = new GraphAxis(10, 1);
   private headerComp = new GraphComponent(40)
   private exerciseComp = new GraphComponent(15);
   private alarmComp = new GraphComponent(25);
   private carbsComp = new GraphComponent(22);
   private pumpModesComp = new GraphComponent(22);
   private components = [
      this.headerComp,
      this.glucoseAxis,
      this.exerciseComp,
      this.bolusAxis,
      this.carbsComp,
      this.alarmComp,
      this.basalAxis,
      this.pumpModesComp
   ];

   // Callbacks:
   readonly onStatisticsChange: (statistics: TimeRangeStatistics) => void;

   constructor(canvas: HTMLCanvasElement,
               graphRepo: GraphRepository,
               settings:TimelineSettings,
               onStatisticsChange: (statistics: TimeRangeStatistics) => void,
               onZoomStatusChange: (zoomStatus: ZoomStatus) => void) {
      super(canvas, graphRepo, settings);

      this.onStatisticsChange = onStatisticsChange;
      this.area.onZoomStatusChange = (zoomStatus: ZoomStatus) => {
         onZoomStatusChange && onZoomStatusChange(zoomStatus);
      };

      if(settings.allowPanning) {
         let hammer = new Hammer(canvas, {});
         hammer.on('panstart pan', (ev: any) => {
            this.area.pan(ev.type == 'panstart', ev.deltaX * this.dpr);
            this.scheduleRepaint();
         });
         hammer.on("tap", this.onTap);

         this.canvas.addEventListener("wheel", (we: WheelEvent) => {
            we.preventDefault();
            this.area.changeZoom(we.clientX * this.dpr - this.scaleW, we.deltaY > 0 ? 0.2 : -0.2);
            this.scheduleRepaint();
         });
      }

      let globToDate:Moment = moment(this.graphRepo.lastSyncTime).utc(true);
      let globFromDate = globToDate.clone().startOf("day").subtract(90, "days");
      this.area.initiate(globFromDate.unix(), globToDate.unix());
   }

   showDays = (nDays: number) => {
      this.area.showDays(nDays);
      this.scheduleRepaint();
   };

   showRange = (from: number, to: number, animate: boolean = true) => {
      this.area.showRange(from, to, animate);
      this.scheduleRepaint();
   }

   showLastDays = (nDays: number, animate: boolean = true) => {
      this.area.showLastDays(nDays, animate);
      this.scheduleRepaint();
   };

   panDays = (nDays:number) => {
      this.area.panDays(nDays);
      this.scheduleRepaint();
   };

   private requestData = () => {
      if(this.area.isSet() && this.graphRepo) {
         let [statMinT, statMaxT] = this.getStatisticsRange();
         let minT = Math.min(statMinT.unix(), this.area.getMinT(false));
         let maxT = Math.max(statMaxT.unix(), this.area.getMaxT(false));
         this.graphRepo.requestData(minT, maxT);
      }
   };

   protected repaint = () => {
      let h = this.canvas.height;
      let w = this.canvas.width;
      let minT = this.area.getMinT();
      let maxT = this.area.getMaxT();
      let ctx = this.ctx;
      let dpr = this.dpr;
      ctx.textAlign = undefined;
      this.setFont(12);

      this.scaleW = (Style.TEXT_SIZE * 2 + 35) * dpr;
      let scaleW = this.scaleW;

      // Place axises:
      this.glucoseAxis.isVisible = this.settings.showCGM || this.settings.showGlucose;
      this.bolusAxis.isVisible = this.settings.showBolus;
      this.basalAxis.isVisible = this.settings.showBasal;
      this.carbsComp.isVisible = this.settings.showCarbs;
      this.exerciseComp.isVisible = this.settings.showExercise;
      this.alarmComp.isVisible = this.settings.showEvents;
      this.pumpModesComp.isVisible = this.settings.showPumpModes;
      GraphComponent.place(this.components, dpr, h)

      this.area.setSize(scaleW, this.canvas.width - scaleW);

      this.requestData();

      if(!this.data) {
         ctx.textAlign = "center";
         ctx.fillText("Loading data....", w / 2, h / 2);
         return;
      }
      let patientData = this.patientData;
      let area = this.area;
      area.freeze();

      drawScale(this, this.glucoseAxis, scaleW, "Glucose", "(" + this.patientData.unit + ")",
         [0, this.getGlucoseValue(patientData.goal_min), this.getGlucoseValue(patientData.goal_max)],
        this.graphRepo.getPatientData().unit == GlucoseUnit.MMOL ? 1 : 0);
      drawScale(this, this.basalAxis, scaleW, "Basal", "(Units/h)", [0, 1, 2, 3]);
      drawScale(this, this.bolusAxis, scaleW, "Bolus", "(Units)", [0, 2, 4, 6, 8]);

      // Draw vertical line at scales:
      ctx.strokeStyle = Style.AXIS_HORIZONTAL;
      this.drawLine(scaleW, this.headerComp.getBottom() - 1, w, this.headerComp.getBottom() - 1);
      this.drawLine(scaleW, this.headerComp.getBottom(), scaleW, h);

      // Set clip:
      ctx.save();
      ctx.beginPath();
      ctx.rect(scaleW, 0, w - scaleW, h);
      ctx.clip();

      if(this.settings.showBasal) {
         ctx.save();
         ctx.beginPath();
         ctx.rect(scaleW, this.basalAxis.top, w - scaleW, this.basalAxis.height);
         ctx.clip();

         ctx.beginPath();
         let basalBottom = this.basalAxis.getBottom();
         let basalList = this.data.basal;
         let [minI, maxI] = getRange(this.data.basal, minT, maxT);
         let lastY = undefined;
         let lastX = undefined;
         for(let i = Math.max(0, minI - 1); i <= Math.min(basalList.length - 1 , maxI + 1); i++) {
            let basal = basalList[i];
            let x = area.getX(basal[0]);
            let y = this.basalAxis.getY(Math.max(basal[1], basal[2]));
            if (lastY == undefined) {
               ctx.moveTo(x, basalBottom);
               ctx.lineTo(x, y);
            } else {
               ctx.lineTo(x, lastY);
               ctx.lineTo(x, y);
            }
            lastX = x;
            lastY = y;
         }
         ctx.fillStyle = Style.BASAL_FILL;
         ctx.lineTo(lastX, basalBottom);
         ctx.closePath();
         ctx.fill();

         // Draw temp modified:
         lastY = undefined;
         let lastBasalType = BasalType.Normal;
         ctx.strokeStyle = Style.BASAL_STROKE;
         let tempH = 14;
         for(let i = Math.max(0, minI - 1); i <= Math.min(basalList.length - 1 , maxI + 1); i++) {
            let basal = basalList[i];
            let nextBasal = basalList[i + 1];
            let basalType = basal[3];
            let isNormalType = basalType == BasalType.Normal;

            let fromX = this.area.getX(basal[0]);
            let toX = nextBasal ? this.area.getX(nextBasal[0]) : w;
            let scheduledY = this.basalAxis.getY(basal[1]);
            let deliveredY = this.basalAxis.getY(basal[2]);

            if (lastY != undefined) {
               let firstLineType = isNormalType ? lastBasalType : basalType;
               drawBasalLine(this, fromX, lastY, fromX, deliveredY, firstLineType);
            }
            drawBasalLine(this, fromX, deliveredY, toX, deliveredY, basalType);

            if(scheduledY != deliveredY) {
               // Draw delivered:
               ctx.fillStyle = scheduledY > deliveredY ? "rgba(0, 0, 255, 0.1)" : "rgba(0, 255, 0, 0.2)";
               ctx.fillRect(fromX, scheduledY, toX - fromX,deliveredY - scheduledY);
               //drawBasalLine(this, fromX, scheduledY, toX, scheduledY, BasalType.Normal);
            }

            lastY = deliveredY;

            if(basalType != BasalType.Normal && basalType != BasalType.TempModified) {
               let grd = ctx.createLinearGradient(0, lastY - tempH, 0, lastY + tempH);
               let transparentColor = getBasalTransparentColor(basalType);
               grd.addColorStop(0, transparentColor);
               grd.addColorStop(0.5, getBasalColor(basalType));
               grd.addColorStop(1, transparentColor);
               ctx.fillStyle = grd;
               ctx.globalAlpha = 0.55;
               ctx.fillRect(fromX, deliveredY - tempH, toX - fromX,tempH * 2);
               ctx.globalAlpha = 1.0;
            }
            lastY = deliveredY;
            lastBasalType = basal[3];
         }
         ctx.restore();
      }

      let cursorInfo = new CursorInfo(this.cursorX, this.cursorY);
      let glucoseCursor = this.settings.advancedCursor ? new CursorInfo(this.cursorX, this.cursorY, 1000000) : cursorInfo;
      if(this.data.cgm && this.settings.showCGM) {
         this.drawGlucose(this.data.cgm, Style.CGM_SIZE * dpr, glucoseCursor);
      }
      if(this.data.glucose && this.settings.showGlucose) {
         this.drawGlucose(this.data.glucose, Style.GLUCOSE_SIZE * dpr, glucoseCursor);
      }

      // Draw min/max:
      let minY = this.glucoseAxis.getY(this.getGlucoseValue(patientData.goal_min));
      let maxY = this.glucoseAxis.getY(this.getGlucoseValue(patientData.goal_max));
      ctx.strokeStyle = Style.GLOCOSE_TARGET_LINE;
      ctx.setLineDash(Style.GLUCOSE_TARGET_DASH.map(l => l * dpr));
      ctx.lineWidth = 1;
      this.drawLine(scaleW, minY, w, minY);
      this.drawLine(scaleW, maxY, w, maxY);
      ctx.setLineDash([]);

      if(this.data.bolus && this.settings.showBolus) {
         ctx.lineWidth = 1;
         let bolusBottom = this.bolusAxis.getBottom();
         for(let bolus of this.data.bolus) {
            let x = area.getX(bolus[0]);
            let data = bolus[1] as BolusData;
            if(data.duration) {
               ctx.fillStyle = Style.BOLUS_FILL;
               ctx.strokeStyle = Style.BOLUS_STROKE;
               let extY = this.bolusAxis.getY(data.extended / 4);
               let extW = area.getDurationW(data.duration * 60);
               ctx.fillRect(x, extY, extW, bolusBottom - extY);
               ctx.strokeRect(x, extY, extW, bolusBottom - extY);
               cursorInfo.suggestArea(x, extY, extW, bolusBottom - extY, data, bolus[0], bolus[0] + data.duration * 60);
            }
            if(data.immediate) {
               let y = this.bolusAxis.getY(data.immediate);
               if(y < this.bolusAxis.top) {
                  y = this.bolusAxis.top + 1 * this.dpr;
                  ctx.fillStyle = Style.BOLUS_STROKE;
                  drawUpArrow(this, x, y - 5 * this.dpr);
               }
               if(data.automatic) {
                  ctx.fillStyle = Style.BOLUS_AUTOMATIC_FILL;
                  ctx.strokeStyle = Style.BOLUS_AUTOMATIC_STROKE;
               } else {
                  ctx.fillStyle = Style.BOLUS_FILL;
                  ctx.strokeStyle = Style.BOLUS_STROKE;
               }
               let immediateW = this.dpr * Math.min(9, 1000 * area.getPixPerSec());
               ctx.fillRect(x - immediateW / 2, y, immediateW, bolusBottom - y);
               ctx.strokeRect(x - immediateW / 2, y, immediateW, bolusBottom - y);
               cursorInfo.suggestArea(x, y, 0, bolusBottom - y, data, bolus[0], bolus[0] + data.duration * 60);
            }
         }
      }

      if(this.settings.showInsulin) {
         for(let insulin of this.data.insulin) {
            let x = area.getX(insulin[0]);
            let y = this.bolusAxis.getY(insulin[1]);
            ctx.fillStyle = Style.BOLUS_FILL;
            ctx.fillRect(x - 5, y, 11, h - y);
         }
      }

      if(this.settings.showExercise) {
         ctx.lineWidth = 1;
         ctx.fillStyle = Style.EXERCISE_FILL;
         ctx.strokeStyle = Style.EXERCISE_STROKE;
         let exerciseBarH = this.exerciseComp.height - 5;
         let exerciseY = this.exerciseComp.top + 2;
         for(let exercise of this.data.exercise) {
            let x = area.getX(exercise[0]);
            let w = area.getDurationW(exercise[1] * 60);
            ctx.fillRect(x, exerciseY, w, exerciseBarH);
            ctx.strokeRect(x, exerciseY, w, exerciseBarH);
            cursorInfo.suggestArea(x, exerciseY, w, exerciseBarH, "Exercise: " + exercise[1] + " minutes", exercise[0], exercise[0] + exercise[1] * 60);
         }
      }

      // Draw days:
      let startOfFirstVisibleDay = area.getStartOfFirstVisibleDay();
      ctx.strokeStyle = Style.AXIS_HORIZONTAL;

      for(let dayStart = toMoment(startOfFirstVisibleDay); dayStart.unix() < area.getMaxT();) {
         let dayStartT = dayStart.unix();
         let dayEnd = moment.unix(dayStartT).add(1, "day");
         let dayEndT = dayEnd.unix();
         let x1 = area.getX(dayStartT);
         let x2 = area.getX(dayEndT);
         let dayW = x2 - x1;
         let dayOfWeek = dayStart.weekday();
         let dayString;
         if(dayW * dpr > 300) {
            dayString = dayStart.format("ddd, DD MMM");
         } else {
            dayString = dayStart.format("DD MMM");
         }
         let dayFontSize = (dayW * dpr > 190) ? 12 : 10;
         ctx.strokeStyle = Style.DAY_DIVIDER;
         this.drawLine(x1, 0, x1, h);

         if(this.area.getPixPerSec() > 0.0017 * dpr) {
            ctx.fillStyle = Style.AXIS_HOUR_FG;
            ctx.strokeStyle = Style.HOUR_DIVIDER;
            ctx.textAlign = "center";
            this.setFont(12);
            for (let hour = 3; hour < 24; hour += 3) {
               let hx = area.getX(dayStartT + hour * 3600);
               this.drawLine(hx, 36 * dpr, hx, h);
               ctx.fillText((hour < 10 ? "0" : "") + hour, hx, 30 * dpr);
            }
         }

         ctx.textAlign = "left";
         //ctx.fillStyle = (dayOfWeek == 0 || dayOfWeek == 6) ? "#24476a" : "#406991";
         //ctx.fillRect(x1 + 2, 0, x2 - x1 - 4, 19);

         let minX = scaleW;
         let maxX = w;
         let m = 3 * dpr;
         this.setFont(dayFontSize);
         let textW = ctx.measureText(dayString).width;
         let textX = (x2 + x1 - textW) / 2;
         if(textX < minX + m) {
            textX = minX + m;
            if(textX > x2 - textW - m * 2) {
               textX = x2 - textW - m * 2;
            }
         } else if(textX > maxX - textW - m) {
            textX = maxX - textW - m;
            if(textX < x1 + m * 2) {
               textX = x1 + m * 2;
            }
         }
         if(this.cursorY < 17 * dpr && this.cursorX > x1 && this.cursorX < x2 && this.settings.allowPanning) {
            ctx.fillStyle = "#e6e7ff";
            drawRoundRect(ctx, textX - m, 1, textW + m * 2, 17 * dpr, 4 * dpr);
            ctx.fill();
         }

         ctx.fillStyle = (dayOfWeek == 0 || dayOfWeek == 6) ? Style.AXIS_WEEKEND_FG : Style.AXIS_WEEKDAY_FG;
         ctx.fillText(dayString,  textX, 14 * dpr);

         dayStart = dayEnd;
      }

      if(this.settings.showCarbs) {
         ctx.lineWidth = 1;
         ctx.fillStyle = Style.BOLUS_FILL;
         ctx.strokeStyle = Style.BOLUS_STROKE;
         let y = this.carbsComp.top;
         let scale = Math.max(0.15, Math.min(1.0, area.getPixPerSec() * 350));

         for(let carbs of this.data.carbs) {
            let x = area.getX(carbs[0]);
            let boxY = drawCarbsArrow(this, x, y, "" + carbs[1], scale);
            let cursorData: any = "Carbs: " + carbs[1] + "g";
            if(carbs[2]) {
               cursorData = [[cursorData], [""], [carbs[2]]]
            }
            cursorInfo.suggestPoint(x, boxY, cursorData, carbs[0]);
         }
      }

      if(this.settings.showEvents) {
         let lastEventX = -1000;
         let lastEventY = 0;
         let baseY = this.alarmComp.top + 8;
         for(let event of this.data.events) {
            let eventId = event[1];
            if(!this.hiddenEventIds.has(eventId)) {
               let eventDescription = this.resources.events[eventId];
               let x = area.getX(event[0]);
               let y;
               if((x - lastEventX) < 6) {
                  y = lastEventY + 8;
               } else {
                  y = baseY;
               }
               lastEventY = y;
               lastEventX = x;

               ctx.globalAlpha = this.highlightedEventId && this.highlightedEventId != eventId ? Style.HIDE_ALPHA : 1;
               drawIcon(this, x, y, eventDescription);
               ctx.globalAlpha = 1;
               cursorInfo.suggestPoint(x, y, eventDescription ? eventDescription.text : ("Unknown event " + eventId), event[0])
            }
         }
      }

      if(this.settings.showPumpModes) {
         for(let pumpMode of this.data.pumpModes) {
            const x = area.getX(pumpMode[0]);
            const w = area.getW(pumpMode[1]);
            const type = pumpMode[2];
            let pumpModeDescription = getPumpModeDescription(type);
            const y = 5 + this.pumpModesComp.top + pumpModeDescription.rowNum * (this.pumpModesComp.height - 10) / 2;
            const h = (this.pumpModesComp.height - 10) / 2 * pumpModeDescription.rowHeight;
            ctx.fillStyle = pumpModeDescription.fillColor;
            ctx.strokeStyle = pumpModeDescription.strokeColor ;
            ctx.fillRect(x, y, w, h);
            ctx.strokeRect(x, y, w, h);
            cursorInfo.suggestArea(x, y, w, h, type, pumpMode[0], pumpMode[0] + pumpMode[1], true);
         }
      }

      // Draw fog of war:
      ctx.fillStyle = Style.FOG_OF_WAR;
      if(this.data.from > minT) {
         ctx.fillRect(0, this.glucoseAxis.top, area.getX(this.data.from), h);
      }
      if(this.data.to < maxT) {
         let x = area.getX(this.data.to);
         ctx.fillRect(x, this.glucoseAxis.top, w - x, h);
      }

      // Restore clip:
      ctx.restore();

      // Cursor:
      if(this.cursorX > scaleW) {
         let cursorT = area.getT(this.cursorX);

         ctx.beginPath();
         ctx.strokeStyle = Style.CURSOR;
         ctx.moveTo(this.cursorX, 30 * dpr);
         ctx.lineTo(this.cursorX, h);
         ctx.stroke();
         ctx.closePath();

         // Draw date:
         let timeString = this.area.getTimeString(this.cursorX);
         drawDownArrow(this, this.cursorX, 18 * dpr, timeString);

         // Draw current basal:
         if(this.settings.showBasal && (this.settings.advancedCursor || (this.cursorY > this.basalAxis.top && !cursorInfo.data))) {
            let basalIndex = findIndex(this.data.basal, cursorT, SearchType.Backward);
            if (basalIndex != undefined) {
               const lines: string[][] = [];
               let cursorBasal = this.data.basal[basalIndex];
               let nextBasal =  this.data.basal[basalIndex + 1];
               let toTime = nextBasal ? nextBasal[0] : cursorBasal[0];
               let y = this.basalAxis.getY(cursorBasal[2]);
               lines.push(["Delivered: " + cursorBasal[2] + " U/h"])
               if(cursorBasal[1] != cursorBasal[2]) {
                  lines.push(["Scheduled: " + cursorBasal[1] + " U/h"])
               }
               if(cursorBasal[3] == BasalType.PredictiveLowGlucoseSuspend) {
                  lines.push(["Predictive low suspend"])
               } else if(cursorBasal[3] == BasalType.LowGlucoseSuspend) {
                  lines.push(["Low glucose suspend"])
               } else if(cursorBasal[3] == BasalType.TempModified) {
                  lines.push(["Temp. modified"])
               }
               let timeString = formatTimeString(cursorBasal[0], toTime, false);
               drawMultiline(this, this.cursorX, y, w, h, lines, timeString);
            }
         }

         // Draw current glucose:
         if(glucoseCursor.data && glucoseCursor != cursorInfo) {
            let timeString = toMoment(glucoseCursor.fromTime).format("HH:mm");
            drawMultiline(this, glucoseCursor.markerX, glucoseCursor.markerY, w, h, [[glucoseCursor.data]], timeString);
         }

         // Draw cursor info:
         if(cursorInfo.data) {
            let timeString = cursorInfo.getTimeString(false);
            if(typeof cursorInfo.data === "string") {
               drawMultiline(this, cursorInfo.markerX, cursorInfo.markerY, w, h, [[cursorInfo.data]], timeString);
            } else if(cursorInfo.data instanceof Array) {
               drawMultiline(this, cursorInfo.markerX, cursorInfo.markerY, w, h, cursorInfo.data, timeString);
            } else {
               drawBolus(this, cursorInfo.markerX, cursorInfo.markerY, w, h, cursorInfo.data, timeString);
            }
         }
      }

      //console.log("repaint in " + (new Date().valueOf() - start_paint_time));
      if(area.isAnimating()) {
         requestAnimationFrame(this.doRepaint)
      }
      this.updateStatistics(500)
   };

   private drawLine = (x1: number, y1: number, x2: number, y2: number) => {
      this.ctx.beginPath();
      this.ctx.moveTo(x1, y1);
      this.ctx.lineTo(x2, y2);
      this.ctx.stroke();
      this.ctx.closePath();
   };

   private drawGlucose = (dataList : any, size: number, cursorInfo: CursorInfo) => {
      let minT = this.area.getMinT();
      let maxT = this.area.getMaxT();
      let [minI, maxI] = getRange(dataList, minT, maxT);
      for(let i = minI; i <= maxI; i++) {
         let glucose = dataList[i];
         let x = this.area.getX(glucose[0]);
         this.drawGlucosePoint(glucose, x, size);
      }
      let nearest = findValue(dataList, this.area.getT(cursorInfo.cursorX), SearchType.Nearest);
      if(nearest) {
         cursorInfo.suggestPoint(
            this.area.getX(nearest[0]),
            Math.max(this.glucoseAxis.top, this.getGlucoseY(nearest[1])),
            this.getGlucoseString(nearest[1]),
            nearest[0])
      }
   };

   getGlucose = (t: number) => {
      return findValue(this.data.glucose, t, SearchType.Nearest);
   };

   onTap = (ev: HammerInput) => {
      let tapCount = (ev as any).tapCount;
      if(tapCount == 1) {
         if(this.cursorY < 17 * this.dpr) {
            this.area.showDays(1, ev.center.x * this.dpr);
            this.scheduleRepaint();
         }
      } else if(tapCount == 2) {
         this.area.showDays(1, ev.center.x * this.dpr);
         this.scheduleRepaint();
      }
   };

   lastStatisticsRange:string = undefined;
   statisticsTimeout:any = undefined;
   statistics: TimeRangeStatistics = undefined;
   updateStatistics = (delay:number = undefined) => {
      let [minT, maxT] = this.getStatisticsRange();
      if(!isNaN(minT as any)) {
         let statisticsRange = "" + minT + ":" + maxT;
         if (this.lastStatisticsRange != statisticsRange) {
            if (this.statistics && !this.statistics.inactive) {
               // Clear shown statistics:
               this.statistics = {...this.statistics, inactive: true};
               if(this.onStatisticsChange) {
                  this.onStatisticsChange(this.statistics)
               }
            }
            this.lastStatisticsRange = undefined;
            clearTimeout(this.statisticsTimeout);
            if(this.data.covers(minT.unix(), maxT.unix())) {
               console.log("Covers!");
               this.lastStatisticsRange = statisticsRange;
               if (delay) {
                  this.statisticsTimeout = setTimeout(this.loadStatistics, delay);
               } else {
                  this.loadStatistics()
               }
            }
         }
      }
   };

   private getStatisticsRange = () => {
      if(this.settings.useFullDayStatistics) {
         return [
            toMoment(this.area.getT(this.scaleW + 10)).startOf("day"),
            toMoment(this.area.getT(this.canvas.width - 10)).endOf("day")
         ];
      } else {
         return [
            toMoment(this.area.getMinT(false)),
            toMoment(this.area.getMaxT(false)),
         ];
      }
   };

   loadStatistics = () => {
      let [minT, maxT] = this.getStatisticsRange();
      console.log("loadStatistics " + minT.toISOString() + " - " + maxT.toISOString());

      // Get TDDS:
      let statisticsGenerator = new StatisticsGenerator(
         this.data,
         this.patientData,
         this.resources,
         this.hiddenEventIds,
         false);
      statisticsGenerator.addTimeRange(minT, maxT);

      this.statistics = statisticsGenerator.getStatistics(this.patientData.unit);
      if (this.onStatisticsChange) {
         this.onStatisticsChange(this.statistics);
      }
   };

   onKeyDown = (ev:KeyboardEvent) => {
      switch(ev.key) {
         case 'w':
            this.showDays(7);
            break;
         case 'd':
            this.showDays(1);
            break;
         case 'ArrowLeft':
            this.panDays(-1);
            break;
         case 'ArrowRight':
            this.panDays(1);
            break;
      }
   };
}