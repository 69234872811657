import React from "react";
import {PumpModeStatisticsItem} from "../graphs/TimeRangeStatistics";
import {Doughnut} from "react-chartjs-2";
import {PumpModeDescription} from "../graphs/PumpModeDescription";

export interface Props {
   statistics: PumpModeStatisticsItem[][];
}

interface BlockProps {
  items: PumpModeStatisticsItem[];
}

const PumpModeStatisticsBlock = (props: BlockProps) => {
  const items = props.items;
  const formatTime = (s: number) => {
    let hours = Math.floor(s / 3600)
    let minutes = Math.floor((s - (hours * 3600)) / 60);
    return "" + hours + " h, " + minutes + " min";
  }

  let totalPercentage = 0;
  items.forEach(i => totalPercentage += i.percentage);


  const data = {
    labels: items.map(s => s.pumpMode),
    datasets: [
      {
        backgroundColor: items.map(s => s.description.fillColor).concat(["#FFFFFF"]),
        borderColor: items.map(s => s.description.strokeColor).concat(["#CCCCCC"]),
        data: items.map(s => s.percentage).concat([100 - totalPercentage])
      }
    ]
  }

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    borderWidth: 1,
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        enabled: false
      }
    },
    animation: {
      duration: 300
    }
  }

  const getPumpModeStyle = (description: PumpModeDescription) => {
    return {
      height: 14,
      width: 20,
      background: description.fillColor,
      margin: "1px 5px 1px 0",
      border: "1px solid " + description.strokeColor
    }
  }

  return (
    <div style={{display:"flex"}} className='pumpModeStatisticsBlock'>
      <div>
        <Doughnut style={{width:40, height:40}} data={data} options={options}/>
      </div>
      <div style={{flexGrow:1, display:"flex", flexDirection:"column", marginLeft:10}}>
        {items.map(item =>
          <div key={item.pumpMode} style={{display: "flex", alignItems: "center"}}>
            <div style={getPumpModeStyle(item.description)}></div>
            <div style={{flexGrow: 1}}><b>{item.pumpMode}</b></div>
            <div>{formatTime(item.totalTime)}</div>
            <div style={{marginLeft: 10, width: 45, textAlign: "end"}}>{item.percentage.toFixed(1)} %</div>
          </div>)
        }
      </div>
    </div>
  );
}

export const PumpModeStatisticsComp = (props: Props) => {
   return(
     <div className="block">
       <div className="header"><b>Pump modes</b></div>
       <div style={{display:"flex", flexDirection:"column", marginTop:10}}>
         {props.statistics.map((itemList, index) => <PumpModeStatisticsBlock key={index} items={itemList} />)}
       </div>
     </div>
   )
}
