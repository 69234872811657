export default class GraphComponent {
   top: number = 0;
   baseHeight: number;
   height: number;
   weight?: number;
   isVisible: boolean = true;

   constructor(baseHeight: number, weight?: number) {
      this.baseHeight = baseHeight;
      this.height = baseHeight;
      this.weight = weight;
   }

   setPos(top: number, height: number) {
      this.top = top;
      this.height = height;
   }

   getBottom() {
      return this.top + this.height;
   }

   static place(components: GraphComponent[], dpr: number, totalH: number) {
      let fixedBaseH = 0;
      let totalWeight = 0;
      for(let c of components) {
         if(c.isVisible) {
            if (c.weight) {
               totalWeight += c.weight;
            } else {
               fixedBaseH += c.baseHeight;
            }
         }
      }
      let heightForWeighted = totalH - fixedBaseH * dpr;
      let y = 0;
      for(let c of components) {
         if(c.isVisible) {
            let h = c.baseHeight * dpr;
            if (c.weight) {
               h = heightForWeighted * c.weight / totalWeight;
            }
            c.setPos(y, h);
            y += h;
         }
      }
   }
}